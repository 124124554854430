import React from 'react';
import { InputBox, Select, SelectOption, Text } from 'uikit-lib';
import lang from './lang';
import {
    TwoInputsWrap,
    DataInputWrap,
    DataInputHint,
} from './RegisterForm.styles';
import { CSSObject } from '@emotion/react';
import { prepareSelectOptions } from './api';
import { useStore } from 'src/containers';
import { VALIDATION_RULES } from 'src/constants';
import { Controller, Control, FieldValues, FieldErrors } from 'react-hook-form';

export type Props = {
    testIds: Record<string, string>;
    theme: Record<string, CSSObject>;
    control: Control<FieldValues> | undefined;
    errors: FieldErrors;
};

export const Institution: React.FC<Props> = (props): JSX.Element => {
    const { theme, testIds, control, errors } = props;
    const {
        FetchedData: {
            registerFormConfig: {
                cityIsRequired,
                educationIsRequired,
                startEducationYears,
                finishEducationYears,
            },
        },
    } = useStore();
    const { placeholders } = lang;

    const startEduOptions = prepareSelectOptions(startEducationYears || []);
    const endEduOptions = prepareSelectOptions(finishEducationYears || []);

    return (
        <>
            {cityIsRequired && (
                <DataInputWrap
                    theme={theme.DataInputWrap}
                    data-testid={testIds.DataInputWrap}>
                    <Controller
                        name={'city'}
                        control={control}
                        rules={VALIDATION_RULES.city}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <InputBox
                                    type={'BASIC'}
                                    tokens={{}}
                                    value={value}
                                    isValid={!errors.city}
                                    placeholder={placeholders.cityInput}
                                    onChange={onChange}
                                />
                            );
                        }}
                    />
                    {!!errors?.city?.message && (
                        <DataInputHint
                            theme={theme.DataInputHint}
                            data-testid={testIds.DataInputHint}>
                            {errors.city.message as string}
                        </DataInputHint>
                    )}
                </DataInputWrap>
            )}

            {educationIsRequired && (
                <>
                    <DataInputWrap
                        theme={theme.DataInputWrap}
                        data-testid={testIds.DataInputWrap}>
                        <Text nodeName={'H2'} type={'T3'} tokens={{}}>
                            ВУЗ, в котором обучаетесь в настоящее время
                        </Text>
                        <Controller
                            name={'university'}
                            control={control}
                            rules={VALIDATION_RULES.university}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <InputBox
                                        type={'BASIC'}
                                        tokens={{}}
                                        value={value}
                                        isValid={!errors.university}
                                        placeholder={
                                            placeholders.universityInput
                                        }
                                        onChange={onChange}
                                    />
                                );
                            }}
                        />
                        {!!errors?.university?.message && (
                            <DataInputHint
                                theme={theme.DataInputHint}
                                data-testid={testIds.DataInputHint}>
                                {errors.university.message as string}
                            </DataInputHint>
                        )}
                    </DataInputWrap>
                    <DataInputWrap
                        theme={theme.DataInputWrap}
                        data-testid={testIds.DataInputWrap}>
                        <Controller
                            name={'faculty'}
                            control={control}
                            rules={VALIDATION_RULES.faculty}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <InputBox
                                        type={'BASIC'}
                                        tokens={{}}
                                        value={value}
                                        isValid={!errors.faculty}
                                        placeholder={placeholders.facultyInput}
                                        onChange={onChange}
                                    />
                                );
                            }}
                        />
                        {!!errors?.faculty?.message && (
                            <DataInputHint
                                theme={theme.DataInputHint}
                                data-testid={testIds.DataInputHint}>
                                {errors.faculty.message as string}
                            </DataInputHint>
                        )}
                    </DataInputWrap>
                    <TwoInputsWrap data-testid={testIds.TwoInputsWrap}>
                        <DataInputWrap
                            theme={theme.DataInputWrap}
                            data-testid={testIds.DataInputWrap}>
                            <Controller
                                name={'startEducationStr'}
                                control={control}
                                rules={VALIDATION_RULES.startEducation}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Select
                                            tokens={{}}
                                            options={startEduOptions.options}
                                            langs={{
                                                placeholder:
                                                    lang.placeholders
                                                        .startEduYearInput,
                                            }}
                                            params={{
                                                isMulti: false,
                                                value: startEduOptions
                                                    .dictionary[String(value)],
                                            }}
                                            isValid={!errors?.startEducation}
                                            handleOnChange={(
                                                data: SelectOption
                                            ) => {
                                                onChange(data.value);
                                            }}
                                        />
                                    );
                                }}
                            />
                            {!!errors?.startEducation?.message && (
                                <DataInputHint
                                    theme={theme.DataInputHint}
                                    data-testid={testIds.DataInputHint}>
                                    {errors.startEducation.message as string}
                                </DataInputHint>
                            )}
                        </DataInputWrap>

                        <DataInputWrap
                            theme={theme.DataInputWrap}
                            data-testid={testIds.DataInputWrap}>
                            <Controller
                                name={'finishEducationStr'}
                                control={control}
                                rules={VALIDATION_RULES.finishEducation}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Select
                                            tokens={{}}
                                            options={endEduOptions.options}
                                            langs={{
                                                placeholder:
                                                    lang.placeholders
                                                        .endEduYearInput,
                                            }}
                                            params={{
                                                isMulti: false,
                                                value: endEduOptions.dictionary[
                                                    String(value)
                                                ],
                                            }}
                                            isValid={!errors?.startEducation}
                                            handleOnChange={(
                                                data: SelectOption
                                            ) => {
                                                onChange(data.value);
                                            }}
                                        />
                                    );
                                }}
                            />
                            {!!errors?.finishEducation?.message && (
                                <DataInputHint
                                    theme={theme.DataInputHint}
                                    data-testid={testIds.DataInputHint}>
                                    {errors.finishEducation.message as string}
                                </DataInputHint>
                            )}
                        </DataInputWrap>
                    </TwoInputsWrap>
                </>
            )}
        </>
    );
};

export default Institution;
