import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { BaseFCProps, withBaseProps, SectionHeaderNodes } from 'uikit-lib';
import { CWrap, Title, Description } from './SectionHeader.styles';

export interface Props extends BaseFCProps<SectionHeaderNodes> {
    lang: {
        title: string;
        description: string;
    };
    modifyClasses?: {
        CWrap?: string;
        Title?: string;
        Description?: string;
    };
}

export const SectionHeader: React.FC<Props> = (props): JSX.Element => {
    const {
        theme,
        tokens: { groupToken = 'SECTION_HEADER' },
        testIds,
        lang: { title, description },
        modifyClasses,
    } = props;

    return (
        <CWrap
            className={modifyClasses?.CWrap || ''}
            data-at-selector={groupToken}
            theme={theme.CWrap}
            data-testid={testIds.CWrap}>
            <Title
                className={modifyClasses?.Title || ''}
                theme={theme.Title}
                data-at-selector={`${groupToken}__IS_TITLE`}
                data-testid={testIds.Title}>
                {title}
            </Title>
            <Description
                className={modifyClasses?.Description || ''}
                theme={theme.Description}
                data-at-selector={`${groupToken}__IS_DESCRIPTION`}
                data-testid={testIds.Description}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(description || ''),
                }}
            />
        </CWrap>
    );
};

export default withBaseProps(SectionHeader, {
    name: 'SectionHeader',
    code: 'SECTION_HEADER',
    nodeNames: Object.keys(SectionHeaderNodes),
});
