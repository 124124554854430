import React from 'react';
import { getDataTestId } from '../../utils';
import {
    FCTokens,
    TYPOGRAPHY__BH1,
    TYPOGRAPHY__BH2,
    TYPOGRAPHY__BH3,
    TYPOGRAPHY__BH4,
    TYPOGRAPHY__BH5,
    TYPOGRAPHY__H1,
    TYPOGRAPHY__H2,
    TYPOGRAPHY__H3,
    TYPOGRAPHY__H4,
    TYPOGRAPHY__H5,
    TYPOGRAPHY__S1,
    TYPOGRAPHY__S2,
    TYPOGRAPHY__S3,
    TYPOGRAPHY__S4,
    TYPOGRAPHY__T1,
    TYPOGRAPHY__T2,
    TYPOGRAPHY__T3,
    TYPOGRAPHY__T4,
    TYPOGRAPHY__D1,
    TYPOGRAPHY__D2,
    TYPOGRAPHY__D3,
} from '../..';
import { nodeMap } from './Text.styles';

export interface Props {
    nodeName: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6' | 'P';
    type: string;
    tokens: FCTokens;
    children: React.ReactNode;
}

export const Text: React.FC<Props> = (props): JSX.Element => {
    const {
        nodeName,
        type,
        tokens: { groupToken = '', hlaToken = '', actionToken = '' },
        children,
    } = props;
    const styleTypes: {
        [key: string]: {
            fontSize: string;
            lineHeight: string;
            fontWeight: number;
        };
    } = {
        BH1: { ...TYPOGRAPHY__BH1 },
        BH2: { ...TYPOGRAPHY__BH2 },
        BH3: { ...TYPOGRAPHY__BH3 },
        BH4: { ...TYPOGRAPHY__BH4 },
        BH5: { ...TYPOGRAPHY__BH5 },
        H1: { ...TYPOGRAPHY__H1 },
        H2: { ...TYPOGRAPHY__H2 },
        H3: { ...TYPOGRAPHY__H3 },
        H4: { ...TYPOGRAPHY__H4 },
        H5: { ...TYPOGRAPHY__H5 },
        S1: { ...TYPOGRAPHY__S1 },
        S2: { ...TYPOGRAPHY__S2 },
        S3: { ...TYPOGRAPHY__S3 },
        S4: { ...TYPOGRAPHY__S4 },
        T1: { ...TYPOGRAPHY__T1 },
        T2: { ...TYPOGRAPHY__T2 },
        T3: { ...TYPOGRAPHY__T3 },
        T4: { ...TYPOGRAPHY__T4 },
        D1: { ...TYPOGRAPHY__D1 },
        D2: { ...TYPOGRAPHY__D2 },
        D3: { ...TYPOGRAPHY__D3 },
    };
    const theme = styleTypes[type];
    const CWrap = nodeMap[nodeName];
    const testIds = {
        CWrap: getDataTestId(`${nodeName}__CWRAP`, hlaToken, actionToken),
    };

    return (
        <CWrap
            theme={theme}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}>
            {children}
        </CWrap>
    );
};
