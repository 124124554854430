import React from 'react';
import { withBaseProps, SeparatorNodes, BaseFCProps } from 'uikit-lib';
import { CWrap } from './Separator.styles';

export const Separator: React.FC<BaseFCProps<SeparatorNodes>> = (
    props
): JSX.Element => {
    const {
        testIds,
        tokens: { groupToken },
        theme,
    } = props;

    return (
        <CWrap
            data-at-selector={groupToken}
            theme={theme.CWrap}
            data-testid={testIds.CWrap}
        />
    );
};

export default withBaseProps(Separator, {
    name: 'Separator',
    code: 'SEPARATOR',
    nodeNames: Object.keys(SeparatorNodes),
});
