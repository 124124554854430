export const getArrayFromNumber = (value: number): number[] => {
    if (value <= 0) return [];
    const output: number[] = [];
    for (let i = 0; i < value; i++) {
        output.push(i);
    }
    return output;
};

export default getArrayFromNumber;
