import {
    TYPOGRAPHY__H2,
    TYPOGRAPHY__H3,
    TYPOGRAPHY__S1,
    TYPOGRAPHY__S2,
    TYPOGRAPHY__S3,
    TYPOGRAPHY__S4,
    TYPOGRAPHY__T3,
    LIST_STYLING,
} from 'uikit-lib';

export const theme = {
    CWrap: {
        padding: '80px 32px 40px',
        backgroundColor: '#DAD6E9',
        position: 'relative',
        overflow: 'hidden',
        '@media screen and (max-width: 1290px)': {
            padding: '40px 32px',
        },
        '@media screen and (max-width: 700px)': {
            padding: '40px 16px',
        },
        '&::before': {
            content: '""',
            borderRadius: '554px',
            opacity: 0.8,
            background: '#2AF587',
            filter: 'blur(117px)',
            width: '554px',
            height: '426px',
            transform: 'rotate(-146deg)',
            flexShrink: 0,
            position: 'absolute',
            top: '-200px',
            left: 'calc((100% - 1200px) / 2 - 100px)',
            zIndex: 1,
        },
        '&::after': {
            content: '""',
            width: '554px',
            height: '426px',
            transform: 'rotate(-146deg)',
            flexShrink: 0,
            borderRadius: '554px',
            opacity: 0.8,
            background: '#1A2F9A',
            filter: 'blur(117px)',
            position: 'absolute',
            bottom: '-200px',
            zIndex: 0,
            right: 'calc((100% - 1200px) / 2 - 100px)',
        },
    },
    Title: {
        ...TYPOGRAPHY__H2,
        maxWidth: '650px',
        paddingRight: '100px',
        alignSelf: 'flex-end',
        '@media screen and (max-width: 1270px)': {
            ...TYPOGRAPHY__H3,
            alignSelf: 'flex-start',
        },
        '@media screen and (max-width: 1024px)': {
            ...TYPOGRAPHY__H3,
            alignSelf: 'flex-start',
        },
        '@media screen and (max-width: 640px)': {
            ...TYPOGRAPHY__S1,
        },
    },
    SubTitle: {
        ...TYPOGRAPHY__S1,
        ...LIST_STYLING,
        alignSelf: 'flex-end',
        maxWidth: '440px',
        '@media screen and (max-width: 1270px)': {
            ...TYPOGRAPHY__S2,
            alignSelf: 'flex-start',
            paddingTop: '30px',
        },
        '@media screen and (max-width: 1024px)': {
            ...TYPOGRAPHY__S2,
            alignSelf: 'flex-start',
        },
        '@media screen and (max-width: 640px)': {
            ...TYPOGRAPHY__S3,
        },
    },
    ShortDescription: {},
    Header: {
        display: 'flex',
        flexDirection: 'row',
        zIndex: 2,
        position: 'relative',
        '@media screen and (max-width: 1024px)': {
            flexDirection: 'column',
        },
    },
    Folder: {
        minHeight: '300px',
        padding: '50px 40px 100px 40px',
        '@media screen and (max-width: 1440px)': {
            padding: '50px 0px 0px 0px',
        },
        '&::after': {
            content: '""',
            clipPath:
                'polygon(0px 0px, 560px 0px, 610px 50px, calc(100% - 60px) 50px, 100% 110px, 100% 100%, calc(100% - 160px) 100%, calc(100% - 270px) calc(100% - 60px), 190px calc(100% - 60px), 0px calc(100% - 230px))',
            backgroundColor: 'rgba(255, 255, 255, 0.43)',
            position: 'absolute',
            backdropFilter: 'blur(92px)',
            width: '100%',
            height: '100%',
            zIndex: 1,
            top: '0px',
            left: '0px',
            '@media screen and (max-width: 1270px)': {
                clipPath:
                    'polygon(0px 0px, 420px 0px, 455px 35px, calc(100% - 60px) 35px, 100% 95px, 100% 100%, calc(100% - 115px) 100%, calc(100% - 195px) calc(100% - 60px), 190px calc(100% - 60px), 0px calc(100% - 220px))',
            },
            '@media screen and (max-width: 900px)': {
                clipPath:
                    'polygon(0px 0px, 260px 0px, 280px 20px, calc(100% - 35px) 20px, 100% 55px, 100% 100%, calc(100% - 115px) 100%, calc(100% - 195px) calc(100% - 60px), 100px calc(100% - 60px), 0px calc(100% - 170px))',
                height: '368px',
            },
            '@media screen and (max-width: 640px)': {
                clipPath:
                    'polygon(0px 0px, 260px 0px, 280px 20px, calc(100% - 35px) 20px, 100% 55px, 100% 100%, calc(100% - 115px) 100%, calc(100% - 195px) calc(100% - 60px), 100px calc(100% - 60px), 0px calc(100% - 170px))',
                height: '368px',
            },
        },
    },
    DirectionList: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: '50px',
        gap: '15px',
        position: 'relative',
        zIndex: 20,
        justifyContent: 'flex-start',
        '@media screen and (max-width: 900px)': {
            padding: '40px 0px',
            marginLeft: '-32px',
            width: 'calc(100% + 64px)',
            justifyContent: 'space-between',
        },
        '@media screen and (max-width: 640px)': {
            padding: '20px 0px',
            marginLeft: '0px',
            width: '100%',
            justifyContent: 'flex-start',
        },
    },
    DirectionListItem: {
        display: 'flex',
        flexDirection: 'column',
        padding: '13px',
        borderRadius: '8px',
        background: 'rgba(255, 255, 255, 0.66)',
        backdropFilter: 'blur(92px)',
        width: 'calc(25% - 12px)',
        cursor: 'pointer',
        '&:hover': {
            boxShadow:
                '0px 10px 32px -4px rgba(24, 39, 75, 0.10), 0px 6px 14px -6px rgba(24, 39, 75, 0.12)',
            transform: 'scale(1.05)',
        },
        minHeight: '108px',
        transition: 'all 0.3s ease-out',
        '@media screen and (max-width: 1440px)': {
            width: '24%',
        },
        '@media screen and (max-width: 1270px)': {
            width: 'calc(33% - 8px)',
        },
        '@media screen and (max-width: 900px)': {
            width: 'calc(50% - 9px)',
        },
        '@media screen and (max-width: 640px)': {
            width: '100%',
        },
    },
    DirectionListItemType: {
        ...TYPOGRAPHY__T3,
        textTransform: 'uppercase',
        flex: 1,
        fontWeight: 600,
        color: 'rgba(42, 53, 65, 0.70)',
    },
    DirectionListItemContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    DirectionListItemName: {
        ...TYPOGRAPHY__S4,
        color: '#1D1D1B',
        fontWeight: '600',
        flex: 1,
        padding: '0px 10px 0px 0px',
    },
    DirectionListItemImage: {
        width: '30px',
        height: '24px',
        backgroundImage: 'url("./assets/images/directions/row_to_left.png")',
    },
};

export default theme;
