import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const Description = styled.div`
    ${getCSSObj}
`;

export const Link = styled.div`
    ${getCSSObj}
`;
