/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Direction, Feedback, RegisterForm } from './features';

export const customFCsDict: {
    [key: string]: React.FC<any>;
} = {
    HOME_PAGE__MODAL____CUSTOM_FC__SHOW_DIRECTION: Direction,

    HOME_PAGE__MODAL____CUSTOM_FC__SHOW_FEEDBACK: Feedback,

    HOME_PAGE__MODAL____CUSTOM_FC__SHOW_REGISTER_FORM__IS_INTRO: RegisterForm,
};

export default customFCsDict;
