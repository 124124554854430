import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const Title = styled.h2`
    ${getCSSObj}
`;

export const Content = styled.div`
    ${getCSSObj}
`;

export const RowContainer = styled.div`
    ${getCSSObj}
`;

export const RowContent = styled.div`
    ${getCSSObj}
`;

export const RowTitle = styled.p`
    ${getCSSObj}
`;

export const RowContentItem = styled.div`
    ${getCSSObj}
`;

export const Question = styled.div`
    ${getCSSObj}
`;

export const Answer = styled.div`
    ${getCSSObj}
`;

export const BackgroundImage = styled.div`
    ${getCSSObj}
`;

export const AnswerIcon = styled.div`
    ${getCSSObj}
`;

export const Wrap = styled.div`
    ${getCSSObj}
`;
