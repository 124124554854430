import { css, keyframes } from '@emotion/react';

import {
    getButtonProps,
    BtnColorModeName,
    BtnSizeModeName,
    BTN_COLOR_MODES,
    BTN_SIZE_MODES,
} from '../../../../../';

const spBtnProps = getButtonProps(
    {
        color: BtnColorModeName.GREEN,
        size: BtnSizeModeName.DEFAULT_48,
        icon: 'ALONG',
        withShadow: false,
    },
    {
        color: BTN_COLOR_MODES,
        size: BTN_SIZE_MODES,
    }
);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
`;

export const theme = {
    CWrap: {
        ...spBtnProps,
        position: 'relative',

        '&.isLoading': {
            opacity: '0.5',
            pointerEvents: 'none',
        },
    },
    BtnLoader: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: 'inherit',
        borderRadius: 'inherit',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        svg: {
            animation: `${rotate} 1s linear infinite`,
        },
    },
};
