import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const CWrap = styled.div`
    ${getCSSObj}
`;
export const Title = styled.h2`
    ${getCSSObj}
`;
export const List = styled.div`
    ${getCSSObj}
`;
export const ListItem = styled.div`
    ${getCSSObj}
`;
export const ListItemPeriod = styled.h4`
    ${getCSSObj}
`;
export const ListItemLines = styled.div`
    ${getCSSObj}
`;
export const ListItemLinesUp = styled.div`
    ${getCSSObj}
`;
export const ListItemLinesCenter = styled.div`
    ${getCSSObj}
`;
export const ListItemLinesDown = styled.div`
    ${getCSSObj}
`;
export const ListItemDescription = styled.div`
    ${getCSSObj}
`;
export const ListItemDescriptionTitle = styled.h4`
    ${getCSSObj}
`;
export const ListItemDescriptionComment = styled.h4`
    ${getCSSObj}
`;
