import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const Container = styled.div`
    ${getCSSObj}
`;

export const Content = styled.div`
    ${getCSSObj}
`;

export const BearTop = styled.img`
    ${getCSSObj}
`;

export const BearBottom = styled.img`
    ${getCSSObj}
`;

export const ContentImage = styled.img`
    ${getCSSObj}
`;

export const ContentText = styled.div`
    ${getCSSObj}
`;

export const Title = styled.h1`
    ${getCSSObj}
`;

export const Subtitle = styled.div`
    ${getCSSObj}
`;
