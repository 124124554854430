import { LAYOUTING__CONTENT_AREA } from '../../../../../';

export const theme = {
    CWrap: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 20,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--COLORS__GRAYSCALE____2)',
        boxShadow:
            '0px 4px 4px -2px rgba(24, 39, 75, 0.08), 0px 2px 4px -2px rgba(24, 39, 75, 0.12)',
    },
    MainArea: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...LAYOUTING__CONTENT_AREA,
        minHeight: '64px',
    },
};

export default theme;
