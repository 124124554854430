import React, { useState, useEffect } from 'react';
import { HelmetBlock, FAQ, Dictionary, toDictionary, Marquee } from 'uikit-lib';

import { useLazyQuery } from '@apollo/client';
import {
    Direction,
    InternshipStep,
    Feedback,
    FaqType,
    FaqRow,
    FaqItem,
    DataModelAction,
    ModalState,
} from 'src/types';
import {
    Directions,
    Feedbacks,
    InternshipRoadmap,
    HomeIntro,
} from './components';
import {
    runSectionDataFetch,
    handleShowModal,
    handleSetModalHeaderLangs,
} from './api';
import lang from './lang';
import { useQueryDictionary, useAction } from 'src/containers';
import { GradientShape, Separator } from 'src/components';
import { FAQ_GREEN, FAQ_PURPLE } from 'src/constants';

const Home: React.FC = (): JSX.Element => {
    const hlaToken = 'APP__HOME_PAGE';

    const { setModalData, setFetchedData } = useAction([
        'setModalData',
        'setFetchedData',
    ]);

    const [directions, setDirections] = useState<Direction[]>([]);
    const [internshipSteps, setInternshipSteps] = useState<InternshipStep[]>(
        []
    );
    const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
    const [questions, setQuestions] = useState<FaqItem[]>([]);

    const [
        GET_DIRECTIONS,
        GET_INTERNSHIP_STEPS,
        GET_FEEDBACKS,
        GET_FAQ_QUESTIONS,
    ] = useQueryDictionary([
        'GET_DIRECTIONS',
        'GET_INTERNSHIP_STEPS',
        'GET_FEEDBACKS',
        'GET_FAQ_QUESTIONS',
    ]);

    const [runDirectionsFetch] = useLazyQuery(GET_DIRECTIONS, {
        variables: { page: 0, size: 50 },
    });
    const [runInternshipStepsFetch] = useLazyQuery(GET_INTERNSHIP_STEPS, {
        variables: { page: 0, size: 20, sortByNumber: true },
    });
    const [runFeedbacksFetch] = useLazyQuery(GET_FEEDBACKS, {
        variables: { page: 0, size: 50 },
    });
    const [runQuestionsFetch] = useLazyQuery(GET_FAQ_QUESTIONS, {
        variables: { page: 0, size: 20 },
    });

    const runFetch = async () => {
        await runSectionDataFetch(runDirectionsFetch, {
            entityName: 'directions',
            write2StateFn: setDirections,
            storeAction: setFetchedData,
        });
        await runSectionDataFetch(runInternshipStepsFetch, {
            entityName: 'phaseSelectionOfIntern',
            write2StateFn: setInternshipSteps,
            storeAction: setFetchedData,
        });
        await runSectionDataFetch(runFeedbacksFetch, {
            entityName: 'internsFeedbacks',
            write2StateFn: setFeedbacks,
            storeAction: setFetchedData,
        });
        await runSectionDataFetch(runQuestionsFetch, {
            entityName: 'questions',
            write2StateFn: setQuestions,
            storeAction: setFetchedData,
        });
    };

    useEffect(() => {
        runFetch();
    }, []);

    const getFaqRows = (): Dictionary<FaqRow> =>
        toDictionary(
            Object.keys(FaqType).map((key: string) => {
                const resource: string =
                    FaqType[key as keyof typeof FaqType].toLowerCase();

                return {
                    key,
                    title: lang[`${resource}Title`],
                };
            }),
            'key'
        );

    return (
        <>
            <HelmetBlock
                lang={{
                    title: lang.title,
                    description: lang.description,
                }}
            />
            <HomeIntro
                handleShowModal={() => {
                    handleShowModal(
                        'showRegisterForm__isIntro',
                        setModalData as DataModelAction<ModalState>
                    );
                }}
                tokens={{
                    hlaToken,
                    groupToken: 'HOME__SECTION',
                }}
            />
            <Directions
                data={directions}
                handleOnClickItem={(item: Direction, index: number) => {
                    handleShowModal(
                        'showDirectionDescription',
                        setModalData as DataModelAction<ModalState>,
                        index
                    );
                    handleSetModalHeaderLangs(
                        setModalData as DataModelAction<ModalState>,
                        {
                            title: item.name,
                        }
                    );
                }}
                tokens={{
                    hlaToken,
                    groupToken: 'HOME__SECTION',
                }}
            />
            <InternshipRoadmap
                data={internshipSteps}
                tokens={{
                    hlaToken,
                    groupToken: 'HOME__SECTION',
                }}
            />
            {!!feedbacks.length && (
                <Feedbacks
                    items={feedbacks}
                    handleOnClickItem={(itemIndex: number) => {
                        handleShowModal(
                            'showFeedback',
                            setModalData as DataModelAction<ModalState>,
                            itemIndex
                        );
                    }}
                    tokens={{
                        hlaToken,
                        groupToken: 'HOME__SECTION',
                    }}
                />
            )}
            <Marquee
                type={'HOME_PAGE'}
                data={[{ key: 'HOME_PAGE_MARQUEE', title: lang.marquee }]}
                separator={{
                    isUsed: true,
                    component: () => (
                        <Separator
                            type={'FAQ'}
                            tokens={{
                                hlaToken,
                                groupToken: 'HOME_FAQ_MARQUEE_SEPARATOR',
                            }}
                        />
                    ),
                }}
            />
            {!!questions.length && (
                // eslint-disable-next-line react/jsx-pascal-case
                <FAQ
                    type={'SAFEBOARD_HOMEPAGE'}
                    title={lang.faqTitle}
                    rows={getFaqRows()}
                    tokens={{
                        hlaToken,
                        groupToken: 'HOME__SECTION',
                    }}
                    questions={questions}
                    backgroundImage={'./assets/images/faq/faq_bg.webp'}
                    iconBackgroundImage={'./assets/images/faq/cat.png'}
                    buttonCaption={lang.faqBtnCaption}>
                    <GradientShape
                        config={FAQ_GREEN}
                        tokens={{
                            hlaToken,
                            groupToken: `${hlaToken}__FAQ__GRADIENT_GREEN`,
                        }}
                    />

                    <GradientShape
                        config={FAQ_PURPLE}
                        tokens={{
                            hlaToken,
                            groupToken: `${hlaToken}_FAQ__GRADIENT_PURPLE`,
                        }}
                    />
                </FAQ>
            )}
        </>
    );
};

export default Home;
