import { pubAppTheme } from 'uikit-lib';
import componentThemes from './components';
import containerThemes from './containers';

export const theme = {
    ...pubAppTheme,
    ...componentThemes,
    ...containerThemes,
};

export default theme;
