export const ruLangs: Record<string, string> = {
    title: 'SafeBoard 2024',
    description: 'Welcome to SafeBoard at the Kaspersky Lab',
    faqTitle: 'FAQ',
    selectionTitle: 'Отбор',
    internshipTitle: 'Стажировка',
    faqBtnCaption: 'Задать вопрос',
    marquee: 'Kaspersky SafeBoard - твой прыжок в безопасное будущее',
};

export default ruLangs;
