export const theme = {
    CWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        overflow: 'hidden',
        width: '20px',
        minWidth: '20px',
        height: '20px',
        minHeight: '20px',
        background: 'transparent',
        border: '2px solid',
        borderRadius: '4px',
        borderColor: 'var(--COLORS__GRAYSCALE____5)',
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        strokeWidth: '3px',
        strokeLinecap: 'round',

        '&:hover': {
            borderColor: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
        },

        '&.isChecked': {
            background: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
            borderColor: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
        },

        '&.isInvalid': {
            borderColor: 'var(--COLORS__SECONDARY_PALETTE____RED)',
            boxShadow: 'var(--COLORS__SHADOWS____RED)',
        },

        '&.isDisabled': {
            pointerEvents: 'none',
            opacity: '0.5',
        },
    },
};

export default theme;
