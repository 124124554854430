import styled from '@emotion/styled';
import { getCSSObj } from '../../utils';

export const CWrap = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    ${getCSSObj}
`;

export const ContentArea = styled.div`
    width: 100%;
    max-width: var(
        --LAYOUTING__CONTENT_AREA__IS_DESKTOP____MAX_WIDTH__IS_FROM_1440
    );

    @media screen and (max-width: 640px) {
        padding: var(--LAYOUTING__CONTENT_AREA__IS_MOBILE__PADDING);
    }

    @media (min-width: 641px) and (max-width: 1440px) {
        padding: var(--LAYOUTING__CONTENT_AREA__IS_TABLET__PADDING);
    }

    ${getCSSObj}
`;
