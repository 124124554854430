/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-case-declarations */
import React from 'react';
import {
    BaseFCProps,
    MenuNodes,
    MenuData,
    withBaseProps,
    Button,
    Dropdown,
    HashLink,
} from '../../';
import { CWrap } from './Menu.styles';

export interface Props extends BaseFCProps<MenuNodes> {
    type: 'HEADER' | 'SIDEBAR' | 'FOOTER';
    data: MenuData;
    isVisible?: boolean;
    isMobile?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    actions?: any;
}

export const Menu: React.FC<Props> = (props): JSX.Element => {
    const {
        data,
        isVisible = false,
        isMobile = false,
        actions,
        tokens: { groupToken = '', hlaToken = '' },
        theme,
        testIds,
    } = props;

    const curFCId = 'MENU';
    const isVisibleClass = isVisible ? 'isVisible' : '';
    const isMobileClass = isMobile ? 'isMobile' : '';

    return (
        <CWrap
            className={`${isMobileClass} ${isVisibleClass}`}
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}
            role={'menu'}>
            {data.map((menuItem) => {
                if (menuItem.type === 'BUTTON') {
                    const {
                        type,
                        caption,
                        modifierClass,
                        navigatesTo,
                        actionToken: btnActionToken,
                        handleOnClick,
                        iconParams,
                        routePath,
                    } = menuItem.data;
                    const key = window.crypto.randomUUID();
                    const tokens = {
                        groupToken: `${groupToken}__${type}_BTN`,
                        hlaToken: `${hlaToken}__${curFCId}`,
                        actionToken: btnActionToken || '',
                    };

                    if (
                        type === 'HEADER_MENU_HASHLINK' ||
                        (routePath && routePath.includes('#'))
                    ) {
                        return (
                            <HashLink
                                type={type}
                                key={key}
                                to={routePath || ''}
                                tokens={tokens}
                                smooth
                                handleOnClick={() => {
                                    if (menuItem.data.closeMenu)
                                        menuItem.data.closeMenu();
                                }}
                                caption={caption || ''}
                                customStylesClass={'isNavigateBtn'}
                            />
                        );
                    } else {
                        const handlerParams =
                            // @ts-ignore
                            menuItem.data.key === 'fillRegForm'
                                ? {
                                      mixinName: 'showRegisterForm__isIntro',
                                      setStateFn: actions.setModalData,
                                  }
                                : undefined;

                        return (
                            <Button
                                key={key}
                                type={type}
                                caption={caption}
                                modifierClass={`${modifierClass || ''}${
                                    navigatesTo === window.location.pathname
                                        ? ' isActive'
                                        : ''
                                }`}
                                tokens={tokens}
                                handleOnClick={() => {
                                    if (!handleOnClick) return;
                                    // @ts-ignore
                                    if (handlerParams) {
                                        handleOnClick(handlerParams);
                                    } else {
                                        handleOnClick();
                                    }
                                }}
                                iconParams={iconParams}
                            />
                        );
                    }
                } else {
                    const {
                        id,
                        type,
                        items,
                        toggleBtnCaption,
                        toggleBtnIconParams,
                    } = menuItem.data;
                    return (
                        <Dropdown
                            id={id || ''}
                            key={window.crypto.randomUUID()}
                            type={type}
                            items={items}
                            toggleBtnCaption={toggleBtnCaption}
                            toggleBtnIconParams={toggleBtnIconParams}
                            tokens={{
                                groupToken: `${groupToken}__${type}_DROPDOWN`,
                                hlaToken: `${hlaToken}__${type}`,
                                actionToken: '',
                            }}
                        />
                    );
                }
            })}
        </CWrap>
    );
};

export default withBaseProps<MenuNodes, Props>(Menu, {
    name: 'Menu',
    code: 'MENU',
    nodeNames: Object.keys(MenuNodes),
});
