import React from 'react';
import FastMarquee from 'react-fast-marquee';
import { getFCTheme, getDataTestId } from '../../utils';
import {
    CWrap,
    Item,
    ItemTitle,
    ItemSubTitle,
    ItemText,
} from './Marquee.styles';
import { MarqueeItem, MarqueeLibConfig } from '../../types';

export interface Props {
    type: string;
    data: MarqueeItem[];
    separator?: {
        isUsed: boolean;
        component: React.FC | null;
    };
    config?: MarqueeLibConfig;
    groupToken?: string;
    hlaToken?: string;
    actionToken?: string;
    // Reserved to customize additionally separate component nodes
    // customize?: Record<string, unknown>;
}

export const Marquee: React.FC<Props> = (props): JSX.Element => {
    const {
        type,
        data,
        separator = {
            isUsed: false,
            component: null,
        },
        config,
        groupToken = '',
        hlaToken = '',
        actionToken = '',
    } = props;

    const theme = getFCTheme('Marquee', type);
    const curFCId = 'MARQUEE';
    const testIds = {
        CWrap: getDataTestId(
            `${curFCId}__CWRAP__IS_${type}`,
            hlaToken,
            actionToken
        ),
        Item: getDataTestId(
            `${curFCId}__ITEM__IS_${type}`,
            hlaToken,
            actionToken
        ),
        ItemTitle: getDataTestId(
            `${curFCId}__ITEM_TITLE__IS_${type}`,
            hlaToken,
            actionToken
        ),
        ItemSubTitle: getDataTestId(
            `${curFCId}__ITEM_SUBTITLE__IS_${type}`,
            hlaToken,
            actionToken
        ),
        ItemText: getDataTestId(
            `${curFCId}__ITEM_TEXT__IS_${type}`,
            hlaToken,
            actionToken
        ),
    };
    const groupToken4Items = `${groupToken}__${type}_ITEM`;
    const { isUsed: withSeparator, component: Separator } = separator;

    return (
        <CWrap
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}>
            <FastMarquee {...config}>
                {data.map((item) => {
                    const { key, title, subtitle, text, iconFC: Icon } = item;

                    const itemTestIds = {
                        Item: `${testIds.Item}__IS_${key.toUpperCase()}`,
                        ItemTitle: `${
                            testIds.ItemTitle
                        }__IS_${key.toUpperCase()}`,
                        ItemSubTitle: `${
                            testIds.ItemSubTitle
                        }__IS_${key.toUpperCase()}`,
                        ItemText: `${
                            testIds.ItemText
                        }__IS_${key.toUpperCase()}`,
                    };

                    return (
                        <React.Fragment key={key}>
                            <Item
                                theme={theme.Item}
                                data-at-selector={groupToken4Items}
                                data-testid={itemTestIds.Item}>
                                {!!Icon && <Icon />}
                                {!!title && (
                                    <ItemTitle
                                        theme={theme.ItemTitle}
                                        data-testid={itemTestIds.ItemTitle}>
                                        {title}
                                    </ItemTitle>
                                )}
                                {!!subtitle && (
                                    <ItemSubTitle
                                        theme={theme.ItemSubTitle}
                                        data-testid={itemTestIds.ItemSubTitle}>
                                        {subtitle}
                                    </ItemSubTitle>
                                )}
                                {!!text && (
                                    <ItemText
                                        theme={theme.ItemText}
                                        data-testid={itemTestIds.ItemText}>
                                        {text}
                                    </ItemText>
                                )}
                            </Item>
                            {withSeparator && Separator && <Separator />}
                        </React.Fragment>
                    );
                })}
            </FastMarquee>
        </CWrap>
    );
};

export default Marquee;
