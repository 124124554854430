import React, { ReactElement } from 'react';
import { withBaseProps, BaseFCProps, NotFoundNodes } from 'uikit-lib';
import {
    CWrap,
    Container,
    BearTop,
    BearBottom,
    Content,
    ContentText,
    Title,
    Subtitle,
    ContentImage,
} from './NotFound.styles';

export interface Props extends BaseFCProps<NotFoundNodes> {
    images: {
        bearTop: string;
        bearBottom: string;
        contentImage: string;
    };
    title: string;
    subtitle: ReactElement;
    background: string;
}

const NotFound: React.FC<Props> = (props): JSX.Element => {
    const {
        theme,
        testIds,
        images: { bearBottom, bearTop, contentImage },
        title,
        subtitle,
        background,
    } = props;

    return (
        <CWrap
            theme={theme.CWrap}
            data-testid={testIds.CWrap}
            style={{ background }}>
            <Container theme={theme.Container} data-testid={testIds.Container}>
                <BearTop
                    theme={theme.BearTop}
                    data-testid={testIds.BearTop}
                    src={bearTop}
                    alt=''
                />
                <Content theme={theme.Content} data-testid={testIds.Content}>
                    <ContentImage
                        theme={theme.ContentImage}
                        data-testid={testIds.ContentImage}
                        src={contentImage}
                        alt=''
                    />
                    <ContentText
                        theme={theme.ContentText}
                        data-testid={testIds.ContentText}>
                        <Title theme={theme.Title} data-testid={testIds.Title}>
                            {title}
                        </Title>
                        <Subtitle
                            theme={theme.Subtitle}
                            data-testid={testIds.Subtitle}>
                            {subtitle}
                        </Subtitle>
                    </ContentText>
                </Content>
                <BearBottom
                    theme={theme.BearBottom}
                    data-testid={testIds.BearBottom}
                    src={bearBottom}
                    alt=''
                />
            </Container>
        </CWrap>
    );
};

export default withBaseProps(NotFound, {
    name: 'NotFound',
    code: 'NotFound',
    nodeNames: Object.keys(NotFound),
});
