import React from 'react';
import {
    BaseFCProps,
    InternshipRoadmapNodes,
    withBaseProps,
    Section,
} from 'uikit-lib';
import { SectionHeader } from 'src/components';
import { CWrap } from './InternshipRoadmap.styles';
import lang from './lang';
import RoadMap from 'src/components/RoadMap/RoadMap';
import { useStore } from '../../../../LocalDataStore';

export interface InternshipSelectionStep {
    creationDate: string;
    id: string;
    comment: string;
    description: string;
    period: string;
    name: string;
    number: number;
}

export interface Props extends BaseFCProps<InternshipRoadmapNodes> {
    data: InternshipSelectionStep[];
}

export const InternshipRoadmap: React.FC<Props> = (props): JSX.Element => {
    const { data, theme, testIds } = props;

    const {
        FetchedData: {
            homepageSettings: { traineeTitle, traineeDescription },
        },
    } = useStore();

    return (
        <Section
            id='calendar'
            type={'BASIC'}
            modifyClasses={{
                CWrap: 'isInternshipRoadmap',
            }}
            tokens={{}}>
            <SectionHeader
                type={'BASIC'}
                modifyClasses={{
                    CWrap: 'isInternshipRoadmap',
                }}
                lang={{
                    title: traineeTitle || '',
                    description: traineeDescription || '',
                }}
                tokens={{}}
            />
            <CWrap theme={theme.CWrap} data-testid={testIds.CWrap}>
                <RoadMap
                    lang={{
                        title: lang.roadmapTitle,
                    }}
                    tokens={{}}
                    items={data.map((d) => ({
                        key: d.id,
                        title: d.name,
                        comment: d.comment,
                        period: d.period,
                    }))}
                />
            </CWrap>
        </Section>
    );
};

export default withBaseProps(InternshipRoadmap, {
    name: 'InternshipRoadmap',
    code: 'INTERNSHIP_ROADMAP',
    nodeNames: Object.keys(InternshipRoadmapNodes),
});
