import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const CloseBtn = styled.button`
    ${getCSSObj}
`;
