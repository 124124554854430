import { LAYOUTING__CONTENT_AREA, TYPOGRAPHY__T3 } from '../../../../..';

export const theme = {
    CWrap: {
        position: 'relative',
        zIndex: 18,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
    },
    MainArea: {
        ...LAYOUTING__CONTENT_AREA,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80px',
    },
    Column: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
    },
    Copyright: {
        ...TYPOGRAPHY__T3,
        padding: '8px',
        color: 'var(--COLORS__GRAYSCALE____5)',
        marginRight: '8px',
    },
};

export default theme;
