/* eslint-disable @typescript-eslint/no-explicit-any */
import scheme from './entitiesScheme';

export const runSectionDataFetch = async (
    fetchFn: () => Promise<any>,
    params: {
        entityName:
            | 'directions'
            | 'phaseSelectionOfIntern'
            | 'internsFeedbacks'
            | 'questions'
            | 'registerFormConfig';
        write2StateFn: any;
        storeAction?: any;
    }
): Promise<unknown> => {
    const { entityName, write2StateFn, storeAction } = params;
    const output = await fetchFn().then((response: any) => {
        if (response?.data) {
            const items = [...response.data[entityName].get.items];
            if (write2StateFn) write2StateFn(items);
            if (storeAction) {
                const payload = {
                    [scheme[entityName]]: [
                        ...response.data[entityName].get.items,
                    ],
                };
                storeAction(payload);
            }
        }
    });
    return output;
};

export default runSectionDataFetch;
