import React from 'react';
import { ElasticCarousel } from './components';
import { MiniCard, FCTokens, CarouselOptions, MiniCardNode } from '../../';
import { MinicardElemName } from '../../components/MiniCard/MiniCard';

export interface Props {
    type: string;
    data: {
        nodes: MiniCardNode[];
        key: string;
        imgKey: string;
        classModifiers: {
            [key in MinicardElemName]?: string;
        };
    }[];
    options: CarouselOptions;
    tokens: FCTokens;
}

export const Carousel: React.FC<Props> = (props): JSX.Element => {
    const {
        type,
        data,
        options,
        tokens,
        tokens: { groupToken = '', hlaToken = '' },
    } = props;

    const curFCId = 'CAROUSEL';
    const groupToken4Slides = groupToken ? `${groupToken}__SLIDE` : '';
    const hlaToken4MiniCard = hlaToken
        ? `${hlaToken}__${curFCId}__IS_${type}`
        : '';

    return (
        <ElasticCarousel {...options} tokens={tokens}>
            {data.map((slide) => {
                const { nodes, key, imgKey, classModifiers } = slide;
                return (
                    <MiniCard
                        key={key}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        imgKey={imgKey}
                        data={nodes}
                        classModifiers={classModifiers}
                        type={`${type}_CARD`}
                        groupToken={groupToken4Slides}
                        hlaToken={hlaToken4MiniCard}
                    />
                );
            })}
        </ElasticCarousel>
    );
};

export default Carousel;
