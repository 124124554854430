import styled from '@emotion/styled';
import { getCSSObj } from '../../';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const PanesWrap = styled.div`
    ${getCSSObj}
`;
