import { DisplayCarouselParams, CarouselLocalStore } from '../../../../../';
import {
    getNumOfPages,
    getTrackWidth,
    getElemWidth,
    getStepDelta,
    getSteps2MoveTrack,
} from './';

export const changeStateAfterResize = (
    sourceParams: {
        breakpoints: {
            [key: string]: DisplayCarouselParams;
        };
        initialState: CarouselLocalStore;
        childrenLength: number;
    },
    setStateFn: (
        mutationObj: Partial<CarouselLocalStore> | CarouselLocalStore
    ) => void
) => {
    /**
    console.warn(
        'RUNTIME::STATUS::ElasticCarousel::changeStateAfterResize\nSource params are - ',
        sourceParams
    );
     */
    const { breakpoints, initialState, childrenLength } = sourceParams;
    const widthLimits = Object.keys(breakpoints)
        .map((key: string) => Number(key))
        .sort((a, b) => b - a);

    const curWindowWidth = window.innerWidth;
    let curWidthKey = null;
    for (let i = widthLimits.length; i >= 0; i--) {
        if (curWindowWidth <= widthLimits[i]) {
            curWidthKey = widthLimits[i];
            break;
        }
    }
    if (curWidthKey === null) {
        setStateFn({ ...initialState });
        return;
    }
    const items2Show = breakpoints[curWidthKey].items2Show;
    const numOfPages = getNumOfPages(childrenLength, items2Show);
    const trackWidth = getTrackWidth(childrenLength, items2Show);
    const elemWidth = getElemWidth(childrenLength);
    const stepDelta = getStepDelta(childrenLength, items2Show, trackWidth);

    const steps2MoveTrack = getSteps2MoveTrack(trackWidth, stepDelta);

    const targetMutation: Partial<CarouselLocalStore> | CarouselLocalStore = {
        ...breakpoints[curWidthKey],
        numOfPages,
        trackWidth,
        elemWidth,
        stepDelta,
        trackPosition: 0,
        activePage: 0,
        steps2MoveTrack,
    };
    setStateFn(targetMutation);
    return;
};

export default changeStateAfterResize;
