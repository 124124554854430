export type TextTagName = 'H2' | 'H3' | 'H4' | 'H5' | 'H6' | 'P';

export interface ContentBlock {
    key: string;
    i18nKey: string;
    tag: TextTagName;
}

export type ContentSequense = ContentBlock[];

export type ContentSeqDict = {
    [key: string]: ContentSequense;
};

export const sequenses: ContentSeqDict = {
    /**
     * MOCK DATA START =>
     */
    PROFILE____SYSTEM__CONFIRM: [
        {
            key: 'testParagraph1',
            i18nKey: 'testParagraph1',
            tag: 'P',
        },
        {
            key: 'testParagraph2',
            i18nKey: 'testParagraph2',
            tag: 'P',
        },
    ],
    /**
     * <= END OF MOCK DATA
     */
};

export default sequenses;
