export const ruLangs: Record<string, string> = {
    teamTitle:
        'В этой вкладке ты можешь внимательней ознакомиться с работой и требованиями в каждой команде. Откликаясь на направление, по мере отбора ты будешь узнавать о них еще больше и в конце концов поймешь, работа в каком отделе тебе ближе.',
    description: '',
    content: 'Мы ищем тех, кто хочет научиться:',
    candidateRequirement: 'Нам важно:',
    additionalRequirement: 'Бонусом будут:',
};

export default ruLangs;
