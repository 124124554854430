import { EntityNameForm, MenuBtnProps } from 'uikit-lib';
import { handleShowModal } from '../containers/pages/Home/api';
import { DataModelAction, ModalState } from 'src/types';

export const HEADER_MENU_ITEMS: MenuBtnProps[] = [
    {
        key: 'directions',
        type: 'HEADER_MENU_HASHLINK',
        routePath: '/#directions',
        entityNameParams: {
            code: 'directions',
            form: EntityNameForm.PLURAL,
        },
        menuType: 'BUTTON',
        actionToken: '',
        modifierClass: 'isNavigateBtn',
    },
    {
        key: 'calendar',
        type: 'HEADER_MENU_HASHLINK',
        routePath: '/#calendar',
        entityNameParams: {
            code: 'calendar',
            form: EntityNameForm.PLURAL,
        },
        menuType: 'BUTTON',
        actionToken: '',
        modifierClass: 'isNavigateBtn',
    },
    {
        key: 'reviews',
        type: 'HEADER_MENU_HASHLINK',
        routePath: '/#reviews',
        entityNameParams: {
            code: 'reviews',
            form: EntityNameForm.PLURAL,
        },
        menuType: 'BUTTON',
        actionToken: '',
        modifierClass: 'isNavigateBtn',
    },
    {
        key: 'faq',
        type: 'HEADER_MENU_HASHLINK',
        routePath: '/#FAQ',
        entityNameParams: {
            code: 'faq',
            form: EntityNameForm.PLURAL,
        },
        menuType: 'BUTTON',
        actionToken: '',
        modifierClass: 'isNavigateBtn',
    },
];

export const REG_FORM_BTN_DESCRIPTOR: MenuBtnProps = {
    key: 'fillRegForm',
    type: 'TRANSPARENT',
    routePath: '',
    entityNameParams: {
        code: 'fillRegForm',
        form: EntityNameForm.PLURAL,
    },
    menuType: 'BUTTON',
    actionToken: '',
    modifierClass: 'isFillFormBtn',
    handleOnClick: (params?: {
        mixinName:
            | 'showRegisterForm__isIntro'
            | 'showDirection'
            | 'showFeedback'
            | 'showDirectionDescription';
        setStateFn: DataModelAction<ModalState>;
    }) => {
        if (params) {
            const {
                mixinName = 'showRegisterForm__isIntro',
                setStateFn = () => {
                    return;
                },
            } = params;
            handleShowModal(mixinName, setStateFn);
        }
    },
};
