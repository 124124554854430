/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
    Logo,
    MenuData,
    Menu,
    convertMenuItems,
    BaseFCProps,
    PubHeaderNodes,
    EntitiesLocale,
    BurgerBtn,
    withBaseProps,
    MenuBtnProps,
} from '../../';
import { CWrap, MainArea } from './Header.styles';
import { runWithResize } from './api';

export interface Props extends BaseFCProps<PubHeaderNodes> {
    logoImagePath: string;
    menuItems: MenuBtnProps[];
    entitiesLocale: EntitiesLocale;
    actions: any;
}

export const Header: React.FC<Props> = (props): JSX.Element => {
    const [isVisibleMenu, setIsVisibleMenu] = useState<boolean>(false);
    const [isMobileMenu, setIsMobileMenu] = useState<boolean>(false);
    const navigate = useNavigate();

    const {
        theme,
        tokens: { groupToken },
        testIds,
        menuItems,
        entitiesLocale,
        logoImagePath,
        actions,
    } = props;

    const menuData: MenuData = convertMenuItems(
        menuItems,
        navigate,
        entitiesLocale,
        () => {
            setIsVisibleMenu(false);
        }
    );

    const resizeFn = () => runWithResize({ isMobileMenu, setIsMobileMenu });

    useEffect(() => {
        resizeFn();
        window.addEventListener('resize', resizeFn);

        return () => {
            window.removeEventListener('resize', resizeFn);
        };
    }, []);

    return (
        <CWrap theme={theme.CWrap} data-testid={testIds.CWrap}>
            <MainArea theme={theme.MainArea} data-testid={testIds.MainArea}>
                <Link to={'/'}>
                    <Logo
                        logoImagePath={logoImagePath}
                        hlaToken={groupToken}
                        type={'HEADER'}
                        mode={'IMAGE'}
                    />
                </Link>

                <BurgerBtn
                    tokens={{
                        hlaToken: 'APP_HEADER',
                        groupToken: 'BURGER_BTN',
                    }}
                    type={'DARK'}
                    isVisible={isMobileMenu}
                    handleOnClick={() => {
                        setIsVisibleMenu(!isVisibleMenu);
                    }}
                    isOpen={isVisibleMenu}
                />

                <Menu
                    tokens={{
                        hlaToken: 'APP_HEADER',
                    }}
                    isVisible={isVisibleMenu}
                    isMobile={isMobileMenu}
                    type={'HEADER'}
                    data={menuData}
                    actions={actions}
                />
            </MainArea>
        </CWrap>
    );
};

export default withBaseProps(Header, {
    name: 'Header',
    code: 'HEADER',
    nodeNames: Object.keys(Header),
});
