import { GradientShapeConfig } from '../../types';

const getRandomFloat = (min: number, max: number, decimals = 1): number => {
    const str = (Math.random() * (max - min) + min).toFixed(decimals);

    return parseFloat(str);
};

export const getGradientShape = (config: GradientShapeConfig) => {
    const {
        backgroundColor,
        width,
        height,
        top,
        left,
        leftEdgeMin,
        rightEdgeMax,
        rightEdgeMin,
        leftEdgeMax,
        scaleMin = 1,
        scaleMax = 2.5,
        opacity = 1,
        transform = 'rotate(-146.318deg)',
        transformSpeedInSeconds = 10,
        liquidSpeedInSeconds = 10,
        zIndex = 15,
        blur = 100,
    } = config;

    return {
        display: 'none',
        '@media screen and (min-width: 641px)': {
            display: 'block',
            position: 'absolute',
            backgroundColor,
            width,
            height,
            top,
            left,
            opacity,
            transform,
            zIndex,
            animation: `transform ${transformSpeedInSeconds}s ease-in-out infinite both alternate, movement ${liquidSpeedInSeconds}s ease-in-out infinite both`,
            filter: `blur(${blur}px)`,
            '@keyframes transform': {
                '0%,\n    100%': {
                    borderRadius: '33% 67% 70% 30% / 30% 40% 70% 70%',
                },
                '20%': { borderRadius: '37% 63% 51% 49% / 37% 35% 35% 63%' },
                '40%': { borderRadius: '36% 64% 64% 36% / 64% 48% 52% 26%' },
                '60%': { borderRadius: '37% 63% 51% 49% / 30% 30% 70% 73%' },
                '80%': { borderRadius: '40% 60% 42% 58% / 51% 51% 49% 59%' },
            },
            '@keyframes movement': {
                '0%, 100%': { transform: 'translate(0, 0)' },
                '50%': {
                    transform: `translate(${getRandomFloat(
                        leftEdgeMin,
                        leftEdgeMax
                    )}%, ${getRandomFloat(
                        rightEdgeMin,
                        rightEdgeMax
                    )}%) rotateY(10deg) scale(${getRandomFloat(
                        scaleMin,
                        scaleMax
                    )})`,
                },
            },
        },
    };
};
