import React from 'react';
import {
    MiniCardListNodes,
    withBaseProps,
    BaseFCProps,
    TabNodes,
    MiniCard,
} from '../../';

import { CWrap } from './Tab.styles';

export interface Props extends BaseFCProps<TabNodes> {
    tabId: string | number;
    caption: string;
    handleOnClick: (tabId: string | number) => void;
    isActive: boolean;
}

export const Tab: React.FC<Props> = (props): JSX.Element => {
    const {
        tabId,
        caption,
        handleOnClick,
        isActive = false,
        theme,
        testIds,
        tokens: { groupToken },
    } = props;

    return (
        <CWrap
            className={isActive ? 'isActive' : ''}
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}
            onClick={() => {
                if (handleOnClick) handleOnClick(tabId);
            }}>
            {caption}
        </CWrap>
    );
};

export default withBaseProps<TabNodes, Props>(Tab, {
    name: 'Tab',
    code: 'TAB',
    nodeNames: Object.keys(TabNodes),
});
