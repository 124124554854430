export const theme = {
    CWrap: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'max-content',
        padding: '30px 0 60px',

        '@media screen and (max-width: 640px)': {
            padding: '10px 0 60px',
        },
    },
    VisibleZone: {
        height: '548px',
    },
    Track: {},
    Item: {},
    Arrow: {
        '@media screen and (max-width: 1023px)': {
            display: 'none',
        },
    },
    PaginationWrap: {},
    Dots: {},
};

export default theme;
