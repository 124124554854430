export const theme = {
    CWrap: {
        '@media screen and (max-width: 640px)': {
            padding: '100px 0 15px',
        },
    },
    CloseBtn: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        width: '24px',
        height: '24px',

        color: 'var(--COLORS__GRAYSCALE____5)',
        backgroundColor: 'transparent',
        border: 'none',

        '@media screen and (max-width: 640px)': {
            top: '56px',
            right: '16px',
        },

        '&:hover': {
            color: 'var(--COLORS__GRAYSCALE____3)',
        },
    },
};

export default theme;
