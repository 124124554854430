import { gql } from '@apollo/client';

export const GET_REVIEWS_GQL = gql`
    query ($page: Int!, $size: Int!) {
        internsFeedbacks {
            get(
                filter: { page: $page, size: $size, sortByCreationDate: false }
            ) {
                count
                items {
                    id
                    creationDate
                    firstName
                    lastName
                    position
                    impression
                    feedback
                    visible
                    number
                    image {
                        fileName
                        contentType
                        fileLink
                    }
                }
            }
        }
    }
`;

export default GET_REVIEWS_GQL;
