export const theme = {
    EmployeePolicy: {
        padding: 'var(--LAYOUTING__CONTENT_AREA__IS_TABLET__PADDING)',
        margin: '-30px auto 30px',

        '@media screen and (max-width: 1024px)': {
            padding: '0px',

            margin: '-30px auto 0',
        },
    },
};

export default theme;
