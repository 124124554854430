/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { createContext, useContext, useReducer } from 'react';
import { initAppState, initModalState, initFetchedDataState } from './models';
import {
    DataModelAction,
    DataModelMutation,
    PubAppActionsDictionary,
    PubAppLocalStoreProviderValue,
    ModalState,
    ModelReducer,
    PubAppState,
    PubAppLocalDataStore,
    PubAppFetchedDataState,
} from 'src/types';

export const LocalStoreProviderContext =
    createContext<PubAppLocalStoreProviderValue>({
        store: null,
        actions: null,
    });

export interface Props {
    children: React.ReactNode;
}

export const LocalStoreProvider: React.FC<Props> = (props): JSX.Element => {
    const { children } = props;

    function runUseReducer<S>(initState: S) {
        const modelReducer: ModelReducer<S> = (
            state: S,
            action: DataModelMutation<S>
        ): S => {
            return { ...state, ...action.payload };
        };

        return useReducer(modelReducer, { ...initState });
    }

    const [App, dispatchApp] = runUseReducer<PubAppState>(initAppState);
    const [FetchedData, dispatchFetchedData] =
        runUseReducer<PubAppFetchedDataState>(initFetchedDataState);
    const [Modal, dispatchModal] = runUseReducer<ModalState>(initModalState);

    const setAppData: DataModelAction<PubAppState> = (payload) =>
        dispatchApp({ type: 'APP_MODEL_UPDATE', payload });
    const setModalData: DataModelAction<ModalState> = (payload) =>
        dispatchModal({ type: 'MODAL_MODEL_UPDATE', payload });
    const setFetchedData: DataModelAction<PubAppFetchedDataState> = (payload) =>
        dispatchFetchedData({ type: 'FETCHED_DATA_MODEL_UPDATE', payload });

    const providerValue: PubAppLocalStoreProviderValue = {
        store: {
            App,
            Modal,
            FetchedData,
        },
        actions: {
            setAppData,
            setModalData,
            setFetchedData,
        },
    };

    return (
        <LocalStoreProviderContext.Provider value={providerValue}>
            {children}
        </LocalStoreProviderContext.Provider>
    );
};

export const useAction = (
    actionNameList: Array<keyof PubAppActionsDictionary>
): PubAppActionsDictionary => {
    const { actions } = useContext(LocalStoreProviderContext);
    if (!actions) {
        throw new Error('useAction must be used within LocalStoreProvider');
    } else {
        if (!actionNameList?.length) return actions;
        // @ts-ignore
        const result: PubAppActionsDictionary = {};
        actionNameList.map((actionName) => {
            if (
                Object.prototype.hasOwnProperty.call(actions, actionName) &&
                actions[actionName]
            ) {
                // @ts-ignore
                result[actionName] = actions[actionName];
            }
        });
        return result;
    }
};

export const useStore = (): PubAppLocalDataStore => {
    const { store } = useContext(LocalStoreProviderContext);
    if (store === undefined || store === null)
        throw new Error('useLocalStore must be used within LocalStoreProvider');
    return store;
};

export default LocalStoreProvider;
