import React from 'react';
import { TextArea } from 'uikit-lib';
import lang from './lang';
import { CSSObject } from '@emotion/react';
import { useStore } from 'src/containers';
import { DataInputWrap, DataInputHint } from './RegisterForm.styles';
import { Controller, Control, FieldValues, FieldErrors } from 'react-hook-form';
import { VALIDATION_RULES } from 'src/constants';

export type Props = {
    testIds: Record<string, string>;
    theme: Record<string, CSSObject>;
    control: Control<FieldValues> | undefined;
    errors: FieldErrors;
};
export const Interests: React.FC<Props> = (props): JSX.Element => {
    const { theme, testIds, control, errors } = props;
    const {
        FetchedData: {
            registerFormConfig: {
                descriptionYourSelfIsRequired,
                whatYouWantToKnowAboutInternshipIsRequired,
            },
        },
    } = useStore();
    const { placeholders } = lang;

    return (
        <>
            {descriptionYourSelfIsRequired && (
                <DataInputWrap
                    theme={theme.DataInputWrap}
                    data-testid={testIds.DataInputWrap}>
                    <Controller
                        name={'descriptionYourSelf'}
                        control={control}
                        rules={VALIDATION_RULES.descriptionYourSelf}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <TextArea
                                    type={'BASIC'}
                                    tokens={{}}
                                    value={value}
                                    isValid={!errors.descriptionYourSelf}
                                    placeholder={placeholders.aboutSelfInput}
                                    onChange={onChange}
                                />
                            );
                        }}
                    />
                    {!!errors?.descriptionYourSelf?.message && (
                        <DataInputHint
                            theme={theme.DataInputHint}
                            data-testid={testIds.DataInputHint}>
                            {errors.descriptionYourSelf.message as string}
                        </DataInputHint>
                    )}
                </DataInputWrap>
            )}

            {whatYouWantToKnowAboutInternshipIsRequired && (
                <DataInputWrap
                    theme={theme.DataInputWrap}
                    data-testid={testIds.DataInputWrap}>
                    <Controller
                        name={'whatYouWantToKnowAboutInternship'}
                        control={control}
                        rules={
                            VALIDATION_RULES.whatYouWantToKnowAboutInternship
                        }
                        render={({ field: { onChange, value } }) => {
                            return (
                                <TextArea
                                    type={'BASIC'}
                                    tokens={{}}
                                    value={value}
                                    isValid={
                                        !errors.whatYouWantToKnowAboutInternship
                                    }
                                    placeholder={placeholders.what2KnowInput}
                                    onChange={onChange}
                                />
                            );
                        }}
                    />
                    {!!errors?.whatYouWantToKnowAboutInternship?.message && (
                        <DataInputHint
                            theme={theme.DataInputHint}
                            data-testid={testIds.DataInputHint}>
                            {
                                errors.whatYouWantToKnowAboutInternship
                                    .message as string
                            }
                        </DataInputHint>
                    )}
                </DataInputWrap>
            )}
        </>
    );
};

export default Interests;
