export const getStepDelta = (
    childrenLength: number,
    items2Show: number,
    trackWidth: number
): number => {
    const output = trackWidth / (childrenLength / items2Show);
    return output;
};

export default getStepDelta;
