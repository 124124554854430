/* eslint-disable @typescript-eslint/ban-ts-comment */
export const theme = {
    CWrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '32px',

        '@media screen and (max-width: 640px)': {
            '.registerFormButton': {
                width: '100%',
            },
        },
    },
    DataInputWrap: {
        position: 'relative',
        width: '100%',
    },
    DataInputHint: {
        position: 'absolute',
        width: '100%',
        top: '100%',
        color: 'var(--COLORS__SECONDARY_PALETTE____RED)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '14px',
    },
};

export default theme;
