export interface RegisterFormLang {
    title: string;
    submitBtnCaption: string;
    placeholders: Record<string, string>;
    checkboxLabels: Record<string, React.FC>;
    errors: Record<string, string>;
}
export const ruLangs: Record<string, string> = {
    HOME_PAGE__MODAL____REGISTER_FORM__REG_SUCCESS:
        'Поздравляем! Вы успешно зарегистрировались. Сейчас вам на почту придет письмо о дальнейших шагах и письмо о назначении онлайн-тестирования на платформе Teachbase',
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__IS_SERVER_ERROR:
        'Наш сервер временно недоступен. Проверьте, пожалуйста, соединение с интернетом и попробуйте оправить заявку на регистрацию еще раз.',
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__COURSE_DOESNT_HAVE_INTO_FORM_SETTINGS:
        'Курс не назначен в настройках администратора',
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__EMAIL_AND_FIRSTNAME_ARE_REQUIRED:
        'Имя и email являются обязательными полями',
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__USER_IS_EXISTING_ON_THIS_COURSE:
        'Вы уже проходили регистрацию на данный курс',
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__YOU_CAN_NOT_USING_AUTH:
        'Ваша заявка принята',
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__CAPTCHA_IS_NOT_VALID:
        'Капча не сработала',
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__LTI_ERROR:
        'Ошибка настроек LTI',
};

export default ruLangs;
