import React, { Children, useReducer, useEffect, useRef } from 'react';
import {
    getFCTheme,
    getDataTestId,
    reducer,
    FCTokens,
    CarouselOptions,
    CarouselLocalStore,
} from '../../../..';
import { Arrow, Pagination, Track } from '..';
import { CWrap, Main, VisibleZone } from './ElasticCarousel.styles';
import { changeStateAfterResize, setTrackParams, getInitialState } from './api';

export interface Props extends CarouselOptions {
    tokens: FCTokens;
    children: React.ReactNode;
}

export const ElasticCarousel: React.FC<Props> = (props): JSX.Element => {
    const {
        type,
        isInfinity = false,
        isVertical = false,
        items2Show = 1,
        items2Scroll,
        showNavigation = true,
        showPagination = true,
        itemPaddings = [0, 0, 0, 0],
        breakpoints,
        tokens: { groupToken = '', hlaToken = '' },
        children,
    } = props;

    const originalChildren = Children.toArray(children);

    const initialState: CarouselLocalStore = getInitialState({
        childrenLength: originalChildren.length,
        items2Show,
        items2Scroll,
        itemPaddings,
    });
    const [state, dispatch] = useReducer(
        reducer<CarouselLocalStore>,
        initialState
    );

    const setState = (
        payload: Partial<CarouselLocalStore> | CarouselLocalStore
    ): void => {
        dispatch({ type: 'CAROUSEL_STATE_UPDATE', payload });
    };

    const theme = getFCTheme('Carousel', type);
    const curFCId = 'ELASTIC_CAROUSEL';
    const testIds = {
        CWrap: getDataTestId(`${curFCId}__CWRAP`, hlaToken, ''),
        VisibleZone: getDataTestId(`${curFCId}__VISIBLE_ZONE`, hlaToken, ''),
    };

    const visibleZoneRef = useRef<HTMLDivElement>(null);

    const handleResize = () => {
        if (breakpoints)
            changeStateAfterResize(
                {
                    breakpoints,
                    initialState,
                    childrenLength: originalChildren.length,
                },
                setState
            );
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <CWrap
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}>
            <Main>
                {showNavigation && (
                    <Arrow
                        isDisabled={state.activePage === 0 && !isInfinity}
                        type={isVertical ? 'UP' : 'LEFT'}
                        handleOnClick={(): void => {
                            setTrackParams(
                                {
                                    activePage: state.activePage,
                                    steps2MoveTrack: state.steps2MoveTrack,
                                    numOfPages: state.numOfPages,
                                },
                                setState,
                                'MOVE2PREV'
                            );
                        }}
                        tokens={{
                            groupToken,
                            hlaToken,
                        }}
                        theme={theme.Arrow}
                    />
                )}
                <VisibleZone
                    ref={visibleZoneRef}
                    theme={theme.VisibleZone}
                    data-at-selector={groupToken}
                    data-testid={testIds.VisibleZone}>
                    <Track
                        childrenList={originalChildren}
                        curWidth={state.trackWidth}
                        childWidth={state.elemWidth}
                        enableSwipe
                        enableMouseSwipe
                        preventDefaultTouchmoveEvent={false}
                        position={state.trackPosition}
                        itemPaddings={state.itemPaddings}
                        isVertical={isVertical}
                        onSwipeLeft={() => {
                            if (state.activePage < state.numOfPages - 1) {
                                setTrackParams(
                                    {
                                        numOfPages: state.numOfPages,
                                        steps2MoveTrack: state.steps2MoveTrack,
                                        activePage: state.activePage + 1,
                                    },
                                    setState
                                );
                            }
                        }}
                        onSwipeRight={() => {
                            if (state.activePage > 0) {
                                setTrackParams(
                                    {
                                        numOfPages: state.numOfPages,
                                        steps2MoveTrack: state.steps2MoveTrack,
                                        activePage: state.activePage - 1,
                                    },
                                    setState
                                );
                            }
                        }}
                        handleOnSwiped={(): void => {
                            return;
                        }}
                        handleOnSwiping={(): void => {
                            return;
                        }}
                        handleOnItemClick={(): void => {
                            return;
                        }}
                        tokens={{
                            groupToken,
                            hlaToken,
                        }}
                        theme={{
                            CWrap: theme.Track,
                            Item: theme.Item,
                        }}
                    />
                </VisibleZone>
                {showNavigation && (
                    <Arrow
                        isDisabled={
                            state.activePage === state.numOfPages - 1 &&
                            !isInfinity
                        }
                        type={isVertical ? 'DOWN' : 'RIGHT'}
                        handleOnClick={(): void => {
                            setTrackParams(
                                {
                                    activePage: state.activePage,
                                    steps2MoveTrack: state.steps2MoveTrack,
                                    numOfPages: state.numOfPages,
                                },
                                setState,
                                'MOVE2NEXT'
                            );
                        }}
                        tokens={{}}
                        theme={theme.Arrow}
                    />
                )}
            </Main>
            {showPagination && (
                <Pagination
                    params={{
                        isVertical,
                        numOfPages: state.numOfPages,
                        activePage: state.activePage,
                        handleOnClick: (pageId: number): void => {
                            setTrackParams(
                                {
                                    numOfPages: state.numOfPages,
                                    steps2MoveTrack: state.steps2MoveTrack,
                                    activePage: pageId,
                                },
                                setState
                            );
                            return;
                        },
                    }}
                    tokens={{}}
                    theme={{
                        CWrap: theme.PaginationWrap,
                        Dot: theme.Dot,
                    }}
                />
            )}
        </CWrap>
    );
};

export default ElasticCarousel;
