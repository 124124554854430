import { GradientShapeConfig } from '../types';

export const FAQ_GREEN: GradientShapeConfig = {
    backgroundColor: '#2AF587',
    left: '50%',
    top: '150px',
    width: '273.174px',
    height: '220.986px',
    transform: 'rotate(-146.318deg)',
    opacity: 0.7,
    blur: 100,
    zIndex: 5,
    transformSpeedInSeconds: 20,
    liquidSpeedInSeconds: 15,
    leftEdgeMin: -50,
    leftEdgeMax: 70,
    rightEdgeMin: 40,
    rightEdgeMax: 90,
    scaleMin: 2.4,
    scaleMax: 3.9,
};

export const FAQ_PURPLE: GradientShapeConfig = {
    backgroundColor: '#1A3270',
    left: '60%',
    top: '250px',
    width: '230.295px',
    height: '297.54px',
    transform: 'rotate(-171.405deg)',
    blur: 100,
    zIndex: 5,
    transformSpeedInSeconds: 15,
    liquidSpeedInSeconds: 12,
    leftEdgeMin: -70,
    leftEdgeMax: 50,
    rightEdgeMin: 50,
    rightEdgeMax: 110,
    scaleMin: 2.5,
    scaleMax: 3.7,
};
