export const theme = {
    CWrap: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        gap: '24px',

        width: '100%',
    },
};

export default theme;
