import { CarouselLocalStore } from '../../../../../';
import { Params2CalcTrackPosition, getTrackPosition } from './';

export const setTrackParams = (
    sourceParams: Params2CalcTrackPosition,
    setStateFn: (payload: Partial<CarouselLocalStore>) => void,
    mode?: 'MOVE2PREV' | 'MOVE2NEXT'
): void => {
    let payload: Partial<CarouselLocalStore> = {};
    if (mode) {
        payload = getTrackPosition(sourceParams, mode);
    } else {
        const { steps2MoveTrack, activePage } = sourceParams;
        payload = {
            trackPosition: steps2MoveTrack[activePage],
            activePage,
        };
    }
    setStateFn(payload);
};

export default setTrackParams;
