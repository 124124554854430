import React from 'react';
import { withBaseProps, BaseFCProps, TabPaneNodes } from '../../';

import { CWrap } from './TabPane.styles';

export interface Props extends BaseFCProps<TabPaneNodes> {
    isActive: boolean;
    FC2Render: React.FC;
}

export const TabPane: React.FC<Props> = (props): JSX.Element => {
    const {
        isActive,
        FC2Render,
        theme,
        testIds,
        tokens: { groupToken },
    } = props;

    return (
        <CWrap
            className={isActive ? 'isActive' : 'isHidden'}
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}>
            <FC2Render />
        </CWrap>
    );
};

export default withBaseProps<TabPaneNodes, Props>(TabPane, {
    name: 'TabPane',
    code: 'TAB_PANE',
    nodeNames: Object.keys(TabPaneNodes),
});
