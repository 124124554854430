import {
    getButtonProps,
    BtnColorModeName,
    BtnSizeModeName,
    BTN_COLOR_MODES,
    BTN_SIZE_MODES,
} from '../../../../../';

const spBtnProps = getButtonProps(
    {
        color: BtnColorModeName.GREEN,
        size: BtnSizeModeName.DEFAULT_48,
        icon: 'ALONG',
        withShadow: false,
    },
    {
        color: BTN_COLOR_MODES,
        size: BTN_SIZE_MODES,
    }
);

export const theme = {
    ...spBtnProps,
};

export default theme;
