import { FC } from 'react';
import { DirectionsNodes, FCTheme } from 'uikit-lib';
import { Direction } from 'src/types';
import {
    DirectionListItem as Component,
    DirectionListItemContent,
    DirectionListItemName,
    DirectionListItemType,
} from './Directions.styles';
import { Separator } from '../../../../../components';

interface Props {
    groupToken: string | undefined;
    theme: FCTheme<DirectionsNodes>;
    dataTestid: Record<DirectionsNodes, string>;
    item: Direction;
    handleOnClickItem: () => void;
}

export const DirectionListItem: FC<Props> = ({
    theme,
    groupToken,
    dataTestid,
    item,
    handleOnClickItem,
}) => {
    return (
        <Component
            data-at-selector={groupToken}
            theme={theme.DirectionListItem}
            onClick={handleOnClickItem}
            data-testid={dataTestid.DirectionListItem}>
            <DirectionListItemType
                theme={theme.DirectionListItemType}
                data-testid={dataTestid.DirectionListItemType}>
                {item?.type?.name ?? ''}
            </DirectionListItemType>

            <DirectionListItemContent
                theme={theme.DirectionListItemContent}
                data-testid={dataTestid.DirectionListItemContent}>
                <DirectionListItemName
                    theme={theme.DirectionListItemName}
                    data-testid={dataTestid.DirectionListItemName}>
                    {item.name}
                </DirectionListItemName>
                <Separator
                    type={'DIRECTIONS'}
                    tokens={{
                        hlaToken: 'DIRECTION_LIST_ITEM_SEPARATOR',
                        groupToken: 'HOME_FAQ_MARQUEE_SEPARATOR',
                    }}
                />
            </DirectionListItemContent>
        </Component>
    );
};

export default DirectionListItem;
