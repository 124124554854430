import { TYPOGRAPHY__S4, TYPOGRAPHY__T1 } from '../../../../';
import { FEEDBACKS_CARD } from '.';

export const theme = {
    CWrap: {
        position: 'relative',
        width: '100%',
        minHeight: '32px',
    },
    Image: {
        ...FEEDBACKS_CARD.Image,
    },
    Title: {
        ...TYPOGRAPHY__S4,
        fontWeight: 600,
        padding: '0 0 0 58px',
    },
    SubTitle: {
        ...TYPOGRAPHY__T1,
        padding: '0 0 0 58px',
        color: 'var(--COLORS__GRAYSCALE____4)',
    },
};

export default theme;
