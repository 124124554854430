import styled from '@emotion/styled';
import { getCSSObj } from '../../utils';

export const H1 = styled.h1`
    ${getCSSObj}
`;

export const H2 = styled.h2`
    ${getCSSObj}
`;

export const H3 = styled.h3`
    ${getCSSObj}
`;

export const H4 = styled.h4`
    ${getCSSObj}
`;

export const H5 = styled.h5`
    ${getCSSObj}
`;

export const H6 = styled.h6`
    ${getCSSObj}
`;

export const P = styled.p`
    ${getCSSObj}
`;

export const nodeMap = { H1, H2, H3, H4, H5, H6, P };
