import styled from '@emotion/styled';
import { getCSSObj } from '../../utils';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const Placeholder = styled.div`
    ${getCSSObj}
`;

export const InputArea = styled.textarea`
    ${getCSSObj}
`;
