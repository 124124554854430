import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { BaseFCProps, withBaseProps, RoadMapNodes } from 'uikit-lib';
import {
    CWrap,
    List,
    ListItem,
    ListItemDescription,
    ListItemDescriptionComment,
    ListItemDescriptionTitle,
    ListItemLines,
    ListItemLinesCenter,
    ListItemLinesDown,
    ListItemLinesUp,
    ListItemPeriod,
    Title,
} from './RoadMap.styles';

export interface Props extends BaseFCProps<RoadMapNodes> {
    lang: {
        title: string;
    };
    items: {
        key: string;
        period?: string;
        title?: string;
        comment?: string;
    }[];
}

export const RoadMap: React.FC<Props> = (props): JSX.Element => {
    const {
        theme,
        tokens: { groupToken = 'ROADMAP' },
        testIds,
        lang: { title },
        items,
    } = props;

    return (
        <CWrap
            data-at-selector={groupToken}
            theme={theme.CWrap}
            data-testid={testIds.CWrap}>
            <Title theme={theme.Title} data-testid={testIds.Title}>
                {title}
            </Title>
            <List theme={theme.List} data-testid={testIds.List}>
                {items &&
                    items.map((item) => (
                        <ListItem
                            key={item.key}
                            theme={theme.ListItem}
                            data-testid={testIds.ListItem}>
                            <ListItemPeriod
                                theme={theme.ListItemPeriod}
                                data-testid={testIds.ListItemPeriod}>
                                {item?.period ?? ''}
                            </ListItemPeriod>
                            <ListItemLines
                                theme={theme.ListItemLines}
                                data-testid={testIds.ListItemLines}>
                                <ListItemLinesUp
                                    className='line-up'
                                    theme={theme.ListItemLinesUp}
                                    data-testid={testIds.ListItemLinesUp}
                                />
                                <ListItemLinesCenter
                                    theme={theme.ListItemLinesCenter}
                                    data-testid={testIds.ListItemLinesCenter}
                                />
                                <ListItemLinesDown
                                    className='line-down'
                                    theme={theme.ListItemLinesDown}
                                    data-testid={testIds.ListItemLinesDown}
                                />
                            </ListItemLines>
                            <ListItemDescription
                                theme={theme.ListItemDescription}
                                data-testid={testIds.ListItemDescription}>
                                <ListItemDescriptionTitle
                                    theme={theme.ListItemDescriptionTitle}
                                    data-testid={
                                        testIds.ListItemDescriptionTitle
                                    }>
                                    {item.title}
                                </ListItemDescriptionTitle>
                                {item.comment && (
                                    <ListItemDescriptionComment
                                        theme={theme.ListItemDescriptionComment}
                                        data-testid={
                                            testIds.ListItemDescriptionComment
                                        }
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                item.comment
                                            ),
                                        }}
                                    />
                                )}
                            </ListItemDescription>
                        </ListItem>
                    ))}
            </List>
        </CWrap>
    );
};

export default withBaseProps(RoadMap, {
    name: 'RoadMap',
    code: 'ROADMAP',
    nodeNames: Object.keys(RoadMapNodes),
});
