import { css } from '@emotion/react';

export const GlobalStyles = css`
    html,
    body {
        position: relative;
        height: max-content;
        font-weight: 300;
        margin: 0;
        padding: 0;
        font-family: 'Kaspersky';
        display: flex;
        flex-direction: column;

        &.isModalOpened {
            overflow: hidden;
        }
    }

    html {
        scroll-padding-top: 40px;
    }

    body {
        width: auto;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;

        &.is-header-menu-open {
            height: 100vh;
            width: 100vw;
            overflow: hidden;
            position: fixed;
        }
    }

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    #root {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%;
    }

    p {
        margin: 0;
    }

    a {
        color: var(--COLORS__PRIMARY_PALETTE____GREEN_TEXT);
        font-weight: var(--TYPOGRAPHY__CORE____FONT_WEIGHT__IS_MEDIUM);
        transition: all 0.3s ease;

        &:hover {
            color: var(--COLORS__PRIMARY_PALETTE____GREEN);
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    u {
        text-decoration: underline;
    }

    i {
        font-style: italic;
    }

    *[contenteditable='true'] {
        user-modify: read-write;

        b,
        strong {
            font-weight: bold;
        }

        ol,
        ul {
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 40px;
        }

        ol {
            list-style-type: decimal;
        }

        ul {
            list-style-type: disc;
        }

        h1 {
            display: block;
            font-size: 2em;
            margin-block-start: 0.67em;
            margin-block-end: 0.67em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
        }

        h2 {
            display: block;
            font-size: 1.5em;
            margin-block-start: 0.83em;
            margin-block-end: 0.83em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
        }

        pre {
            display: block;
            font-family: monospace;
            white-space-collapse: preserve;
            text-wrap: inherit;
            margin: 1em 0px;
        }
    }

    a[role='button'],
    button {
        &:focus,
        &:active,
        &:hover {
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    input {
        &:focus,
        &:hover {
            outline: none;
        }
    }
`;
