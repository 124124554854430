import React from 'react';
import { Helmet } from 'react-helmet';

export interface LangDictionary {
    title: string;
    description: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
}

export interface Props {
    lang: LangDictionary;
}

export const HelmetBlock: React.FC<Props> = (props): JSX.Element => {
    const {
        lang: { title, description, ogTitle, ogDescription, ogImage },
    } = props;

    return (
        <Helmet>
            {!!title && <title>{title}</title>}
            {!!description && (
                <meta property='description' content={description} />
            )}
            {!!ogTitle && <meta property='og:title' content={ogTitle} />}
            {!!ogDescription && (
                <meta property='og:description' content={ogDescription} />
            )}
            {!!ogImage && <meta property='og:image' content={ogImage} />}
        </Helmet>
    );
};

export default HelmetBlock;
