import React from 'react';
import {
    withBaseProps,
    HomeIntroBannerNodes,
    BaseFCProps,
    Button,
    GetFile,
} from 'uikit-lib';
import { CWrap, Title, Image, Column } from './HomeIntroBanner.styles';
import lang from './lang';

export interface Props extends BaseFCProps<HomeIntroBannerNodes> {
    content: {
        link: string;
        description: string;
        image: GetFile | null;
    };
}

export const HomeIntroBanner: React.FC<Props> = (props): JSX.Element => {
    const {
        theme,
        tokens: { hlaToken },
        testIds,
        content: { link, description, image },
    } = props;

    return (
        <CWrap theme={theme.CWrap} data-testid={testIds.CWrap}>
            <Column theme={theme.Column} data-testid={testIds.Column}>
                <Title theme={theme.Title} data-testid={testIds.Title}>
                    {description}
                </Title>

                <Button
                    type={'TRANSPARENT'}
                    caption={lang.bannerBtnCaption}
                    tokens={{
                        groupToken: 'HOME_INTRO__BANNER_BTN',
                        hlaToken,
                        actionToken: 'HOME_INTRO__BANNER',
                    }}
                    handleOnClick={() => window.open(link, '_blank')}
                />
            </Column>

            <Column theme={theme.Column} data-testid={testIds.Column}>
                <Image
                    theme={theme.Image}
                    data-testid={testIds.Image}
                    style={{
                        backgroundImage: `url("${image?.fileLink || ''}")`,
                        // 'url("./assets/images/home/home_intro_banner_image.png")',
                    }}
                />
            </Column>
        </CWrap>
    );
};

export default withBaseProps(HomeIntroBanner, {
    name: 'HomeIntroBanner',
    code: 'HOME_INTRO_BANNER',
    nodeNames: Object.keys(HomeIntroBannerNodes),
});
