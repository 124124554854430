import styled from '@emotion/styled';
import { getCSSObj } from '../../../../utils';

export const CWrap = styled.button`
    box-sizing: border-box;
    padding: 0;
    border: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    outline: none;
    background: var(--COLORS__GRAYSCALE____6);

    transition: all 0.3s ease;

    &:hover,
    &:focus {
        cursor: pointer;
        background: var(--COLORS__GRAYSCALE____4);
    }

    &.isActive {
        width: 70px;
        border-radius: 5px;
        background: var(--COLORS__PRIMARY_PALETTE____GREEN);
        &:hover {
            background: var(--COLORS__PRIMARY_PALETTE____GREEN_DARK);
        }
    }

    ${getCSSObj}
`;
