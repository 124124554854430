export const runWithResize = (
    setImagePostfix: React.Dispatch<React.SetStateAction<string>>
) => {
    if (window.innerWidth <= 640) {
        return setImagePostfix('xs');
    }
    if (window.innerWidth <= 1024) {
        return setImagePostfix('md');
    }

    return setImagePostfix('lg');
};

export default runWithResize;
