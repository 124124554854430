import React, { MouseEventHandler } from 'react';
import { CWrap, BtnLoader } from './Button.styles';
import {
    FCTokens,
    BtnIconParams,
    getFCTheme,
    getDataTestId,
    CurIcon,
    BtnType,
} from '../../';

export interface Props {
    id?: string;
    type: keyof typeof BtnType | string;
    iconParams?: BtnIconParams;
    caption?: string | JSX.Element;
    handleOnClick?: MouseEventHandler;
    isDisabled?: boolean;
    modifierClass?: string | null;
    buttonType?: 'submit' | 'reset' | 'button';
    tokens: FCTokens;
    loading?: boolean;
}

export const Button: React.FC<Props> = (props): JSX.Element => {
    const {
        id = '',
        type,
        caption,
        iconParams,
        handleOnClick,
        isDisabled = false,
        tokens: { groupToken = '', hlaToken = '', actionToken = '' },
        modifierClass,
        loading = false,
    } = props;

    const theme = getFCTheme('Button', type);

    const curFCId = 'BUTTON';
    const testIds = {
        CWrap: getDataTestId(
            curFCId,
            hlaToken,
            isDisabled ? 'ACCESS__IS_DISABLED' : actionToken
        ),
        BtnLoader: getDataTestId(curFCId, hlaToken, 'BTN__LOADER'),
    };

    const disabledClass = isDisabled ? 'isDisabled' : '';
    const isLoadingClass = loading ? 'isLoading' : '';
    const modifyClass = modifierClass ? modifierClass : '';

    return (
        <CWrap
            id={id}
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}
            onClick={(evt) => {
                if (!isDisabled) {
                    if (handleOnClick) handleOnClick(evt);
                }
            }}
            className={`${modifyClass} ${disabledClass} ${isLoadingClass}`}>
            {loading && (
                <BtnLoader
                    theme={theme.BtnLoader}
                    data-testid={testIds.BtnLoader}>
                    <CurIcon sizeId={'INCREASED'} iconId={'LOAD'} />
                </BtnLoader>
            )}
            {!!iconParams && iconParams?.position === 'BEFORE' && (
                <CurIcon
                    sizeId={iconParams.sizeId}
                    iconId={iconParams.iconId}
                />
            )}
            {(!iconParams || iconParams?.position != 'ALONG') && caption}
            {!!iconParams && iconParams?.position !== 'BEFORE' && (
                <CurIcon
                    sizeId={iconParams.sizeId}
                    iconId={iconParams.iconId}
                />
            )}
        </CWrap>
    );
};

export default Button;
