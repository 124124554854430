import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Logo,
    Menu,
    MenuData,
    convertMenuItems,
    withBaseProps,
    BaseFCProps,
    PubFooterNodes,
    SocialLinks,
    NavBtn,
    EntitiesLocale,
} from 'uikit-lib';
import { CWrap, MainArea, Column, Copyright } from './Footer.styles';

export interface Props extends BaseFCProps<PubFooterNodes> {
    logoImagePath: string;
    menuItems: NavBtn[];
    socialLinks: NavBtn[];
    entitiesLocale: EntitiesLocale;
    copyright?: string;
}

const Footer: React.FC<Props> = (props): JSX.Element => {
    const navigate = useNavigate();
    const {
        theme,
        testIds,
        logoImagePath,
        copyright = '© 2023 АО «Лаборатория Касперского»',
        menuItems,
        socialLinks,
        entitiesLocale,
    } = props;

    const menuData: MenuData = convertMenuItems(
        menuItems,
        navigate,
        entitiesLocale
    );

    return (
        <CWrap theme={theme.CWrap} data-testid={testIds.CWrap}>
            <MainArea theme={theme.MainArea} data-testid={testIds.MainArea}>
                <Column theme={theme.Column} data-testid={testIds.Column}>
                    <Logo
                        hlaToken={'APP_FOOTER'}
                        type={'FOOTER'}
                        mode={'IMAGE'}
                        logoImagePath={logoImagePath}
                    />
                    <Copyright
                        theme={theme.Copyright}
                        data-testid={testIds.Copyright}>
                        {copyright}
                    </Copyright>
                    <Menu
                        tokens={{
                            hlaToken: 'APP_FOOTER',
                        }}
                        type={'FOOTER'}
                        data={menuData}
                    />
                </Column>
                <SocialLinks
                    tokens={{
                        hlaToken: 'APP_FOOTER',
                        groupToken: 'SOCIAL_LINKS',
                    }}
                    type={'FOOTER'}
                    items={socialLinks}
                />
            </MainArea>
        </CWrap>
    );
};

export default withBaseProps(Footer, {
    name: 'Footer',
    code: 'FOOTER',
    nodeNames: Object.keys(Footer),
});
