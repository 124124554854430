import { EntityNameForm, NavBtn } from 'uikit-lib';

export const SOCIAL_LINKS: NavBtn[] = [
    {
        key: 'telegram',
        iconParams: {
            sizeId: 'REGULAR',
            iconId: 'TELEGRAM',
            position: 'ALONG',
        },
        menuType: 'BUTTON',
        actionToken: '',
        type: 'FOOTER_MENU_BTN',
        // testIdPostfix: 'NAVIGATE__TELEGRAM',
        routePath: 'https://t.me/kaspersky_career',
        isDisabled: false,
    },
    {
        key: 'twitter',
        iconParams: {
            sizeId: 'REGULAR',
            iconId: 'TWITTER',
            position: 'ALONG',
        },
        menuType: 'BUTTON',
        actionToken: '',
        type: 'FOOTER_MENU_BTN',
        // testIdPostfix: 'NAVIGATE__TWITTER',
        routePath: 'https://twitter.com/kaspersky_ru/',
        isDisabled: true,
    },
    {
        key: 'youtube',
        iconParams: {
            sizeId: 'REGULAR',
            iconId: 'YOUTUBE',
            position: 'ALONG',
        },
        menuType: 'BUTTON',
        actionToken: '',
        type: 'FOOTER_MENU_BTN',
        // testIdPostfix: 'NAVIGATE__YOUTUBE',
        routePath: 'https://www.youtube.com/c/KasperskyTech',
        isDisabled: false,
    },
    {
        key: 'instagram',
        iconParams: {
            sizeId: 'REGULAR',
            iconId: 'INSTAGRAM',
            position: 'ALONG',
        },
        menuType: 'BUTTON',
        actionToken: '',
        type: 'FOOTER_MENU_BTN',
        // testIdPostfix: 'NAVIGATE__INSTAGRAM',
        routePath: 'https://www.instagram.com/kasperskylabrus/',
        isDisabled: true,
    },
    {
        key: 'vk',
        iconParams: {
            sizeId: 'REGULAR',
            iconId: 'VK',
            position: 'ALONG',
        },
        menuType: 'BUTTON',
        actionToken: '',
        type: 'FOOTER_MENU_BTN',
        // testIdPostfix: 'NAVIGATE__VK',
        routePath: 'https://vk.com/kaspersky.team',
        isDisabled: false,
    },
];

export const FOOTER_MENU_ITEMS: NavBtn[] = [
    {
        key: 'termsOfUse',
        entityNameParams: {
            code: 'termsOfUse',
            form: EntityNameForm.PLURAL,
        },
        menuType: 'BUTTON',
        actionToken: '',
        type: 'FOOTER_MENU_BTN',
        // testIdPostfix: 'NAVIGATE__TERMS_OF_USE',
        routePath: '/terms-of-use/',
        newTab: true,
    },
    {
        key: 'footerPrivacyPolicy',
        entityNameParams: {
            code: 'privacyPolicy',
            form: EntityNameForm.PLURAL,
        },
        menuType: 'BUTTON',
        actionToken: '',
        type: 'FOOTER_MENU_BTN',
        // testIdPostfix: 'NAVIGATE__PRIVACY_POLICY',
        routePath: '/privacy-policy/',
        newTab: true,
    },
    {
        key: 'footerContacts',
        entityNameParams: {
            code: 'contacts',
            form: EntityNameForm.PLURAL,
        },
        menuType: 'BUTTON',
        actionToken: '',
        type: 'FOOTER_MENU_BTN',
        // testIdPostfix: 'NAVIGATE__CONTACTS',
        routePath: 'https://www.kaspersky.ru/about/contact',
    },
];
