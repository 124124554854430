import {
    TYPOGRAPHY__H3,
    TYPOGRAPHY__S1,
    TYPOGRAPHY__S2,
    TYPOGRAPHY__S3,
    LIST_STYLING,
} from 'uikit-lib';

export const theme = {
    CWrap: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '60px 0 48px',
        gap: '32px',

        '&.isInternshipRoadmap': {
            color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        },

        '@media screen and (max-width: 900px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },

        '@media screen and (max-width: 640px)': {
            gap: '15px',
        },
    },
    Title: {
        ...TYPOGRAPHY__H3,
        width: '50%',
        textAlign: 'left',

        padding: '0 32px',
        '@media screen and (max-width: 900px)': {
            ...TYPOGRAPHY__S1,
            width: '50%',
            padding: '0px',
        },
        '@media screen and (max-width: 640px)': {
            ...TYPOGRAPHY__S1,
            width: '100%',
            padding: '0px',
        },
    },
    Description: {
        ...TYPOGRAPHY__S2,
        ...LIST_STYLING,
        width: '50%',
        textAlign: 'left',
        padding: '0 32px',
        '@media screen and (max-width: 900px)': {
            ...TYPOGRAPHY__S3,
            width: '50%',
            padding: '0px',
        },
        '@media screen and (max-width: 640px)': {
            width: '100%',
            ...TYPOGRAPHY__S3,
            padding: '0px',
        },
    },
};

export default theme;
