export const theme = {
    CWrap: {
        display: 'flex',
        gap: '20px',
    },
    BtnItem: {
        width: '26px',
        height: '26px',
        padding: '2px',
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',

        '&:hover': {
            color: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
        },

        '@media screen and (max-width: 1024px)': {
            width: '50px',
            height: '50px',

            svg: {
                transform: 'scale(2)',
            },
        },
    },
};

export default theme;
