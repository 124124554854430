import styled from '@emotion/styled';
import { getCSSObj } from '../../utils';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const Input = styled.input`
    ${getCSSObj}
`;

export const Placeholder = styled.div`
    ${getCSSObj}
`;

export const Label = styled.label`
    ${getCSSObj}
`;

export const ResetBtn = styled.button`
    ${getCSSObj}
`;
