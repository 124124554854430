export interface MenuParams {
    isMobileMenu: boolean;
    setIsMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const runWithResize = (menuParams: MenuParams) => {
    if (window.innerWidth < 1024) {
        menuParams.setIsMobileMenu(true);
    } else {
        menuParams.setIsMobileMenu(false);
    }
    return true;
};

export default runWithResize;
