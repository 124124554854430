import { CSSObject, useTheme } from '@emotion/react';
import { mergeDeep } from '.';

export const getFinalFCTheme = (
    theme: Record<string, CSSObject>,
    customize?: Record<string, CSSObject>
) => {
    if (!customize) return theme;

    const output = mergeDeep(theme, customize);
    return output;
};

/**
 *
 * @param {string} name
 * @param {string} type
 * @returns theme object 4 Target Component
 */
export const getFCTheme = (name: string, type?: string): any => {
    // TODO: need to be typed
    const fullTheme: any = useTheme();
    let theme = { ...fullTheme[name] };
    if (type) theme = theme[type];

    return theme;
};

/**
 *
 * @param props
 * @returns
 */
// TODO: need to be typed
export const getCSSObj = (props: any) => {
    return props?.theme && props.theme;
};
