import styled from '@emotion/styled';
import { getCSSObj } from '../../../../utils';

export const CWrap = styled.div<{
    elemWidth: number;
    itemPaddings: [number, number, number, number];
}>`
    width: ${(props) => props.elemWidth}%;
    min-width: ${(props) => props.elemWidth}%;
    padding: ${(props) => props.itemPaddings.join('px ')}px;
    ${getCSSObj}
`;
