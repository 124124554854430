import { TYPOGRAPHY__S4, TRANSITION__DEFAULT } from '../../../../';

export const theme = {
    CWrap: {
        display: 'flex',
        alignItems: 'center',
        gap: '32px',
        position: 'relative',

        '.isNavigateBtn': {
            color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
            position: 'relative',
            height: '100%',
            ...TRANSITION__DEFAULT,

            '&:after': {
                content: '""',
                position: 'absolute',
                left: '50%',
                right: 0,
                bottom: '-24px',
                width: '0px',
                height: '3px',
                background: 'transparent',
                borderRadius: '10px',
                ...TRANSITION__DEFAULT,
            },
            '&:hover': {
                '&:after': {
                    width: '100%',
                    left: '0%',
                    background: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
                },
            },
        },

        '&.isMobile': {
            position: 'fixed',
            zIndex: 19,
            flexDirection: 'column',
            width: '100%',
            height: 'calc(100vh - 64px)',
            top: '-120vh',
            left: 0,
            right: 0,
            backgroundColor: 'var(--COLORS__GRAYSCALE____2)',
            padding: '0 16px 16px',
            gap: '16px',

            '& .isNavigateBtn': {
                width: 'calc(100% + 32px)',
                ...TYPOGRAPHY__S4,
                height: '64px',
                padding: '0px',
                margin: '0 -32px',

                '&:hover': {
                    backgroundColor: 'var(--COLORS__GRAYSCALE____7)',
                },

                '&:after': {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 'auto',
                    width: '3px',
                    height: '100%',
                },
            },

            '& .isFillFormBtn': {
                width: '100%',
            },

            '&.isVisible': {
                top: '64px',
                transition: 'all 0.3s ease-in-out',
            },
        },
    },
};

export default theme;
