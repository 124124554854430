import { TYPOGRAPHY__S4 } from '../../coreTheme';

export const theme = {
    CWrap: {
        position: 'relative',
        ...TYPOGRAPHY__S4,
        fontWeight: 'var(--TYPOGRAPHY__CORE____FONT_WEIGHT__IS_SEMI_BOLD)',
        margin: '30px 0 0 0',

        '&.isActive': {
            '&::after': {
                content: '""',
                position: 'absolute',
                left: '0',
                right: '0',
                bottom: 0,

                width: 'calc(100%)',
                height: '3px',

                borderRadius: '10px',
                backgroundColor: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
            },
        },
    },
};

export default theme;
