export const theme = {
    CWrap: {
        overflow: 'auto',
        maxHeight: '100vh',
        padding: '0 15px 15px 0',

        '@media screen and (max-width: 640px)': {
            padding: '0 15px 100px 0',
        },

        '&::-webkit-scrollbar': {
            width: '8px',
        },

        '&::-webkit-scrollbar-track': {
            boxShadow: 'none',
        },

        '&::-webkit-scrollbar-thumb': {
            background: 'rgba(29, 54, 83, 0.17)',
            borderRadius: '5px',
        },
    },
};

export default theme;
