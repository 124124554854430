import { DataModelAction, ModalState } from 'src/types';

export const actionMixins = {
    showRegisterForm__isIntro: {
        actionName: 'SHOW_REGISTER_FORM',
        actionMode: 'IS_INTRO',
    },
    showDirection: {
        actionName: 'SHOW_DIRECTION',
    },
    showFeedback: {
        actionName: 'SHOW_FEEDBACK',
    },
    showDirectionDescription: {
        actionName: 'SHOW_DIRECTION',
    },
};

export const handleShowModal = (
    mixinName:
        | 'showRegisterForm__isIntro'
        | 'showDirection'
        | 'showFeedback'
        | 'showDirectionDescription',
    setModalDataFn: DataModelAction<ModalState>,
    dataId?: number
): void => {
    const mixin = actionMixins[mixinName];
    setModalDataFn({
        isVisible: true,
        type: 'CUSTOM_FC',
        sysType: 'IDLE',
        descriptor: {
            moduleName: 'HOME_PAGE__MODAL',
            moduleMode: '',
            actionType: 'CUSTOM_FC',
            actionMode: '',
            ...mixin,
        },
        dataId: dataId ?? 0,
    });
};

export default handleShowModal;
