import {
    TYPOGRAPHY__BH5,
    TYPOGRAPHY__H1,
    TYPOGRAPHY__S3,
    TYPOGRAPHY__H3,
} from 'uikit-lib';

export const theme = {
    CWrap: {
        padding: '400px 0 75px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        marginTop: '-25px',
        overflow: 'hidden',
        backgroundColor: 'var(--COLORS__GRAYSCALE____2)',

        '@media screen and (max-width: 1440px)': {
            padding: '230px 0 75px',
        },

        '@media screen and (max-width: 1024px)': {
            padding: '300px 0 40px',
            height: '730px',
        },

        '@media screen and (max-width: 640px)': {
            padding: '80px 0 40px',
            height: '860px',
        },
    },
    Title: {
        ...TYPOGRAPHY__BH5,
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        maxWidth: '520px',
        margin: '0 0 40px 0',

        '@media screen and (max-width: 1024px)': {
            ...TYPOGRAPHY__H1,
        },

        '@media screen and (max-width: 640px)': {
            ...TYPOGRAPHY__H3,
        },
    },
    Subtitle: {
        ...TYPOGRAPHY__S3,
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        '@media screen and (max-width: 640px)': {
            paddingLeft: '5px',
            paddingTop: '20px',
        },
    },
    TitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '20px',

        '@media screen and (max-width: 1024px)': {
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'flex-start',
        },
        '@media screen and (max-width: 640px)': {
            flexDirection: 'column',
            gap: 0,
            alignItems: 'flex-start',
        },
    },
};

export default theme;
