import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { BaseFCProps, Section, withBaseProps } from 'uikit-lib';
import { Direction } from 'src/types';
import {
    CWrap,
    Title,
    Header,
    Folder,
    SubTitle,
    DirectionList,
} from './Directions.styles';
import { DirectionsNodes } from 'uikit-lib/src/types/enums';
import { DirectionListItem } from './DirectionListItem';
import { useStore } from '../../../../LocalDataStore';

export interface Props extends BaseFCProps<DirectionsNodes> {
    data: Direction[];
    handleOnClickItem: (item: Direction, index: number) => void;
}

export const Directions: React.FC<Props> = (props): JSX.Element => {
    const {
        theme,
        tokens: { groupToken, hlaToken },
        testIds,
        data,
        handleOnClickItem,
    } = props;

    const {
        FetchedData: {
            homepageSettings: { directionsTitle, directionsDescription },
        },
    } = useStore();

    return (
        <CWrap
            id='directions'
            data-at-selector={groupToken}
            theme={theme.CWrap}
            data-testid={testIds.CWrap}>
            <Section type={'DIRECTIONS'} tokens={{ hlaToken }}>
                <Folder theme={theme.Folder} data-testid={testIds.Folder}>
                    <Header theme={theme.Header} data-testid={testIds.Header}>
                        <Title theme={theme.Title} data-testid={testIds.Title}>
                            {directionsTitle}
                        </Title>
                        {directionsDescription && (
                            <SubTitle
                                theme={theme.SubTitle}
                                data-testid={testIds.SubTitle}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        directionsDescription
                                    ),
                                }}
                            />
                        )}
                    </Header>
                    {data && (
                        <DirectionList
                            theme={theme.DirectionList}
                            data-testid={testIds.DirectionList}>
                            {data.map((item: Direction, index: number) => (
                                <DirectionListItem
                                    key={item.id}
                                    theme={theme}
                                    dataTestid={testIds}
                                    groupToken={groupToken}
                                    item={item}
                                    handleOnClickItem={() => {
                                        handleOnClickItem(item, index);
                                    }}
                                />
                            ))}
                        </DirectionList>
                    )}
                </Folder>
            </Section>
        </CWrap>
    );
};

export default withBaseProps(Directions, {
    name: 'Directions',
    code: 'DIRECTIONS',
    nodeNames: Object.keys(DirectionsNodes),
});
