import React from 'react';
import { getDataTestId } from '../../../../utils';
import { FCTokens } from '../../../..';
import { ItemWrapper } from '..';
import { Swipeable } from 'react-swipeable';
import { CSSObject } from '@emotion/react';
import { CWrap } from './Track.styles';

export interface Props {
    childrenList: React.ReactNode[];
    curWidth: number;
    childWidth: number;
    enableSwipe: boolean;
    enableMouseSwipe: boolean;
    preventDefaultTouchmoveEvent: boolean;
    position: number;
    itemPaddings: [number, number, number, number];
    isVertical: boolean;
    handleOnSwiped: () => void;
    handleOnSwiping: () => void;
    handleOnItemClick: () => void;
    onSwipeLeft: () => void;
    onSwipeRight: () => void;
    tokens: FCTokens;
    theme: {
        CWrap: CSSObject;
        Item: CSSObject;
    };
}

export const Track: React.FC<Props> = (props): JSX.Element => {
    const {
        childrenList,
        childWidth,
        curWidth,
        enableSwipe,
        enableMouseSwipe,
        preventDefaultTouchmoveEvent,
        position,
        itemPaddings,
        isVertical,
        handleOnSwiped,
        handleOnSwiping,
        tokens,
        tokens: { groupToken = '', hlaToken = '' },
        theme,
        onSwipeLeft,
        onSwipeRight,
    } = props;
    /**
    console.warn(
        'RUNTIME::STATUS::ElasticCarousel::Track\n Track position property equals to - ',
        position
    );
    console.warn(
        'RUNTIME::STATUS::ElasticCarousel::Track\n Track width property equals to - ',
        curWidth
    );
     */

    const curFCId = 'TRACK';
    const testId = getDataTestId(`${curFCId}_CWRAP`, hlaToken, '');

    return (
        <>
            {enableSwipe ? (
                <CWrap
                    theme={theme.CWrap}
                    data-at-selector={groupToken}
                    data-testid={testId}
                    curWidth={curWidth}
                    xDelta={isVertical ? 0 : -position}
                    yDelta={isVertical ? -position : 0}>
                    <Swipeable
                        style={{
                            display: 'flex',
                            flexDirection: isVertical ? 'column' : 'row',
                        }}
                        preventDefaultTouchmoveEvent={
                            preventDefaultTouchmoveEvent
                        }
                        trackMouse={enableMouseSwipe}
                        onSwipedLeft={onSwipeLeft}
                        onSwipedRight={onSwipeRight}
                        onSwiped={handleOnSwiped}
                        onSwiping={handleOnSwiping}>
                        {React.Children.map(childrenList, (child, index) => (
                            <ItemWrapper
                                key={index}
                                id={index}
                                tokens={tokens}
                                theme={theme.Item}
                                width={childWidth}
                                itemPaddings={itemPaddings}>
                                {child}
                            </ItemWrapper>
                        ))}
                    </Swipeable>
                </CWrap>
            ) : (
                'originalChildren'
            )}
        </>
    );
};

export default Track;
