import { LanguageDictionary } from 'uikit-lib';

export const LANGUAGES: LanguageDictionary = {
    EN: {
        id: 'EN',
        key: 'EN',
        inUse: true,
    },
    RU: {
        id: 'RU',
        key: 'RU',
        inUse: false,
    },
};
