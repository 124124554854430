import { TYPOGRAPHY__H2, TYPOGRAPHY__S4 } from '../../../../';

export const theme = {
    CWrap: {
        padding: '32px 0 120px',
        height: 'max-content',

        '& .marquee-container': {
            overflow: 'hidden',
        },
    },
    Item: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '8px',

        paddingLeft: '96px',
        height: '140px',

        '& svg': {
            position: 'absolute',
            top: '12px',
            left: '16px',
            color: 'var(--COLORS__PRIMARY_PALETTE____GREEN_TEXT)',
            strokeWidth: '1px',
            stroke: 'white',
        },
    },
    ItemTitle: {
        ...TYPOGRAPHY__H2,
    },
    ItemSubTitle: {
        ...TYPOGRAPHY__S4,
        fontWeight: '500',
    },
    ItemText: {},
};

export default theme;
