import React from 'react';
import { CheckBox, Text } from 'uikit-lib';
import lang from './lang';
import { VALIDATION_RULES } from 'src/constants';
import {
    Agreement,
    AgreementLabel,
    GoogleDescription,
} from './RegisterForm.styles';
import { Controller, Control, FieldValues, FieldErrors } from 'react-hook-form';

export type Props = {
    control: Control<FieldValues> | undefined;
    errors: FieldErrors;
    testIds: Record<string, string>;
};

export const Agreements: React.FC<Props> = (props): JSX.Element => {
    const { testIds, control, errors } = props;
    const {
        privacyPolicy: PolicyLabel,
        marketingInfo: MarketingInfoLabel,
        termsOfUse: TermsOfUseLabel,
    } = lang.checkboxLabels;

    return (
        <>
            <Agreement data-testid={testIds.Agreement}>
                <Controller
                    name={'privacyPolicyIsSelected'}
                    control={control}
                    rules={VALIDATION_RULES.privacyPolicyIsSelected}
                    render={({ field: { onChange, value } }) => {
                        return (
                            <CheckBox
                                type={'BASIC'}
                                isValid={!errors.privacyPolicyIsSelected}
                                value={value}
                                onChange={onChange}
                                tokens={{}}
                            />
                        );
                    }}
                />
                <AgreementLabel
                    className={'isPrivacyPolicyLabel'}
                    data-testid={testIds.AgreementLabel}>
                    <PolicyLabel />
                </AgreementLabel>
            </Agreement>
            <Agreement data-testid={testIds.Agreement}>
                <Controller
                    name={'touCheckBoxIsSelected'}
                    control={control}
                    rules={VALIDATION_RULES.touCheckBoxIsSelected}
                    render={({ field: { onChange, value } }) => {
                        return (
                            <CheckBox
                                type={'BASIC'}
                                isValid={!errors.touCheckBoxIsSelected}
                                value={value}
                                onChange={onChange}
                                tokens={{}}
                            />
                        );
                    }}
                />
                <AgreementLabel
                    className={'isTermsOfUseLabel'}
                    data-testid={testIds.AgreementLabel}>
                    <TermsOfUseLabel />
                </AgreementLabel>
            </Agreement>
            <Agreement data-testid={testIds.Agreement}>
                <Controller
                    name={'marketingIsSelected'}
                    control={control}
                    rules={VALIDATION_RULES.marketingIsSelected}
                    render={({ field: { onChange, value } }) => {
                        return (
                            <CheckBox
                                type={'BASIC'}
                                isValid={!errors.marketingIsSelected}
                                value={value}
                                onChange={onChange}
                                tokens={{}}
                            />
                        );
                    }}
                />
                <AgreementLabel
                    className={'isMarketingLabel'}
                    data-testid={testIds.AgreementLabel}>
                    <MarketingInfoLabel />
                </AgreementLabel>
            </Agreement>
            <GoogleDescription data-testid={testIds.GoogleDescription}>
                <Text type={'T3'} nodeName={'P'} tokens={{}}>
                    This site is protected by reCAPTCHA and the Google&nbsp;
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://policies.google.com/privacy'
                        data-at-selector=''>
                        Privacy Policy
                    </a>
                    &nbsp; and&nbsp;
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://policies.google.com/terms'
                        data-at-selector=''>
                        Terms of Service
                    </a>
                </Text>
            </GoogleDescription>
        </>
    );
};

export default Agreements;
