/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
import i18next, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { LANGUAGES } from 'src/constants';
import { getActiveLanguages } from 'uikit-lib/src/utils';

const supportedLngs = getActiveLanguages(LANGUAGES).map((lang) => lang.key);

use(initReactI18next)
    .use(HttpApi)
    .init({
        lng: 'en',
        interpolation: {
            escapeValue: false,
        },
        supportedLngs,
        returnNull: false,
        fallbackLng: 'en',
    });

export default i18next;
