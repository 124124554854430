import React from 'react';
import { CurIcon } from 'uikit-lib';
import { useStore } from '../../../';
import lang from './lang';
import { CWrap, Text } from './SystemMsgGen.styles';

export interface Props {
    seqKey: string;
}

export const SystemMsgGen: React.FC<Props> = (props): JSX.Element => {
    const { seqKey } = props;
    const {
        Modal: { sysType },
    } = useStore();

    const sysType2ClassName: Record<string, string> = {
        ERROR: 'isError',
        WARNING: 'isWarning',
        INFO: 'isInfo',
        SUCCESS: 'isSuccess',
        IDLE: 'isIdle',
    };

    return (
        <CWrap
            className={`${sysType2ClassName[sysType]}`}
            data-testid='SYSTEM_MSG_GEN__CWRAP'>
            <CurIcon sizeId={'GIANT'} iconId={sysType} />
            <Text>{lang[seqKey]}</Text>
        </CWrap>
    );
};

export default SystemMsgGen;
