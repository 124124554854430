/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectOptions, SelectOption } from 'uikit-lib';

export interface SelectOptPrepareResult {
    dictionary: Record<string, SelectOption>;
    options: SelectOptions;
}

/**
 *
 * @param {SourceDataItem[]} data
 * @param {string} labelKey
 * @returns {SelectOptions}
 */
export const prepareSelectOptions = <SourceItem extends Record<string, any>>(
    data: (SourceItem | number)[],
    labelKey?: string
): SelectOptPrepareResult => {
    const dictionary: Record<string, SelectOption> = {};
    const options: SelectOptions = [];

    data.map((dataItem: SourceItem | number) => {
        if (typeof dataItem !== 'number' && labelKey) {
            const option: SelectOption = {
                value: String(dataItem.id),
                label: String(dataItem[labelKey]),
            };
            options.push(option);
            dictionary[String(dataItem.id)] = { ...option };
        } else {
            const option: SelectOption = {
                value: String(dataItem),
                label: String(dataItem),
            };
            options.push(option);
            dictionary[String(dataItem)] = { ...option };
        }
    });

    return {
        dictionary,
        options,
    };
};
