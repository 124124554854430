import React from 'react';
import { HelmetBlock } from 'uikit-lib';
import { PrivacyPolicyItem } from './components';

export const PrivacyPolicy: React.FC = (): JSX.Element => {
    const hlaToken = 'APP__PRIVACY_POLICY_PAGE';

    return (
        <>
            <HelmetBlock
                lang={{
                    title: 'Политика конфиденциальности',
                    description: 'Privacy policy',
                }}
            />
            <PrivacyPolicyItem
                tokens={{
                    hlaToken,
                }}
            />
        </>
    );
};

export default PrivacyPolicy;
