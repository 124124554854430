import AppLayout from './AppLayout';
import NotFound from './NotFound';
import Header from './Header';
import Footer from './Footer';
import { Carousel, Modal, Toaster } from '../../coreTheme';

export interface ContainersTheme {
    AppLayout: typeof AppLayout;
    Carousel: typeof Carousel;
    Header: typeof Header;
    Footer: typeof Footer;
    Modal: typeof Modal;
    Toaster: typeof Toaster;
    NotFound: typeof NotFound;
}

export const containerThemes: ContainersTheme = {
    AppLayout,
    Carousel,
    Header,
    Footer,
    Modal,
    Toaster,
    NotFound,
};

export default containerThemes;
