import {
    BTN_COLOR_MODES,
    BTN_SIZE_MODES,
    getButtonProps,
    BtnColorModeName,
    BtnSizeModeName,
} from '../../../../../';

const fillFormBtnProps = getButtonProps(
    {
        color: BtnColorModeName.TRANSPARENT,
        size: BtnSizeModeName.DEFAULT_40,
        icon: 'ALONG',
        withShadow: false,
    },
    {
        color: BTN_COLOR_MODES,
        size: BTN_SIZE_MODES,
    }
);

export const theme = {
    CWrap: {
        ...fillFormBtnProps,
        fontWeight: 'var(--TYPOGRAPHY__CORE____FONT_WEIGHT__IS_MEDIUM)',
        height: 'max-content',
    },
};

export default theme;
