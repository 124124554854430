export const theme = {
    Title: {
        fontWeight: 'var(--TYPOGRAPHY__CORE____FONT_WEIGHT__IS_SEMI_BOLD)',
        fontSize: 'var(--TYPOGRAPHY__H3____FONT_SIZE)',
        lineHeight: 1,
        letterSpacing: '-1.5px',
        color: 'var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
        marginBottom: '50px',
    },
};

export default theme;
