import React from 'react';
import {
    Button,
    withBaseProps,
    BaseFCProps,
    ModalFooterNodes,
} from 'uikit-lib';
import { CWrap } from './Footer.styles';

import sequenses, { ModalControlDeclaration } from './sequenses';

export interface Props extends BaseFCProps<ModalFooterNodes> {
    seqKey: string;
}

export const Footer: React.FC<Props> = (props): JSX.Element => {
    const {
        tokens: { hlaToken = '' },
        seqKey,
        theme,
        testIds,
    } = props;

    const targetSeq = sequenses[seqKey];

    return (
        <CWrap theme={theme.CWrap} data-testid={testIds.CWrap}>
            {!!targetSeq &&
                targetSeq.map((btnObj: ModalControlDeclaration) => {
                    const { key, caption, handleOnClick } = btnObj;
                    return (
                        <Button
                            key={key}
                            type={'BASIC'}
                            caption={caption || ''}
                            handleOnClick={() => {
                                if (handleOnClick) {
                                    handleOnClick();
                                }
                            }}
                            tokens={{
                                hlaToken,
                            }}
                        />
                    );
                })}
        </CWrap>
    );
};

export default withBaseProps<ModalFooterNodes, Props>(Footer, {
    name: 'ModalFooter',
    code: 'MODAL_FOOTER',
    nodeNames: Object.keys(ModalFooterNodes),
});
