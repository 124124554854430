import styled from '@emotion/styled';
import { getCSSObj } from '../../../../utils';

export const CWrap = styled.div`
    display: flex;
    flex-direction: column;
    ${getCSSObj}
`;

export const Main = styled.div`
    display: flex;
    justify-content: space-between;

    ${getCSSObj}
`;

export const VisibleZone = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;

    ${getCSSObj}
`;
