import styled from '@emotion/styled';
import { getCSSObj } from '../../utils';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const ToggleBtn = styled.button`
    position: relative;
    ${getCSSObj}
`;

export const ToggleBtnIcon = styled.div`
    ${getCSSObj}
`;

export const ToggleCaption = styled.span`
    ${getCSSObj}
`;

export const ToggleBtnArrow = styled.div`
    /*
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    */
    width: 32px;
    height: 100%;

    ${getCSSObj}
`;

export const BtnsWrap = styled.div`
    ${getCSSObj}
`;

export const ItemBtn = styled.button`
    ${getCSSObj}
`;
