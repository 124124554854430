import React from 'react';
import { useStore } from 'src/containers';
import { BaseFCProps, FeedbackNodes, withBaseProps, MiniCard } from 'uikit-lib';
import { CWrap, FeedbackText } from './Feedback.styles';

export type Props = BaseFCProps<FeedbackNodes>;

export const Feedback: React.FC<Props> = (props): JSX.Element => {
    const { theme, testIds } = props;
    const {
        Modal: { dataId },
        FetchedData: { feedbacks },
    } = useStore();

    const { firstName, lastName, position, image, feedback } =
        feedbacks[dataId];

    return (
        <CWrap theme={theme.CWrap} data-testid={testIds.CWrap}>
            <MiniCard
                data={[
                    {
                        tag: 'Image',
                        text: '',
                        imgUrl: (image?.fileLink as string) || '',
                    },
                    {
                        tag: 'Title',
                        text: `${firstName} ${lastName}`,
                    },
                    {
                        tag: 'SubTitle',
                        text: position,
                    },
                ]}
                type={'INTERN_INFO'}
            />
            <FeedbackText theme={theme.CWrap} data-testid={testIds.CWrap}>
                {feedback}
            </FeedbackText>
        </CWrap>
    );
};

export default withBaseProps(Feedback, {
    name: 'Feedback',
    code: 'FEEDBACK',
    nodeNames: Object.keys(FeedbackNodes),
});
