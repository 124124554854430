import React from 'react';
import { ICON_SIZES, UnitSizeId, FCTokens } from '../../';

export interface Props {
    sizeId: keyof typeof UnitSizeId;
    iconId: string;
    map: Record<string, React.FC<React.SVGProps<SVGSVGElement>>>;
    handleOnClick?: () => void;
    groupToken?: string;
    tokens: FCTokens;
}

export const Icon: React.FC<Props> = (props): JSX.Element => {
    const { sizeId, iconId, map } = props;

    const TargetIcon = map[iconId];
    const { width, height } = ICON_SIZES[sizeId];

    return <TargetIcon width={width} height={height} />;
};

export default Icon;
