import React from 'react';
import { MaskedInputBox, InputBox } from 'uikit-lib';
import lang from './lang';
import { DataInputHint, DataInputWrap } from './RegisterForm.styles';
import { CSSObject } from '@emotion/react';
import { useStore } from 'src/containers';
import { Controller, Control, FieldValues, FieldErrors } from 'react-hook-form';
import { VALIDATION_RULES } from 'src/constants';

export type Props = {
    testIds: Record<string, string>;
    theme: Record<string, CSSObject>;
    control: Control<FieldValues> | undefined;
    errors: FieldErrors;
};

export const Contacts: React.FC<Props> = (props): JSX.Element => {
    const { theme, testIds, control, errors } = props;
    const { placeholders } = lang;
    const {
        FetchedData: {
            registerFormConfig: {
                emailIsRequired,
                phoneIsRequired,
                telegramNickNameIsRequired,
            },
        },
    } = useStore();

    return (
        <>
            {emailIsRequired && (
                <DataInputWrap
                    theme={theme.DataInputWrap}
                    data-testid={testIds.DataInputWrap}>
                    <Controller
                        name={'email'}
                        control={control}
                        rules={VALIDATION_RULES.email}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <InputBox
                                    type={'BASIC'}
                                    tokens={{}}
                                    value={value}
                                    isValid={!errors.email}
                                    placeholder={placeholders.emailInput}
                                    onChange={onChange}
                                />
                            );
                        }}
                    />
                    {!!errors?.email?.message && (
                        <DataInputHint
                            theme={theme.DataInputHint}
                            data-testid={testIds.DataInputHint}>
                            {errors.email.message as string}
                        </DataInputHint>
                    )}
                </DataInputWrap>
            )}
            {phoneIsRequired && (
                <DataInputWrap
                    theme={theme.DataInputWrap}
                    data-testid={testIds.DataInputWrap}>
                    <Controller
                        name={'phone'}
                        control={control}
                        rules={VALIDATION_RULES.phone}
                        render={({
                            field: { onChange, value },
                            // formState,
                            formState: { isDirty, dirtyFields },
                        }) => {
                            // console.log('formState = ', formState);
                            // console.log('isDirty = ', isDirty);
                            // console.log('dirtyFields = ', dirtyFields);

                            return (
                                <>
                                    <MaskedInputBox
                                        type={'BASIC'}
                                        tokens={{}}
                                        value={value}
                                        isValid={!errors.phone}
                                        placeholder={placeholders.phoneInput}
                                        onChange={(curValue: string) => {
                                            if (
                                                curValue === '(+7) XXX XXX XXXX'
                                            )
                                                return;
                                            curValue.replaceAll(' ', '');
                                            curValue.replaceAll('(+7)', '');
                                            onChange(curValue);
                                        }}
                                        maskParams={{
                                            mask: '(+7) 999 999 9999',
                                            maskChar: ' ',
                                        }}
                                    />
                                    {isDirty &&
                                        Object.prototype.hasOwnProperty.call(
                                            dirtyFields,
                                            'phone'
                                        ) &&
                                        !!errors?.phone?.message && (
                                            <DataInputHint
                                                theme={theme.DataInputHint}
                                                data-testid={
                                                    testIds.DataInputHint
                                                }>
                                                {errors.phone.message as string}
                                            </DataInputHint>
                                        )}
                                </>
                            );
                        }}
                    />
                </DataInputWrap>
            )}
            {telegramNickNameIsRequired && (
                <DataInputWrap
                    theme={theme.DataInputWrap}
                    data-testid={testIds.DataInputWrap}>
                    <Controller
                        name={'telegramNickName'}
                        control={control}
                        rules={VALIDATION_RULES.telegramNickName}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <InputBox
                                    type={'BASIC'}
                                    tokens={{}}
                                    value={value}
                                    onChange={onChange}
                                    placeholder={placeholders.telegramInput}
                                    isValid={!errors.telegramNickName}
                                />
                            );
                        }}
                    />
                    {!!errors?.telegramNickName?.message && (
                        <DataInputHint
                            theme={theme.DataInputHint}
                            data-testid={testIds.DataInputHint}>
                            {errors.telegramNickName.message as string}
                        </DataInputHint>
                    )}
                </DataInputWrap>
            )}
        </>
    );
};

export default Contacts;
