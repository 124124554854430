import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const Title = styled.div`
    ${getCSSObj}
`;

export const SubTitle = styled.div`
    ${getCSSObj}
`;

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const Chapter = styled.div`
    ${getCSSObj}
`;

export const Table = styled.table`
    ${getCSSObj}
`;

export const TableRow = styled.tr`
    ${getCSSObj}
`;
export const TableData = styled.td`
    ${getCSSObj}
`;
export const TableHeadData = styled.th`
    ${getCSSObj}
`;
export const TableHead = styled.thead`
    ${getCSSObj}
`;
export const TableBody = styled.tbody`
    ${getCSSObj}
`;

export const NumberList = styled.ol`
    ${getCSSObj}
`;
export const PointList = styled.ul`
    ${getCSSObj}
`;

export const ListItem = styled.li`
    ${getCSSObj}
`;

export const HorizontalLine = styled.hr`
    ${getCSSObj}
`;

export const Anchor = styled.a`
    ${getCSSObj}
`;

export const SupText = styled.sup`
    ${getCSSObj}
`;
