import { LAYOUTING__CONTENT_AREA, TYPOGRAPHY__T1 } from '../../';

export const theme = {
    SplashBg: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 21,
        display: 'none',

        width: '100%',
        height: '100%',
        padding: '32px',

        '@media screen and (max-width: 640px)': {
            padding: '0',
        },

        background: 'rgba(0, 0, 0, 0.9)',

        '&.isVisible': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    ModalWrap: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '15px',
        ...LAYOUTING__CONTENT_AREA,
        maxHeight: 'calc(100vh - 100px)',
        borderRadius: '16px',
        position: 'relative',

        background: 'var(--COLORS__GRAYSCALE____8)',

        ...TYPOGRAPHY__T1,
        color: 'var(--COLORS__GRAYSCALE____3)',
        padding: '32px 24px',
        overflow: 'hidden',

        '@media (min-width: 641px) and (max-width: 1440px)': {
            padding: '32px 24px',
        },

        '@media screen and (max-width: 640px)': {
            maxHeight: '100vh',
            borderRadius: 0,
            width: '100%',
            maxWidth: '100% !important',
            padding: '0 16px',
        },
    },
};

export default theme;
