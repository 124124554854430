export const theme = {
    CWrap: {
        width: '30px',
        height: '24px',
        backgroundImage: 'url("./assets/images/directions/row_to_left.png")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    },
};

export default theme;
