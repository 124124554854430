import React from 'react';
import { getDataTestId } from '../../../../utils';
import { FCTokens } from '../../../..';
import { CWrap } from './Dot.styles';
import { CSSObject } from '@emotion/react';

export interface Props {
    id: number;
    activePage: number;
    handleOnClick: () => void;
    tokens: FCTokens;
    theme: CSSObject;
}

export const Dot: React.FC<Props> = (props): JSX.Element => {
    const {
        id,
        activePage,
        handleOnClick,
        tokens: { groupToken = '', hlaToken = '' },
        theme,
    } = props;

    const testId = getDataTestId('DOT__CWRAP', hlaToken, '');

    return (
        <CWrap
            onClick={handleOnClick}
            className={activePage === id ? 'isActive' : ''}
            theme={theme}
            data-at-selector={groupToken}
            data-testid={testId}
        />
    );
};

export default Dot;
