export const theme = {
    CWrap: {
        position: 'relative',
        zIndex: 3,
        '&::before': {
            content: '""',
            width: '458px',
            height: '464px',
            backgroundImage:
                'url("./assets/images/internship-selectionsteps/cat.webp")',
            backgroundSize: 'cover',
            position: 'absolute',
            zIndex: 0,
            left: '0px',
            top: '0px',
            '@media screen and (max-width: 900px)': {
                display: 'none',
            },
        },
    },
};
export default theme;
