import { ValidationRule } from 'src/types';

export const required: ValidationRule<boolean> = {
    value: true,
    message: 'Это поле обязательное',
};

export const minLength1 = {
    value: 1,
    message: 'Минимальная длина 1 символ',
};

export const maxLength50 = {
    value: 50,
    message: 'Максимальная длина 50 символов',
};

export const maxLength255 = {
    value: 255,
    message: 'Максимальная длина 255 символов',
};

export const maxLength2000 = {
    value: 2000,
    message: 'Максимальная длина 2000 символов',
};

export const length10 = {
    value: 10,
    message: 'Длина должна быть 10 символов',
};

export const onlyDigits = {
    value: /\D+/,
    message: 'В значении могут быть использованы только цифры',
};

export type ValidationRuleType =
    | ValidationRule<RegExp>
    | ValidationRule<string>
    | ValidationRule<number>
    | ValidationRule<boolean>
    | boolean;

export const VALIDATION_RULES: Record<
    string,
    Record<string, ValidationRuleType>
> = {
    directionsId: {
        required,
    },
    firstName: {
        required,
        minLength: { ...minLength1 },
        maxLength: { ...maxLength50 },
    },
    lastName: {
        required,
        minLength: { ...minLength1 },
        maxLength: { ...maxLength50 },
    },
    email: {
        required,
        minLength: { ...minLength1 },
        maxLength: { ...maxLength255 },
        pattern: {
            value: /\S+@\S+\.\S+/,
            message: 'Введите свой email',
        },
    },
    phone: {
        required,
        minLength: length10,
        pattern: onlyDigits,
    },
    telegramNickName: {
        required,
        minLength: minLength1,
        maxLength: maxLength255,
    },
    city: {
        required,
        minLength: minLength1,
        maxLength: maxLength50,
    },
    university: {
        required,
        minLength: minLength1,
        maxLength: maxLength255,
    },
    faculty: {
        required,
        minLength: minLength1,
        maxLength: maxLength255,
    },
    startEducation: {
        required,
    },
    finishEducation: {
        required,
    },
    descriptionYourSelf: {
        required: false,
        minLength: minLength1,
        maxLength: maxLength2000,
    },
    whatYouWantToKnowAboutInternship: {
        required: false,
        minLength: minLength1,
        maxLength: maxLength2000,
    },
    privacyPolicyIsSelected: {
        required,
    },
    touCheckBoxIsSelected: {
        required,
    },
    marketingIsSelected: {
        required: false,
    },
};
