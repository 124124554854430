import styled from '@emotion/styled';
import { getCSSObj } from '../../../../utils';

export const CWrap = styled.div`
    display: flex;
    flex-wrap: wrap;

    justify-content: center;
    align-items: center;

    gap: 12px;

    ${getCSSObj}
`;
