import React from 'react';
import {
    BaseFCProps,
    DirectionDescriptionNodes,
    withBaseProps,
} from 'uikit-lib';
import { CWrap, Description, Link } from './DirectionDescription.styles';
import lang from './lang';

export interface Props extends BaseFCProps<DirectionDescriptionNodes> {
    description: string;
    link: string;
}

export const DirectionDescription: React.FC<Props> = (props): JSX.Element => {
    const { description, link, theme, testIds } = props;

    return (
        <CWrap theme={theme.CWrap} data-testid={testIds.CWrap}>
            <Description
                theme={theme.Description}
                data-testid={testIds.Description}>
                {description}
            </Description>
            {link && (
                <Link theme={theme.Link} data-testid={testIds.Link}>
                    <a href={link} target='__blank' rel='noreferrer'>
                        {lang.linkCaption}
                    </a>
                </Link>
            )}
        </CWrap>
    );
};

export default withBaseProps(DirectionDescription, {
    name: 'DirectionDescription',
    code: 'DIRECTION_DESCRIPTION',
    nodeNames: Object.keys(DirectionDescriptionNodes),
});
