import { TYPOGRAPHY__S4 } from '../../../../coreTheme';

export const theme = {
    CWrap: {
        '&:not(&:first-of-type)': {
            borderTop: '2px solid var(--COLORS__GRAYSCALE____7)',
        },
    },
    ContentWrap: {
        margin: '0 0 20px 0',
    },
    Header: {
        ...TYPOGRAPHY__S4,
        fontWeight: 'var(--TYPOGRAPHY__CORE____FONT_WEIGHT__IS_SEMI_BOLD)',
        padding: '25px 0 25px 40px',
        position: 'relative',

        '&:hover': {
            cursor: 'pointer',
        },

        '&.isVisible': {
            '&:before': {
                backgroundImage: 'url("/assets/images/home/minus.svg")',
            },
        },

        '&:before': {
            content: '" "',
            position: 'absolute',
            left: '0',
            top: '50%',
            transform: 'translateY(-50%)',
            height: '32px',
            width: '32px',
            borderRadius: '8px',
            background: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: 'url("/assets/images/home/plus.svg")',
            backgroundSize: '80%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        },
    },
};

export default theme;
