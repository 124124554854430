import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@emotion/react';
import i18n from './i18n';
import theme from 'src/theme';
import { AppSettings } from 'uikit-lib/src/types/app';

import { ApolloClient, LocalStoreProvider } from '..';

export interface Props {
    children?: React.ReactNode;
    appSettings: AppSettings;
}

export const CoreProvider: React.FC<Props> = (props): JSX.Element => {
    const { children, appSettings } = props;

    return (
        <ApolloClient appSettings={appSettings}>
            <LocalStoreProvider>
                <I18nextProvider i18n={i18n}>
                    <BrowserRouter>
                        <ThemeProvider theme={theme}>{children}</ThemeProvider>
                    </BrowserRouter>
                </I18nextProvider>
            </LocalStoreProvider>
        </ApolloClient>
    );
};

export default CoreProvider;
