import Direction from './Direction';
import Feedback from './Feedback';
import Feedbacks from './Feedbacks';
import DirectionDescription from './DirectionDescription';
import Directions from './Directions';
import DirectionTeams from './DirectionTeams';
import Policy from './Policy';
import ProjectList from './ProjectList';
import SectionHeader from './SectionHeader';
import InternshipRoadmap from './InternshipRoadmap';
import RegisterForm from './RegisterForm';
import RoadMap from './RoadMap';

export const containerThemes = {
    Direction,
    Feedback,
    Feedbacks,
    Directions,
    DirectionDescription,
    DirectionTeams,
    Policy,
    ProjectList,
    SectionHeader,
    InternshipRoadmap,
    RegisterForm,
    RoadMap,
};

export default containerThemes;
