import { IconSizeDictionary } from '..';

export const ICON_SIZES: IconSizeDictionary = {
    ATOMIC: {
        width: '8px',
        height: '8px',
    },
    MICRO: {
        width: '10px',
        height: '10px',
    },
    TINY: {
        width: '12px',
        height: '12px',
    },
    SMALL: {
        width: '16px',
        height: '16px',
    },
    REDUCED: {
        width: '18px',
        height: '18px',
    },
    REGULAR: {
        width: '24px',
        height: '24px',
    },
    INCREASED: {
        width: '38px',
        height: '38px',
    },
    MEDIUM: {
        width: '48px',
        height: '48px',
    },
    LARGE: {
        width: '64px',
        height: '64px',
    },
    HUGE: {
        width: '96px',
        height: '96px',
    },
    GIANT: {
        width: '128px',
        height: '128px',
    },
    MAXI: {
        width: '256px',
        height: '256px',
    },
};
