import React from 'react';
import {
    withBaseProps,
    HeaderConfig,
    ModalHeaderNodes,
    BaseFCProps,
    CurIcon,
    Text,
} from 'uikit-lib';
import { CWrap, CloseBtn } from './Header.styles';
import { useStore } from '../../../LocalDataStore';

export interface Props extends BaseFCProps<ModalHeaderNodes> {
    config: HeaderConfig;
    handleOnClose: () => void;
}

export const Header: React.FC<Props> = (props): JSX.Element => {
    const {
        config,
        handleOnClose,
        tokens: { hlaToken = '' },
        theme,
        testIds,
    } = props;

    const {
        Modal: { headerLangs },
    } = useStore();

    const { nodeName, type } = config.types;
    const { title, description } = config.langs;

    return (
        <CWrap theme={theme.CWrap} data-testid={testIds.CWrap}>
            {title ? (
                <Text
                    nodeName={nodeName}
                    type={type}
                    tokens={{
                        hlaToken,
                        groupToken: 'MODAL_HEADER_TITLE',
                    }}>
                    {title}
                </Text>
            ) : (
                headerLangs?.title && (
                    <Text
                        nodeName={nodeName}
                        type={type}
                        tokens={{
                            hlaToken,
                            groupToken: 'MODAL_HEADER_TITLE',
                        }}>
                        {headerLangs?.title ? headerLangs.title : title || ''}
                    </Text>
                )
            )}
            {!!description && (
                <Text
                    nodeName={'P'}
                    type={'T2'}
                    tokens={{
                        hlaToken,
                        groupToken: 'MODAL_HEADER_DESCRIPTION',
                    }}>
                    {description}
                </Text>
            )}

            <CloseBtn
                theme={theme.CloseBtn}
                data-testid={testIds.CloseBtn}
                onClick={handleOnClose}>
                <CurIcon sizeId={'REGULAR'} iconId={'CROSS'} />
            </CloseBtn>
        </CWrap>
    );
};

export default withBaseProps<ModalHeaderNodes, Props>(Header, {
    name: 'ModalHeader',
    code: 'MODAL_HEADER',
    nodeNames: Object.keys(ModalHeaderNodes),
});
