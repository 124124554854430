import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home, TermsOfUse, PrivacyPolicy, NotFound } from '../pages';

const AppRouter: React.FC = (): JSX.Element => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/terms-of-use' element={<TermsOfUse />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
};

export default AppRouter;
