import { TYPOGRAPHY__S4, TYPOGRAPHY__T1 } from '../../../../';

export const theme = {
    CWrap: {
        position: 'relative',
        width: '100%',
        height: '488px',
        boxSizing: 'border-box',
        zIndex: 'auto',
        boxShadow: 'var(--COLORS__SHADOWS____CARD_SHADOW)',
        borderRadius: '16px',
        overflow: 'hidden',
        padding: '32px',
        background: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',

        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '12px',
            backgroundColor: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
        },
    },
    Image: {
        position: 'absolute',
        width: '48px',
        height: '48px',
        borderRadius: '50%',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    Title: {
        ...TYPOGRAPHY__S4,
        fontWeight: 600,
        padding: '0 0 0 58px',
    },
    SubTitle: {
        ...TYPOGRAPHY__T1,
        padding: '0 0 0 58px',
        color: 'var(--COLORS__GRAYSCALE____4)',
        minHeight: '36px',
    },
    ShortDescription: {
        ...TYPOGRAPHY__S4,
        paddingTop: '12px',
    },
    Btn: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        strokeWidth: '1px !important',
        position: 'absolute',
        bottom: '32px',
        left: '32px',
        ...TYPOGRAPHY__T1,
        lineHeight: '24px',
        fontWeight: 600,
        cursor: 'pointer',

        '& svg': {
            color: 'var(--COLORS__PRIMARY_PALETTE____GREEN_TEXT)',
        },
    },
};

export default theme;
