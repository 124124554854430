import React from 'react';
import { PubBurgerBtnNodes, withBaseProps, BaseFCProps } from '../../';
import { CWrap, LinesWrap, Line } from './BurgerBtn.styles';

export interface Props extends BaseFCProps<PubBurgerBtnNodes> {
    isVisible: boolean;
    handleOnClick?: () => void;
    isOpen: boolean;
}

export const BurgerBtn: React.FC<Props> = (props): JSX.Element => {
    const { isVisible, handleOnClick, theme, testIds, isOpen } = props;

    return (
        <CWrap
            title='Menu'
            type='button'
            className={isVisible ? 'isVisible' : ''}
            onClick={() => {
                if (handleOnClick) handleOnClick();
            }}
            theme={theme.CWrap}
            data-testid={testIds.CWrap}>
            <LinesWrap theme={theme.LinesWrap} data-testid={testIds.LinesWrap}>
                <Line
                    className={`isTopLine ${isOpen ? 'isActive' : ''}`}
                    theme={theme.Line}
                    data-testid={`${testIds.Line}__IS_TOP`}
                />
                <Line
                    className={`isMiddleLine ${isOpen ? 'isActive' : ''}`}
                    theme={theme.Line}
                    data-testid={`${testIds.Line}__IS_MIDDLE`}
                />
                <Line
                    className={`isBottomLine ${isOpen ? 'isActive' : ''}`}
                    theme={theme.Line}
                    data-testid={`${testIds.Line}__IS_BOTTOM`}
                />
            </LinesWrap>
        </CWrap>
    );
};

export default withBaseProps(BurgerBtn, {
    name: 'BurgerBtn',
    code: 'BURGER_BTN',
    nodeNames: Object.keys(BurgerBtn),
});
