import { BtnColorScheme, BtnSizeScheme } from '../../../';

export const BTN_COLOR_MODES: BtnColorScheme = {
    GREEN: {
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        bg: 'var(--COLORS__PRIMARY_PALETTE____GREEN_TEXT)',
        border: 'none',
        boxShadow: 'var(--COLORS__SHADOWS____GREEN_SHADOW)',
        colorOnHover: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        bgOnHover: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
        borderOnHover: 'none',
        boxShadowOnHover: 'var(--COLORS__SHADOWS____GREEN_SHADOW_HOVER)',
        colorDisabled: 'var(--COLORS__GRAYSCALE____5)',
        bgDisabled: 'var(--COLORS__GRAYSCALE____7)',
        borderDisabled: 'none',
    },
    BLACK: {
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        bg: 'var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
        border: 'none',
        boxShadow: 'var(--COLORS__SHADOWS____GREY_HOVER)',
        colorOnHover: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
        bgOnHover: 'var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
        borderOnHover: 'none',
        boxShadowOnHover: 'var(--COLORS__SHADOWS____GREY_HOVER)',
        colorDisabled: 'var(--COLORS__GRAYSCALE____5)',
        bgDisabled: 'var(--COLORS__GRAYSCALE____7)',
        borderDisabled: 'none',
    },
    WHITE: {
        color: 'var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
        bg: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        border: 'none',
        boxShadow: 'var(--COLORS__SHADOWS____GREY_HOVER)',
        colorOnHover: 'var(--COLORS__PRIMARY_PALETTE____GREEN_TEXT)',
        bgOnHover: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        borderOnHover: 'none',
        boxShadowOnHover: 'var(--COLORS__SHADOWS____GREY_HOVER)',
        colorDisabled: 'var(--COLORS__GRAYSCALE____5)',
        bgDisabled: 'rgba(255, 255, 255, 0.32)',
        borderDisabled: 'none',
    },
    BLUE: {
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        bg: 'var(--COLORS__SECONDARY_PALETTE____BLUE)',
        border: 'none',
        boxShadow: 'var(--COLORS__SHADOWS____700)',
        colorOnHover: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        bgOnHover:
            'linear-gradient(0deg, rgba(51, 85, 255, 0.88), rgba(51, 85, 255, 0.88)), #FFFFFF',
        borderOnHover: 'none',
        boxShadowOnHover: 'var(--COLORS__SHADOWS____700)',
        colorDisabled: 'var(--COLORS__GRAYSCALE____5)',
        bgDisabled: 'var(--COLORS__GRAYSCALE____7)',
        borderDisabled: 'none',
    },
    RED: {
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        bg: 'var(--COLORS__SECONDARY_PALETTE____RED)',
        border: 'none',
        boxShadow: 'var(--COLORS__SHADOWS____GREY_HOVER)',
        colorOnHover: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        bgOnHover: 'rgba(255, 51, 85, 0.8)',
        borderOnHover: 'none',
        boxShadowOnHover: 'var(--COLORS__SHADOWS____GREY_HOVER)',
        colorDisabled: 'var(--COLORS__GRAYSCALE____5)',
        bgDisabled: 'var(--COLORS__GRAYSCALE____7)',
        borderDisabled: 'none',
    },
    GHOST_BLACK: {
        color: 'var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
        bg: 'transparent',
        border: 'var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
        boxShadow: 'none',
        colorOnHover: 'var(--COLORS__GRAYSCALE____4)',
        bgOnHover: 'transparent',
        borderOnHover: 'var(--COLORS__GRAYSCALE____4)',
        boxShadowOnHover: 'none',
        colorDisabled: 'var(--COLORS__GRAYSCALE____5)',
        bgDisabled: 'var(--COLORS__GRAYSCALE____7)',
        borderDisabled: 'var(--COLORS__GRAYSCALE____7)',
    },
    GHOST_GREY: {
        color: 'var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
        bg: 'transparent',
        border: 'var(--COLORS__GRAYSCALE____5)',
        boxShadow: 'none',
        colorOnHover: 'var(--COLORS__GRAYSCALE____4)',
        bgOnHover: 'transparent',
        borderOnHover: 'var(--COLORS__GRAYSCALE____6)',
        boxShadowOnHover: 'none',
        colorDisabled: 'var(--COLORS__GRAYSCALE____5)',
        bgDisabled: 'var(--COLORS__GRAYSCALE____7)',
        borderDisabled: 'var(--COLORS__GRAYSCALE____7)',
    },
    GHOST_GREEN: {
        color: 'var(--COLORS__PRIMARY_PALETTE____GREEN_TEXT)',
        bg: 'transparent',
        border: 'var(--COLORS__PRIMARY_PALETTE____GREEN_TEXT)',
        boxShadow: 'none',
        colorOnHover: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
        bgOnHover: 'transparent',
        borderOnHover: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
        boxShadowOnHover: 'none',
        colorDisabled: 'var(--COLORS__GRAYSCALE____5)',
        bgDisabled: 'var(--COLORS__GRAYSCALE____7)',
        borderDisabled: 'var(--COLORS__GRAYSCALE____7)',
    },
    GRADIENT_GREEN: {
        color: 'var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
        border: 'none',
        bg: 'linear-gradient(28deg, #23D1AE 0%, #7EFF33 100%)',
        bgDisabled: 'transparent',
        borderOnHover: 'transparent',
        bgOnHover: 'linear-gradient(28deg, #23D1AE 0%, #7EFF33 100%)',
        boxShadow: '0px 5px 15px 0px rgba(61, 232, 202, 0.32)',
        colorDisabled: 'none',
        boxShadowOnHover: '0px 5px 15px 0px rgba(61, 232, 202, 0.72)',
        colorOnHover: 'var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
        borderDisabled: 'transparent',
    },
    TRANSPARENT: {
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        border: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        bg: 'transparent',
        bgDisabled: 'transparent',
        borderOnHover: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        bgOnHover: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        boxShadow: 'transparent',
        colorDisabled: 'none',
        boxShadowOnHover: 'transparent',
        colorOnHover: 'var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
        borderDisabled: '2px solid var(--COLORS__PRIMARY_PALETTE____WHITE)',
    },
};

export const BTN_SIZE_MODES: BtnSizeScheme = {
    DEFAULT_40: {
        padding: '10px 24px',
        fontSize: '16px',
        lineHeight: '20px',
        borderWidth: '2px',
        gap4Before: '9px',
        gap4After: '5px',
    },
    DEFAULT_48: {
        padding: '14px 24px',
        fontSize: '16px',
        lineHeight: '20px',
        borderWidth: '2px',
        gap4Before: '6px',
        gap4After: '4px',
    },
    DEFAULT_64: {
        padding: '20px 24px',
        fontSize: '21px',
        lineHeight: '24px',
        borderWidth: '2px',
        gap4Before: '9px',
        gap4After: '5px',
    },
};
