import styled from '@emotion/styled';
import { getCSSObj } from '../../utils';

export const CWrap = styled.div`
    ${getCSSObj}

    background: transparent;
    border-radius: 8px;

    &.isInvalid {
        & [class$='-control'] {
            border-color: var(--COLORS__SECONDARY_PALETTE____RED);
        }
    }
`;
