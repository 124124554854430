import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const Header = styled.div`
    ${getCSSObj}
`;

export const Title = styled.h2`
    ${getCSSObj}
`;

export const ShortDescription = styled.p`
    ${getCSSObj}
`;

export const EmployeePolicy = styled.p`
    ${getCSSObj}
`;
