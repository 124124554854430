import React from 'react';
import { Link } from 'react-router-dom';
import {
    HelmetBlock,
    getDataTestId,
    getFCTheme,
    NotFound as NothingFound,
    CurIcon,
} from 'uikit-lib';
import { CWrap } from './NotFound.styles';
import lang from './lang';

export const NotFound: React.FC = (): JSX.Element => {
    const hlaToken = 'APP__NOT_FOUND_PAGE';
    const theme = getFCTheme('NotFound');

    const curFCId = 'NOT_FOUND';
    const testIds = {
        CWrap: getDataTestId(`${curFCId}__CWRAP`, hlaToken, ''),
        Title: getDataTestId(`${curFCId}__TITLE`, hlaToken, ''),
        ShortDescription: getDataTestId(
            `${curFCId}__SHORT_DESCRIPTION`,
            hlaToken,
            ''
        ),
    };

    return (
        <CWrap theme={theme.CWrap} data-testid={testIds.CWrap}>
            <HelmetBlock
                lang={{
                    title: 'Not found',
                    description: 'Page not found',
                }}
            />
            <NothingFound
                tokens={{
                    hlaToken: 'MAIN_APP',
                    groupToken: '404_PAGE',
                }}
                title={lang.title}
                subtitle={
                    <p>
                        Чтобы попасть на стажировку нажми
                        <Link to='/'>
                            сюда
                            <CurIcon
                                sizeId={'REDUCED'}
                                iconId={'MOVE_TO_ARROW'}
                            />
                        </Link>
                    </p>
                }
                images={{
                    bearTop: './assets/images/not-found/bear-top.webp',
                    bearBottom: './assets/images/not-found/bear-bottom.webp',
                    contentImage: './assets/images/not-found/404.webp',
                }}
                background={'#dad6e9'}
            />
        </CWrap>
    );
};

export default NotFound;
