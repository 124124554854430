import React from 'react';
import {
    CurIcon,
    NavBtn,
    withBaseProps,
    BaseFCProps,
    PubSocialLinksNodes,
} from '../../';
import { CWrap, BtnItem } from './SocialLinks.styles';

export interface Props extends BaseFCProps<PubSocialLinksNodes> {
    items: NavBtn[];
}

const SocialLinks: React.FC<Props> = (props): JSX.Element => {
    const {
        items,
        tokens: { groupToken },
        theme,
        testIds,
    } = props;

    return (
        <CWrap
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}>
            {items.map((item) => {
                const { key, iconParams, isDisabled } = item;
                return (
                    !!iconParams &&
                    !isDisabled && (
                        <BtnItem
                            key={key}
                            theme={theme.BtnItem}
                            data-at-selector={groupToken}
                            data-testid={testIds.CWrap}
                            href={item.routePath}
                            target='_blank'>
                            <CurIcon
                                iconId={iconParams.iconId}
                                sizeId={iconParams?.sizeId}
                            />
                        </BtnItem>
                    )
                );
            })}
        </CWrap>
    );
};

export default withBaseProps(SocialLinks, {
    name: 'SocialLinks',
    code: 'SOCIAL_LINKS',
    nodeNames: Object.keys(SocialLinks),
});
