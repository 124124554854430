import { css } from '@emotion/react';

export const LayoutingVars = css`
    :root {
        --LAYOUTING__CONTENT_AREA__IS_DESKTOP____MAX_WIDTH__IS_FROM_1440: 1200px;

        --LAYOUTING__CONTENT_AREA__IS_MOBILE__PADDING: 0 15px;
        --LAYOUTING__CONTENT_AREA__IS_TABLET__PADDING: 0 32px;

        --LAYOUTING__CONTENT_AREA__NOT_MOBILE____GRID_CELL_PADDING: 12px;
        --LAYOUTING__CONTENT_AREA__IS_MOBILE____GRID_CELL_PADDING: 8px;
    }
`;

export default LayoutingVars;
