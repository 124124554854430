import { TYPOGRAPHY__S4 } from 'uikit-lib';

export const theme = {
    CWrap: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        padding: '13px',
        borderRadius: '8px',
        background: 'rgba(255, 255, 255, 0.25)',
        backdropFilter: 'blur(52px)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '25px',
        justifyContent: 'space-between',

        '@media screen and (max-width: 1024px)': {
            position: 'static',
            width: '385px',
            marginTop: '215px',
        },
    },
    Title: {
        ...TYPOGRAPHY__S4,
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        fontWeight: 'var(--TYPOGRAPHY__CORE____FONT_WEIGHT__IS_MEDIUM)',
        maxWidth: '230px',
        margin: '0 0 15px 0',
        padding: '0',
    },
    Image: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '98px',
        height: '98px',
        borderRadius: '10px',
    },
    Column: {},
};

export default theme;
