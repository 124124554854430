export const theme = {
    CWrap: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 20,
        display: 'none',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        width: '64px',
        height: '64px',
        border: 'none',
        backgroundColor: 'var(--COLORS__SECONDARY_PALETTE____GREEN_NEON)',

        '&.isVisible': {
            display: 'flex',
        },
    },
    LinesWrap: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '3px',
        width: '18px',
        height: '18px',
    },
    Line: {
        width: '100%',
        height: '3px',
        opacity: 1,
        backgroundColor: '#fff',
        transition: 'all 0.3s',

        '&.isTopLine': {
            '&.isActive': {
                transform: 'rotate(45deg) translate(4px, 4px)',
            },
        },

        '&.isMiddleLine': {
            '&.isActive': {
                width: '0%',
                opacity: 0,
            },
        },

        '&.isBottomLine': {
            '&.isActive': {
                transform: 'rotate(-45deg) translate(4px, -4px)',
            },
        },
    },
};

export default theme;
