/**
 * This is Promise based methods for Window.localStorage API
 * Abbreviation 'LS' means Local Storage in function namings here
 */

export interface LocalStorageItem {
    name: string;
    value: unknown;
}

/**
 * gets data string SYNCRONIOUSLY from LS
 * @param {string} itemName
 * @returns {string | null}
 */
export const getLSItemSync = (itemName: string): string | null => {
    const result = localStorage.getItem(itemName);
    return result;
};

/**
 * gets data string ASYNCRONIOUSLY from LS
 * @param {string} itemName
 * @returns {Promise<string>}
 */
export const getLSItem = async (itemName: string): Promise<string | null> => {
    return getLSItemSync(itemName);
};

/**
 * sets data item SYNCRONIOUSLY to LS
 * @param {LocalStorageItem} item
 * @returns {boolean}
 */
export const setLSItemSync = (item: LocalStorageItem): boolean => {
    if (!item.name || !item.value) return false;

    const { name, value } = item;
    let val2Write: string;
    if (typeof value === 'object') {
        val2Write = JSON.stringify(value);
    } else {
        val2Write = String(value);
    }
    localStorage.setItem(name, val2Write);
    return localStorage.getItem(name) === val2Write;
};

/**
 * sets data to LS
 * @param {LocalStorageItem} item
 * @returns {Promise<boolean>}
 */
export const setLSItem = async (item: LocalStorageItem): Promise<boolean> => {
    return setLSItemSync(item);
};

/**
 * removes some data from LS  by name synchronously
 * @param {string} itemName
 * @returns {Promise<boolean>}
 */
export const deleteLSItemSync = (itemName: string): boolean => {
    if (localStorage.getItem(itemName)) {
        localStorage.removeItem(itemName);
        return true;
    }

    return false;
};

/**
 * removes some data from LS  by key name
 * @param {string} itemName
 * @returns {Promise<boolean>}
 */
export const deleteLSItem = async (itemName: string): Promise<boolean> => {
    return deleteLSItemSync(itemName);
};
