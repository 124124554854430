import { UTM_MARKS } from '../constants';

export const setUtm = (): void => {
    const url = location.search;
    const urlParams = new URLSearchParams(url);

    UTM_MARKS.forEach((utm: string) => {
        const postfix = utm.slice(3);
        const prefix = utm.slice(0, 3);
        const param = `${prefix}_${postfix.toLowerCase()}`;

        if (urlParams.has(param)) {
            const paramValue = urlParams.get(param);
            localStorage.setItem(utm, paramValue || '');
        }
    });
};

export const getUtm = (): Object => {
    let marks = {};

    UTM_MARKS.forEach((utm: string) => {
        marks = {
            ...marks,
            [utm]: localStorage.getItem(utm) ?? '',
        };
    });

    return marks;
};

export default getUtm;
