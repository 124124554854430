import React from 'react';
import { HelmetBlock } from 'uikit-lib';
import { TermsOfUseItem } from './components';

export const TermsOfUse: React.FC = (): JSX.Element => {
    const hlaToken = 'APP__TERMS_OF_USE_PAGE';

    return (
        <>
            <HelmetBlock
                lang={{
                    title: 'Условия использования',
                    description: 'terms of usage',
                }}
            />
            <TermsOfUseItem
                tokens={{
                    hlaToken,
                }}
            />
        </>
    );
};

export default TermsOfUse;
