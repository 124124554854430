/**
 * SOCIAL ICONS START ==>
 */
import { ReactComponent as FB } from '../../assets/icons/social/facebook.svg';
import { ReactComponent as INSTAGRAM } from '../../assets/icons/social/instagram.svg';
import { ReactComponent as LINKEDIN } from '../../assets/icons/social/linkedin.svg';
import { ReactComponent as MESSENGER } from '../../assets/icons/social/messenger.svg';
import { ReactComponent as OK } from '../../assets/icons/social/ok.svg';
import { ReactComponent as TELEGRAM } from '../../assets/icons/social/telegram.svg';
import { ReactComponent as TWITTER } from '../../assets/icons/social/twitter.svg';
import { ReactComponent as VK } from '../../assets/icons/social/vk.svg';
import { ReactComponent as YOUTUBE } from '../../assets/icons/social/youtube.svg';
/**
 * <== SOCIAL ICONS END
 */
/**
 * ARROW ICONS START ==>
 */
import { ReactComponent as SIMPLE_ARROW } from '../../assets/icons/arrows/arrow.svg';
import { ReactComponent as DOUBLE_ARROW } from '../../assets/icons/arrows/double_arrow.svg';
import { ReactComponent as ENTER_ARROW } from '../../assets/icons/arrows/enter_arrow.svg';
import { ReactComponent as MINI_ARROW } from '../../assets/icons/arrows/mini_arrow.svg';
import { ReactComponent as MOVE_TO_ARROW } from '../../assets/icons/arrows/move_to_arrow.svg';
import { ReactComponent as PLAY_ARROW } from '../../assets/icons/arrows/play_arrow.svg';
import { ReactComponent as ROUNDED_PLAY_ARROW } from '../../assets/icons/arrows/rounded_play_arrow.svg';
import { ReactComponent as RUN_ARROW } from '../../assets/icons/arrows/run_arrow.svg';
/**
 * <== ARROW ICONS END
 */
/**
 * CORE ICONS START ==>
 */
import { ReactComponent as ACCOUNT } from '../../assets/icons/core/account.svg';
import { ReactComponent as APPLY } from '../../assets/icons/core/apply.svg';
import { ReactComponent as ADD_USER } from '../../assets/icons/core/add_user.svg';
import { ReactComponent as BELL } from '../../assets/icons/core/bell.svg';
import { ReactComponent as CROSS } from '../../assets/icons/core/cross.svg';
import { ReactComponent as DOWNLOAD } from '../../assets/icons/core/download.svg';
import { ReactComponent as GLOBUS } from '../../assets/icons/core/globus.svg';
import { ReactComponent as GRID } from '../../assets/icons/core/grid.svg';
import { ReactComponent as HEART } from '../../assets/icons/core/heart.svg';
import { ReactComponent as KEY } from '../../assets/icons/core/key.svg';
import { ReactComponent as LICENSE } from '../../assets/icons/core/license.svg';
import { ReactComponent as LIKE } from '../../assets/icons/core/like.svg';
import { ReactComponent as LINK } from '../../assets/icons/core/link.svg';
import { ReactComponent as LOAD } from '../../assets/icons/core/load.svg';
import { ReactComponent as LOCK } from '../../assets/icons/core/lock.svg';
import { ReactComponent as MAP_DOT } from '../../assets/icons/core/map_dot.svg';
import { ReactComponent as ORGANIZATION } from '../../assets/icons/core/organization.svg';
import { ReactComponent as PENCIL } from '../../assets/icons/core/pencil.svg';
import { ReactComponent as PLUS } from '../../assets/icons/core/plus.svg';
import { ReactComponent as POST } from '../../assets/icons/core/post.svg';
import { ReactComponent as REPORTS } from '../../assets/icons/core/reports.svg';
import { ReactComponent as SETTINGS } from '../../assets/icons/core/settings.svg';
import { ReactComponent as SHIELD } from '../../assets/icons/core/shield.svg';
import { ReactComponent as STAR } from '../../assets/icons/core/star.svg';
/**
 * <== CORE ICONS END
 */

/**
 * STATUS ICONS START ==>
 */
import { ReactComponent as DANGER__IS_FULFILLED } from '../../assets/icons/statuses/danger__is_fulfilled.svg';
import { ReactComponent as DANGER } from '../../assets/icons/statuses/danger.svg';
import { ReactComponent as ERROR__IS_FULFILLED } from '../../assets/icons/statuses/error__is_fulfilled.svg';
import { ReactComponent as ERROR } from '../../assets/icons/statuses/error.svg';
import { ReactComponent as INFO__IS_FULFILLED } from '../../assets/icons/statuses/info__is_fulfilled.svg';
import { ReactComponent as INFO } from '../../assets/icons/statuses/info.svg';
import { ReactComponent as SUCCESS__IS_FULFILLED } from '../../assets/icons/statuses/ok__is_fulfilled.svg';
import { ReactComponent as SUCCESS } from '../../assets/icons/statuses/ok.svg';
import { ReactComponent as QUESTION__IS_FULFILLED } from '../../assets/icons/statuses/question__is_fulfilled.svg';
import { ReactComponent as QUESTION } from '../../assets/icons/statuses/question.svg';
import { ReactComponent as WARNING__IS_FULFILLED } from '../../assets/icons/statuses/warning__is_fulfilled.svg';
import { ReactComponent as WARNING } from '../../assets/icons/statuses/warning.svg';

/**
 * <== STATUS ICONS END
 */

export const iconsMap = {
    FB,
    INSTAGRAM,
    LINKEDIN,
    MESSENGER,
    OK,
    TELEGRAM,
    TWITTER,
    VK,
    YOUTUBE,
    SIMPLE_ARROW,
    DOUBLE_ARROW,
    ENTER_ARROW,
    MINI_ARROW,
    MOVE_TO_ARROW,
    PLAY_ARROW,
    ROUNDED_PLAY_ARROW,
    RUN_ARROW,
    ACCOUNT,
    ADD_USER,
    APPLY,
    BELL,
    CROSS,
    DOWNLOAD,
    GLOBUS,
    GRID,
    HEART,
    KEY,
    LICENSE,
    LIKE,
    LINK,
    LOAD,
    LOCK,
    MAP_DOT,
    ORGANIZATION,
    PENCIL,
    PLUS,
    POST,
    REPORTS,
    SETTINGS,
    SHIELD,
    STAR,
    DANGER__IS_FULFILLED,
    DANGER,
    ERROR__IS_FULFILLED,
    ERROR,
    INFO__IS_FULFILLED,
    INFO,
    SUCCESS__IS_FULFILLED,
    SUCCESS,
    QUESTION__IS_FULFILLED,
    QUESTION,
    WARNING__IS_FULFILLED,
    WARNING,
};

export default iconsMap;
