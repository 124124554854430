export const theme = {
    CWrap: {
        '&.isInternshipRoadmap': {
            padding: '0 0 90px',
            background:
                'linear-gradient(228deg, #000 0%, rgba(38, 41, 99, 0.84) 100%)',
            minHeight: '700px',
            position: 'relative',
            overflow: 'hidden',
            '&::before': {
                content: '""',
                width: '457px',
                height: '373px',
                backgroundImage:
                    'url("./assets/images/directions/grid_green.webp")',
                opacity: 0.2,
                backgroundSize: 'cover',
                position: 'absolute',
                zIndex: 0,
                left: '50%',
                top: '-100px',
                '@media screen and (max-width: 1024px)': {
                    left: 'calc(100% - 200px)',
                },
                '@media screen and (max-width: 900px)': {
                    left: 'calc(100% - 200px)',
                },
            },
            '&::after': {
                content: '""',
                width: '457px',
                height: '373px',
                backgroundImage:
                    'url("./assets/images/directions/grid_green.webp")',
                opacity: 0.2,
                backgroundSize: 'cover',
                position: 'absolute',
                zIndex: 0,
                left: 'calc(50% - 500px)',
                bottom: '0px',
                '@media screen and (max-width: 1024px)': {
                    left: '0%',
                },
                '@media screen and (max-width: 900px)': {
                    left: '-200px',
                },
            },
        },
        '&.isFeedbacks': {
            background: '#DAD6E9',
        },
    },
    ContentArea: {
        position: 'relative',
    },
};

export default theme;
