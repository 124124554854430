export const LIST_STYLING = {
    'ul, ol': {
        display: 'block',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        paddingInlineStart: '40px',
        li: {
            padding: '0 0 0 10px',
        },
    },
    ol: {
        listStyleType: 'decimal',
    },
    ul: {
        listStyleType: 'disc',
    },
};
