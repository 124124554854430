import React, { Suspense, useEffect, useState } from 'react';
import { Global } from '@emotion/react';
import { Helmet } from 'react-helmet';
import { AppLayout, CoreProvider } from '..';
import {
    ColorVars,
    LayoutingVars,
    TypographyVars,
    NormalizeCSS,
    GlobalStyles,
    FontStyles,
} from 'src/assets';
import { AppSettings } from 'uikit-lib/src/types/app';

export const App: React.FC = (): JSX.Element => {
    const [appSettings, setAppSettings] = useState<AppSettings | null>(null);

    useEffect(() => {
        fetch('/settings/settings.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => response.json())
            .then((settings: AppSettings) => setAppSettings(settings))
            .catch((e: Error) => {
                throw new Error(`Cannot load app settings: ${e.message}`);
            });
    }, []);

    if (!appSettings) {
        return <span>Loading, please wait...</span>;
    }

    return (
        <>
            <Global styles={NormalizeCSS} />
            <Global styles={FontStyles} />
            <Global styles={LayoutingVars} />
            <Global styles={ColorVars} />
            <Global styles={TypographyVars} />
            <Global styles={GlobalStyles} />
            <Helmet>
                <title>{'Kaspersky SafeBoard'}</title>
                <meta
                    name='description'
                    content='Kaspersky SafeBoard Project'
                />
            </Helmet>
            <CoreProvider appSettings={appSettings}>
                <Suspense fallback={<span>Loading, please wait...</span>}>
                    <AppLayout appSettings={appSettings} />
                </Suspense>
            </CoreProvider>
        </>
    );
};

export default App;
