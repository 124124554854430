import styled from '@emotion/styled';

export const CWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    &.isInfo svg {
        color: var(--COLORS__PRIMARY_PALETTE____GREEN);
    }

    &.isError svg {
        color: var(--COLORS__SECONDARY_PALETTE____RED);
    }
`;

export const Text = styled.p`
    width: 100%;
`;
