export const theme = {
    CWrap: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '20px',

        '@media screen and (max-width: 640px)': {
            padding: '16px 0 50px',
            flexDirection: 'column',
            gap: '10px',

            button: {
                width: '100%',
            },
        },
    },
};

export default theme;
