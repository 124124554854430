import React from 'react';
import { BaseFCProps, SectionNodes, withBaseProps } from '../../';
import { CWrap, ContentArea } from './Section.styles';

export interface Props extends BaseFCProps<SectionNodes> {
    type: string;
    modifyClasses?: {
        CWrap?: string;
        Title?: string;
        Description?: string;
    };
    children?: React.ReactNode;
    id?: string;
}

export const Section: React.FC<Props> = (props): JSX.Element => {
    const {
        theme,
        testIds,
        tokens: { groupToken = '' },
        modifyClasses,
        children,
        id,
    } = props;

    return (
        <CWrap
            id={id ? id : ''}
            className={modifyClasses?.CWrap || ''}
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}>
            <ContentArea
                className={modifyClasses?.CWrap || ''}
                theme={theme.ContentArea}
                data-at-selector={`${groupToken}__IS_CONTENT_AREA`}
                data-testid={testIds.ContentArea}>
                {children}
            </ContentArea>
        </CWrap>
    );
};

export default withBaseProps(Section, {
    name: 'Section',
    code: 'SECTION',
    nodeNames: Object.keys(SectionNodes),
});
