import React, { useEffect, useState } from 'react';
import {
    withBaseProps,
    SafeboardHomeIntroNodes,
    BaseFCProps,
    Section,
    Button,
    toDayAndMonth,
} from 'uikit-lib';
import { CWrap, Title, Subtitle, TitleContent } from './Intro.styles';
import lang from './lang';
import { runWithResize } from './api';
import { useStore } from 'src/containers';
import { HomeIntroBanner } from './features';

export interface Props extends BaseFCProps<SafeboardHomeIntroNodes> {
    handleShowModal: () => void;
}

export const HomeIntro: React.FC<Props> = (props): JSX.Element => {
    const {
        handleShowModal,
        theme,
        tokens: { groupToken, hlaToken },
        testIds,
    } = props;
    const {
        FetchedData: {
            registerFormConfig: { isOpened, finish },
            homepageSettings: { introTitle },
            eventAdt: { link, description, isVisible, image },
        },
    } = useStore();

    const [imagePostfix, setImagePostfix] = useState<string>('');

    const resizeFn = () => {
        return runWithResize(setImagePostfix);
    };

    useEffect(() => {
        resizeFn();
        window.addEventListener('resize', resizeFn);

        return () => {
            window.removeEventListener('resize', resizeFn);
        };
    }, []);

    return (
        <CWrap
            style={{
                backgroundImage: `url("./assets/images/home/home-intro-${imagePostfix}.webp")`,
            }}
            data-at-selector={groupToken}
            theme={theme.CWrap}
            data-testid={testIds.CWrap}>
            <Section type={'BASIC'} tokens={{ hlaToken }}>
                {isVisible && (
                    <HomeIntroBanner
                        tokens={{
                            hlaToken,
                            groupToken: 'HOME__INTRO__BANNER',
                        }}
                        content={{
                            description,
                            link,
                            image,
                        }}
                    />
                )}

                <Title theme={theme.Title} data-testid={testIds.Title}>
                    {introTitle}
                </Title>

                <TitleContent
                    theme={theme.TitleContent}
                    data-testid={theme.TitleContent}>
                    {isOpened === false && (
                        <Subtitle
                            theme={theme.Subtitle}
                            data-testid={testIds.Subtitle}>
                            {lang.closed}
                        </Subtitle>
                    )}
                    {isOpened === true && (
                        <>
                            <Button
                                type={'GRADIENT_NEON'}
                                caption={lang.btnCaption}
                                tokens={{
                                    groupToken: 'HOME_INTRO__APPLY_BTN',
                                    hlaToken,
                                    actionToken: 'HOME_INTRO__APPLY',
                                }}
                                handleOnClick={() => {
                                    handleShowModal();
                                }}
                            />
                            {finish && (
                                <Subtitle
                                    theme={theme.Subtitle}
                                    data-testid={testIds.Subtitle}>
                                    {lang.until.replace(
                                        '${date}',
                                        toDayAndMonth(finish)
                                    )}
                                </Subtitle>
                            )}
                        </>
                    )}
                </TitleContent>
            </Section>
        </CWrap>
    );
};

export default withBaseProps(HomeIntro, {
    name: 'HomeIntro',
    code: 'HOME_INTRO',
    nodeNames: Object.keys(SafeboardHomeIntroNodes),
});
