import React, { useState } from 'react';
import { withBaseProps, BaseFCProps, TabsNodes } from '../../';
import { TabsPanel, TabPane } from '../';
import { CWrap, PanesWrap } from './Tabs.styles';

export interface Props extends BaseFCProps<TabsNodes> {
    initState: {
        [key: string | number]: boolean;
    };
    value2ResetState: {
        [key: string | number]: boolean;
    };
    panelData: {
        caption: string;
        tabId: string | number;
    }[];
    panesContent: {
        tabId: string | number;
        FC2Render: React.FC;
    }[];
}

export const Tabs: React.FC<Props> = (props): JSX.Element => {
    const {
        initState,
        value2ResetState,
        panelData,
        panesContent,
        theme,
        testIds,
        tokens,
        tokens: { groupToken = '' },
    } = props;

    const [tabsState, setTabsState] = useState(initState);

    return (
        <CWrap
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}>
            <TabsPanel
                tabsState={tabsState}
                items={panelData}
                tokens={tokens}
                handleOnClickTab={(tabId: string | number): void => {
                    setTabsState({
                        ...value2ResetState,
                        [tabId]: true,
                    });
                }}
            />
            <PanesWrap
                theme={theme.PanesWrap}
                data-at-selector={groupToken}
                data-testid={testIds.PanesWrap}>
                {panesContent.map(({ tabId, FC2Render }) => {
                    return (
                        <TabPane
                            key={window.crypto.randomUUID()}
                            isActive={tabsState[tabId]}
                            FC2Render={FC2Render}
                            tokens={tokens}
                        />
                    );
                })}
            </PanesWrap>
        </CWrap>
    );
};

export default withBaseProps<TabsNodes, Props>(Tabs, {
    name: 'Tabs',
    code: 'TABS',
    nodeNames: Object.keys(TabsNodes),
});
