import { css } from '@emotion/react';

export const FontStyles = css`
    @font-face {
        /**
         * Extra Bold Font. It's weight is equal to 800
         * For better understanding read this:
         * https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
         */
        font-family: 'Kaspersky';
        font-style: normal;
        font-weight: 800;
        font-display: optional;
        /**
         * Super Modern Browsers use woff2. This font extention may be 
         * the first to be preloaded by default in browser.
         * Pretty Modern Browsers use woff.
         * Safari, Android, iOS use ttf.
         * IE6-IE8 use eot.
         * Our solution covers all fonts to be preloaded with link rel="preload".
         * "font-display: optional" rule improves font rendering if it's combined with preload.
         *
         */
        src: url('/assets/fonts/Kaspersky_Sans_Display_ExtraBold.woff2');
        src: url('/assets/fonts/Kaspersky_Sans_Display_ExtraBold.woff2')
                format('woff2'),
            url('/assets/fonts/Kaspersky_Sans_Display_ExtraBold.woff')
                format('woff'),
            url('/assets/fonts/Kaspersky_Sans_Display_ExtraBold.eot?#iefix')
                format('embedded-opentype'),
            url('/assets/fonts/Kaspersky_Sans_Display_ExtraBold.ttf')
                format('truetype');
    }

    @font-face {
        /**
         * Bold Font. It's weight is equal to 700
         */
        font-family: 'Kaspersky';
        font-style: normal;
        font-weight: 700;
        font-display: optional;
        src: url('/assets/fonts/Kaspersky_Sans_Display_Bold.woff2');
        src: url('/assets/fonts/Kaspersky_Sans_Display_Bold.woff2')
                format('woff2'),
            url('/assets/fonts/Kaspersky_Sans_Display_Bold.woff') format('woff'),
            url('/assets/fonts/Kaspersky_Sans_Display_Bold.eot?#iefix')
                format('embedded-opentype'),
            url('/assets/fonts/Kaspersky_Sans_Display_Bold.ttf')
                format('truetype');
    }

    @font-face {
        /**
         * Semi Bold Font. It's weight is equal to 800
         */
        font-family: 'Kaspersky';
        font-style: normal;
        font-weight: 600;
        font-display: optional;
        src: url('/assets/fonts/Kaspersky_Sans_Display_SemiBold.woff2');
        src: url('/assets/fonts/Kaspersky_Sans_Display_SemiBold.woff2')
                format('woff2'),
            url('/assets/fonts/Kaspersky_Sans_Display_SemiBold.woff')
                format('woff'),
            url('/assets/fonts/Kaspersky_Sans_Display_SemiBold.eot?#iefix')
                format('embedded-opentype'),
            url('/assets/fonts/Kaspersky_Sans_Display_SemiBold.ttf')
                format('truetype');
    }

    @font-face {
        /**
         * Medium Font. It's weight is equal to 800
         */
        font-family: 'Kaspersky';
        font-style: normal;
        font-weight: 500;
        font-display: optional;
        src: url('/assets/fonts/Kaspersky_Sans_Display_Medium.woff2');
        src: url('/assets/fonts/Kaspersky_Sans_Display_Medium.woff2')
                format('woff2'),
            url('/assets/fonts/Kaspersky_Sans_Display_Medium.woff')
                format('woff'),
            url('/assets/fonts/Kaspersky_Sans_Display_Medium.eot?#iefix')
                format('embedded-opentype'),
            url('/assets/fonts/Kaspersky_Sans_Display_Medium.ttf')
                format('truetype');
    }

    @font-face {
        /**
         * Regular Font. It's weight is equal to 800
         */
        font-family: 'Kaspersky';
        font-style: normal;
        font-weight: 400;
        font-display: optional;
        src: url('/assets/fonts/Kaspersky_Sans_Display_Regular.woff2');
        src: url('/assets/fonts/Kaspersky_Sans_Display_Regular.woff2')
                format('woff2'),
            url('/assets/fonts/Kaspersky_Sans_Display_Regular.woff')
                format('woff'),
            url('/assets/fonts/Kaspersky_Sans_Display_Regular.eot?#iefix')
                format('embedded-opentype'),
            url('/assets/fonts/Kaspersky_Sans_Display_Regular.ttf')
                format('truetype');
    }

    @font-face {
        /**
         * Light Font. It's weight is equal to 800
         */
        font-family: 'Kaspersky';
        font-style: normal;
        font-weight: 300;
        font-display: optional;
        src: url('/assets/fonts/Kaspersky_Sans_Display_Light.woff2');
        src: url('/assets/fonts/Kaspersky_Sans_Display_Light.woff2')
                format('woff2'),
            url('/assets/fonts/Kaspersky_Sans_Display_Light.woff')
                format('woff'),
            url('/assets/fonts/Kaspersky_Sans_Display_Light.eot?#iefix')
                format('embedded-opentype'),
            url('/assets/fonts/Kaspersky_Sans_Display_Light.ttf')
                format('truetype');
    }
`;
