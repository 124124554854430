export const theme = {
    CWrap: {
        display: 'none',
        flexDirection: 'column',
        padding: '20px 0 32px',
        borderTop: '2px solid var(--COLORS__GRAYSCALE____7)',

        '&.isActive': {
            display: 'flex',
        },
    },
};

export default theme;
