/**
 *
 * @param {string} value descriptor property value
 * @param {boolean} isLast flag to add postfix '__'
 * @returns {string} empty string or string with value and possible postfix
 */
export const checkProp = (value: string, isLast: boolean) => {
    return value ? `${value}${isLast ? '' : '__'}` : '';
};

export default checkProp;
