export const styles = {
    /*
    clearIndicator: (baseStyles: Record<string, unknown>) => {
      // console.warn('clearIndicator Base Styles - ', baseStyles);
      return {
        ...baseStyles,
      };
    },
    */
    container: (baseStyles: Record<string, unknown>) => {
        // console.warn('container Base Styles - ', baseStyles);
        return {
            ...baseStyles,
        };
    },
    control: (baseStyles: Record<string, unknown>) => {
        // console.warn('control Base Styles - ', baseStyles, state);
        return {
            ...baseStyles,
            borderColor: 'var(--COLORS__GRAYSCALE____4)',
            borderWidth: '1px',
            borderRadius: '8px',
            boxShadow: 'none',

            '&:hover': {
                borderColor: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
            },

            '&:focus': {
                borderColor: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
            },
        };
    },
    dropdownIndicator: (baseStyles: Record<string, unknown>) => {
        return {
            ...baseStyles,
            color: 'var(--COLORS__GRAYSCALE____4)',

            '& svg': {
                width: '16px',
                height: '16px',
            },

            '&:hover': {
                color: 'var(--COLORS__PRIMARY_PALETTE____BLACK)',
            },
        };
    },
    /*
    group: (baseStyles: Record<string, unknown>) => {
      // console.warn('group Base Styles - ', baseStyles);
      return {
        ...baseStyles,
      };
    },
    */
    /*
    groupHeading: (baseStyles: Record<string, unknown>) => {
      // console.warn('groupHeading Base Styles - ', baseStyles);
      return {
        ...baseStyles,
      };
    },
    */
    indicatorContainer: (baseStyles: Record<string, unknown>) => {
        // console.warn('indicatorsContainer Base Styles - ', baseStyles);
        return {
            ...baseStyles,
            color: 'var(--COLORS__GRAYSCALE____5)',
            strokeWidth: '1px!important',
        };
    },
    indicatorSeparator: (baseStyles: Record<string, unknown>) => {
        // console.warn('indicatorSeparator Base Styles - ', baseStyles);
        return {
            ...baseStyles,
            display: 'none',
        };
    },
    input: (baseStyles: Record<string, unknown>) => {
        return {
            ...baseStyles,
            lineHeight: '48px',
            margin: '0px',
            padding: '0px',
        };
    },
    /*
    loadingIndicator: (baseStyles: Record<string, unknown>) => {
      // console.warn('loadingIndicator Base Styles - ', baseStyles);
      return {
        ...baseStyles,
      };
    },
    */
    /*
    loadingMessage: (baseStyles: Record<string, unknown>) => {
      // console.warn('loadingMessage Base Styles - ', baseStyles);
      return {
        ...baseStyles,
      };
    },
    */
    /*
    menu: (baseStyles: Record<string, unknown>) => {
      // console.warn('menu Base Styles - ', baseStyles);
      return {
        ...baseStyles,
      };
    },
    */
    /*
    menuList: (baseStyles: Record<string, unknown>) => {
      // console.warn('menuList Base Styles - ', baseStyles);
      return {
        ...baseStyles,
      };
    },
    */
    /*
    menuPortal: (baseStyles: Record<string, unknown>) => {
      // console.warn('menuPortal Base Styles - ', baseStyles);
      return {
        ...baseStyles,
      };
    },
    */
    /*
    multiValue: (baseStyles: Record<string, unknown>) => {
      // console.warn('multiValue Base Styles - ', baseStyles);
      return {
        ...baseStyles,
      };
    },
    */
    /*
    multiValueLabel: (baseStyles: Record<string, unknown>) => {
      // console.warn('multiValueLabel Base Styles - ', baseStyles);
      return {
        ...baseStyles,
      };
    },
    */
    /*
    multiValueRemove: (baseStyles: Record<string, unknown>) => {
      // console.warn('multiValueRemove Base Styles - ', baseStyles);
      return {
        ...baseStyles,
      };
    },
    */
    /*
    noOptionsMessage: (baseStyles: Record<string, unknown>) => {
      // console.warn('noOptionsMessage Base Styles - ', baseStyles);
      return {
        ...baseStyles,
      };
    },
    */
    option: (
        baseStyles: Record<string, unknown>,
        { isSelected }: { isSelected: boolean }
    ) => {
        // console.warn('option Base Styles - ', baseStyles);
        return {
            ...baseStyles,
            background: 'transparent',
            color: 'var(--COLORS__PRIMARY_PALETTE____BLACK)',
            fontWeight: isSelected ? 600 : 400,

            '&:hover': {
                background: 'var(--COLORS__GRAYSCALE____7)',
            },
        };
    },
    placeholder: (baseStyles: Record<string, unknown>) => {
        // console.warn('placeholder Base Styles - ', baseStyles);
        return {
            ...baseStyles,
            margin: 0,
            fontSize: '14px',
            fontWeight: 400,
        };
    },
    singleValue: (baseStyles: Record<string, unknown>) => {
        // console.warn('singleValue Base Styles - ', baseStyles);
        return {
            ...baseStyles,
            color: 'var(--COLORS__PRIMARY_PALETTE____BLACK)',
            fontWeight: 400,
            margin: 0,
        };
    },
    valueContainer: (baseStyles: Record<string, unknown>) => {
        return {
            ...baseStyles,
            padding: '0px 0px 0px 12px',
        };
    },
};

export default styles;
