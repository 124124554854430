import styled from '@emotion/styled';
import { getCSSObj } from '../../utils';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const Track = styled.div`
    ${getCSSObj}
`;

export const Item = styled.div`
    ${getCSSObj}
`;

export const ItemTitle = styled.h5`
    ${getCSSObj}
`;

export const ItemSubTitle = styled.h6`
    ${getCSSObj}
`;

export const ItemText = styled.p`
    ${getCSSObj}
`;
