/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useContext } from 'react';
import API from './api';

import { DocumentNode } from '@apollo/client';
import { GraphQLAPI } from 'uikit-lib/src/types';

export interface Props {
    children: React.ReactNode;
}

export const GraphQLProviderContext = createContext<GraphQLAPI>({
    queries: null,
    mutations: null,
});

export const GraphQLProvider: React.FC<Props> = ({ children }): JSX.Element => (
    <GraphQLProviderContext.Provider value={API}>
        {children}
    </GraphQLProviderContext.Provider>
);

export const useQueryDictionary = (queryNames: string[]): DocumentNode[] => {
    const { queries } = useContext(GraphQLProviderContext);

    const targetQueries: DocumentNode[] = [];
    queryNames.forEach((queryName) => {
        // @ts-ignore
        targetQueries.push(queries[queryName]);
    });
    return targetQueries;
};

export const useMutationDictionary = (mutationName: string): DocumentNode => {
    const { mutations } = useContext(GraphQLProviderContext);
    if (
        !mutations ||
        !Object.keys(mutations).length ||
        !Object.prototype.hasOwnProperty.call(mutations, mutationName)
    )
        throw new Error(
            'useQueryDictionary must be used within GraphQLProvider'
        );
    return mutations[mutationName];
};

export default GraphQLProvider;
