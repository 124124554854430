import Logo from './Logo';
import Marquee from './Marquee';
import Menu from './Menu';
import Button from './Button';
import BurgerBtn from './BurgerBtn';
import Dropdown from './Dropdown';
import SocialLinks from './SocialLinks';
import Section from './Section';
import MiniCard from './MiniCard';
import BlockTitle from './BlockTitle';
import NativeVideo from './NativeVideo';
import FAQ from './FAQ';
import HashLink from './HashLink';
import Accordion from './Accordion';
import AccordionItem from './AccordionItem';
import CookieBanner from './CookieBanner';
import {
    MaskedInputBox,
    InputBox,
    CheckBox,
    TextArea,
    Tab,
    TabsPanel,
    TabPane,
    Tabs,
    ModalContent,
    ModalHeader,
    ModalFooter,
} from '../../coreTheme';

export const componentThemes = {
    Logo,
    Marquee,
    Menu,
    Button,
    Dropdown,
    BurgerBtn,
    BlockTitle,
    SocialLinks,
    Section,
    MiniCard,
    NativeVideo,
    FAQ,
    HashLink,
    Accordion,
    AccordionItem,
    MaskedInputBox,
    InputBox,
    CheckBox,
    TextArea,
    Tab,
    TabsPanel,
    TabPane,
    Tabs,
    CookieBanner,
    ModalContent,
    ModalHeader,
    ModalFooter,
};

export default componentThemes;
