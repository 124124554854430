import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import {
    BaseFCProps,
    DirectionTeamsNodes,
    withBaseProps,
    Accordion,
} from 'uikit-lib';
import { InternshipTeam } from 'src/types';
import {
    CWrap,
    Title,
    Content,
    ContentTitle,
    ContentWrap,
} from './DirectionTeams.styles';
import lang from './lang';

export interface Props extends BaseFCProps<DirectionTeamsNodes> {
    teams: InternshipTeam[];
}

export const DirectionTeams: React.FC<Props> = (props): JSX.Element => {
    const { teams, theme, testIds } = props;

    return (
        <CWrap theme={theme.CWrap} data-testid={testIds.CWrap}>
            <Title theme={theme.Title} data-testid={testIds.Title}>
                {lang.teamTitle}
            </Title>

            <Accordion
                type={'BASIC'}
                data={teams}
                ContentFC={(item: unknown) => {
                    const subset = (({
                        description,
                        content,
                        candidateRequirement,
                        additionalRequirement,
                    }) => ({
                        description,
                        content,
                        candidateRequirement,
                        additionalRequirement,
                    }))(item as InternshipTeam);

                    return (
                        <>
                            {Object.entries(subset).map(
                                ([key, value]) =>
                                    value && (
                                        <ContentWrap
                                            key={window.crypto.randomUUID()}>
                                            {lang[key] && (
                                                <ContentTitle
                                                    theme={theme.ContentTitle}
                                                    data-testid={
                                                        testIds.ContentTitle
                                                    }>
                                                    {lang[key]}
                                                </ContentTitle>
                                            )}
                                            <Content
                                                theme={theme.Content}
                                                data-testid={testIds.Content}
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(
                                                        value || ''
                                                    ),
                                                }}
                                            />
                                        </ContentWrap>
                                    )
                            )}
                        </>
                    );
                }}
                converseFn={(sourceData: unknown) => {
                    const source: InternshipTeam = sourceData as InternshipTeam;
                    return {
                        id: source.id,
                        title: source.name,
                        description: source.description,
                        content: source.content,
                        candidateRequirement: source.candidateRequirement,
                        additionalRequirement: source.additionalRequirement,
                    };
                }}
                tokens={{
                    hlaToken: 'DIRECTION_TEAMS',
                    groupToken: 'DIRECTION_TEAMS_ACCORDION',
                }}
            />
        </CWrap>
    );
};

export default withBaseProps(DirectionTeams, {
    name: 'DirectionTeams',
    code: 'DIRECTION_TEAMS',
    nodeNames: Object.keys(DirectionTeamsNodes),
});
