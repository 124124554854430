export const theme = {
    CWrap: {
        width: '201px',
        height: '64px',

        '@media screen and (max-width: 1024px)': {
            width: '156px',
            height: '32px',
        },
    },
    Image: {
        width: '100%',
        height: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    },
    Caption: {},
};

export default theme;
