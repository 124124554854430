import { EntitiesLocale } from 'uikit-lib';

export const locale: EntitiesLocale = {
    directions: {
        SINGULAR: 'Направления',
        PLURAL: 'Направления',
    },
    calendar: {
        SINGULAR: 'Календарь',
        PLURAL: 'Календари',
    },
    events: {
        SINGULAR: 'События',
        PLURAL: 'События',
    },
    about: {
        SINGULAR: 'О компании',
        PLURAL: 'О компании',
    },
    benefits: {
        SINGULAR: 'Бенефиты',
        PLURAL: 'Бенефиты',
    },
    reviews: {
        SINGULAR: 'Отзыв',
        PLURAL: 'Отзывы',
    },
    faq: {
        SINGULAR: 'FAQ',
        PLURAL: 'FAQ',
    },
    specialProjects: {
        SINGULAR: 'Спецпроекты',
        PLURAL: 'Спецпроекты',
    },
    fillRegForm: {
        SINGULAR: 'Подать заявку',
        PLURAL: 'Подать заявку',
    },
    termsOfUse: {
        SINGULAR: 'Условия использования',
        PLURAL: 'Условия использования',
    },
    privacyPolicy: {
        SINGULAR: 'Политика конфиденциальности',
        PLURAL: 'Политика конфиденциальности',
    },
    contacts: {
        SINGULAR: 'Контакты',
        PLURAL: 'Контакты',
    },
};

export default locale;
