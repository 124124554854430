import React from 'react';
import { withBaseProps, BaseFCProps, AccordionNodes } from '../../';
import { CWrap } from './Accordion.styles';
import { AccordionItem } from './features/AccordionItem';

export interface Props extends BaseFCProps<AccordionNodes> {
    data: unknown[];
    ContentFC: React.FC<{
        id: string;
        title: string;
    }>;
    converseFn: (sourceData: unknown) => unknown;
}

export const Accordion: React.FC<Props> = (props): JSX.Element => {
    const {
        theme,
        testIds,
        data,
        converseFn,
        ContentFC,
        tokens: { hlaToken = '', groupToken = '' },
    } = props;

    return (
        <CWrap
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}>
            {data.map((item, index) => {
                const converted = converseFn(item) as {
                    id: string;
                    title: string;
                };

                return (
                    <AccordionItem
                        key={converted.id || index}
                        title={converted.title}
                        type={'BASIC'}
                        tokens={{
                            groupToken: 'ACCORDION_ITEM',
                            hlaToken,
                        }}>
                        <ContentFC {...converted} />
                    </AccordionItem>
                );
            })}
        </CWrap>
    );
};

export default withBaseProps<AccordionNodes, Props>(Accordion, {
    name: 'Accordion',
    code: 'ACCORDION',
    nodeNames: Object.keys(AccordionNodes),
});
