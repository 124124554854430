import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const CWrap = styled.header`
    ${getCSSObj}
`;

export const MainArea = styled.div`
    ${getCSSObj}
`;
