import React from 'react';
import {
    CWrap,
    MessageWrap,
    BtnWrap,
    ContentWrap,
} from './CookieBanner.styles';
import { withBaseProps, BaseFCProps, Button, Section } from '../..';
import { CookieBannerNodes } from '../../types';

export interface Props extends BaseFCProps<CookieBannerNodes> {
    message: string;
    link: React.ReactNode;
    btnCaption: string;
    accept: () => void;
}

export const CookieBanner: React.FC<Props> = (props): JSX.Element => {
    const {
        theme,
        testIds,
        message,
        link,
        btnCaption,
        accept,
        tokens: { hlaToken },
    } = props;

    return (
        <CWrap theme={theme.CWrap} data-testid={testIds.CWrap}>
            <Section type={'BASIC'} tokens={{ hlaToken }}>
                <ContentWrap
                    theme={theme.ContentWrap}
                    data-testid={testIds.ContentWrap}>
                    <MessageWrap
                        theme={theme.MessageWrap}
                        data-testid={testIds.MessageWrap}>
                        {message}
                        {link && link}
                    </MessageWrap>
                    <BtnWrap
                        theme={theme.BtnWrap}
                        data-testid={testIds.BtnWrap}>
                        <Button
                            type={'BASIC'}
                            caption={btnCaption}
                            tokens={{
                                hlaToken,
                                groupToken: 'COOKIE_BANNER_ACCEPT_BTN',
                            }}
                            handleOnClick={accept}
                        />
                    </BtnWrap>
                </ContentWrap>
            </Section>
        </CWrap>
    );
};

export default withBaseProps(CookieBanner, {
    name: 'CookieBanner',
    code: 'COOKIE_BANNER',
    nodeNames: Object.keys(CookieBannerNodes),
});
