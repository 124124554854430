import React, { useState } from 'react';
import { CWrap, ContentWrap, Header } from './AccordionItem.styles';
import { AccordionItemNodes, BaseFCProps, withBaseProps } from '../../../..';

export interface Props extends BaseFCProps<AccordionItemNodes> {
    title: string;
    children: React.ReactNode;
}

export const AccordionItem: React.FC<Props> = (props): JSX.Element => {
    const {
        theme,
        testIds,
        tokens: { groupToken = '' },
        children,
        title,
    } = props;

    const [isContentVisible, setIsContentVisible] = useState<boolean>(false);

    return (
        <CWrap
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}>
            <Header
                className={isContentVisible ? 'isVisible' : ''}
                theme={theme.Header}
                data-testid={testIds.Header}
                onClick={() => setIsContentVisible((prevState) => !prevState)}>
                {title}
            </Header>

            {isContentVisible && (
                <ContentWrap
                    theme={theme.ContentWrap}
                    data-testid={testIds.ContentWrap}>
                    {children}
                </ContentWrap>
            )}
        </CWrap>
    );
};

export default withBaseProps<AccordionItemNodes, Props>(AccordionItem, {
    name: 'AccordionItem',
    code: 'ACCORDION_ITEM',
    nodeNames: Object.keys(AccordionItemNodes),
});
