import { TYPOGRAPHY__S1, TYPOGRAPHY__T1 } from 'uikit-lib';

export const theme = {
    CWrap: {
        padding: '80px 0 100px',
        background: 'rgba(213, 213, 213, 1)',
    },
    Content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '15px',
    },
    Title: {
        ...TYPOGRAPHY__S1,
    },
    Date: {
        ...TYPOGRAPHY__T1,
        padding: 0,
    },
};

export default theme;
