import { SelectOptions } from 'uikit-lib';

export const getSelectedOptIds = (selectedOptions: SelectOptions): string[] => {
    const output: string[] = [];
    // console.log('getSelectedOptIds is ', selectedOptions);
    selectedOptions.forEach((option) => {
        output.push(String(option.value));
    });
    return output;
};

export default getSelectedOptIds;
