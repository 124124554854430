import styled from '@emotion/styled';
import { getCSSObj } from '../../utils';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const Title = styled.h5`
    ${getCSSObj}
`;

export const SubTitle = styled.h6`
    ${getCSSObj}
`;

export const ShortDescription = styled.p`
    ${getCSSObj}
`;

export const FullDescription = styled.p`
    ${getCSSObj}
`;

export const Image = styled.div`
    ${getCSSObj}
`;

export const DateTime = styled.p`
    ${getCSSObj}
`;

export const Btn = styled.button`
    ${getCSSObj}
`;

export const NavBtn = styled.button`
    ${getCSSObj}
`;

export const ExternalLink = styled.a`
    ${getCSSObj}
`;

import type { MinicardElemName } from './MiniCard';

export type MinicardElemDict = {
    [key in MinicardElemName]:
        | typeof Title
        | typeof SubTitle
        | typeof ShortDescription
        | typeof FullDescription
        | typeof Image
        | typeof DateTime
        | typeof Btn
        | typeof NavBtn
        | typeof ExternalLink;
};

export const minicardElements: MinicardElemDict = {
    Title,
    SubTitle,
    ShortDescription,
    FullDescription,
    Image,
    DateTime,
    Btn,
    NavBtn,
    ExternalLink,
    CWrap,
};
