import React from 'react';
import ReactSelect, { StylesConfig } from 'react-select';
import {
    SelectOption,
    SelectOptions,
    SelectValue,
    SelectActionName,
    BaseFCProps,
    SelectNodes,
    withBaseProps,
    SelectLangs,
} from '../../';
import styles from './styles';
import { CWrap } from './Select.styles';

export type ReactSelectProps = {
    isClearable?: boolean;
    clearValue?: () => void;
    getValue?: () => SelectValue;
    hasValue?: boolean;
    isMulti?: boolean;
    selectOption?: (type: SelectOption) => void;
    value?: SelectOption | SelectOptions;
    defaultValue?: SelectOption | SelectOptions;
    setValue?: (value: SelectValue, actionType: SelectActionName) => void;
    isOptionDisabled?: () => boolean;
};

export interface Props extends BaseFCProps<SelectNodes> {
    options: SelectOptions;
    params: ReactSelectProps;
    langs?: SelectLangs;
    handleOnChange: (value: SelectOption | SelectOptions) => void;
    isValid?: boolean;
}

const initLangs = {
    noOptionsMessage: () => '',
    placeholder: 'Select property value',
    initValidationMsg: ' ',
};

// eslint-disable-next-line react/display-name
export const Select = React.forwardRef<HTMLDivElement, Props>(
    (props, ref): JSX.Element => {
        const {
            options,
            params,
            langs = { ...initLangs },
            handleOnChange,
            isValid = true,
            tokens: { groupToken = '' },
            theme,
            testIds,
        } = props;
        const {
            noOptionsMessage = initLangs.noOptionsMessage,
            placeholder = initLangs.placeholder,
        } = langs;

        /*
        console.log(
            `RUNTIME::STATUS::Select
        Select props are - `,
            props
        );
         */

        return (
            <CWrap
                ref={ref}
                className={`${!isValid ? 'isInvalid' : ''}`}
                theme={theme.CWrap}
                data-at-selector={groupToken}
                data-testid={testIds.CWrap}>
                <ReactSelect
                    options={options}
                    styles={styles as StylesConfig}
                    onChange={(option: unknown) => {
                        handleOnChange(option as SelectOption | SelectOptions);
                    }}
                    {...params}
                    placeholder={placeholder}
                    noOptionsMessage={noOptionsMessage}
                />
            </CWrap>
        );
    }
);

export default withBaseProps<SelectNodes, Props>(Select, {
    name: 'Select',
    code: 'SELECT',
    nodeNames: Object.keys(SelectNodes),
});
