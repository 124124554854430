import styled from '@emotion/styled';
import { getCSSObj } from '../../utils';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const Input = styled.input`
    ${getCSSObj}
`;
