import { TYPOGRAPHY__S4 } from '../../coreTheme';

export const theme = {
    CWrap: {
        position: 'fixed',
        bottom: '0',
        left: '0',
        width: '100vw',
        background: 'rgba(0, 0, 0, 0.7)',
        zIndex: 100,
        padding: '20px 0',
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
    },
    MessageWrap: {
        width: '80%',
        ...TYPOGRAPHY__S4,

        '@media screen and (max-width: 1024px)': {
            width: '100%',
        },

        a: {
            padding: '0 10px',
        },
    },
    BtnWrap: {
        width: '20%',

        '@media screen and (max-width: 1024px)': {
            width: '100%',
        },
    },
    ContentWrap: {
        display: 'flex',
        flexDirection: 'row',

        '@media screen and (max-width: 1024px)': {
            flexDirection: 'column',
        },
    },
};

export default theme;
