import { ModalState } from 'src/types';

export const initModalState: ModalState = {
    isVisible: false,
    headerLangs: null,
    type: 'ADDITIONAL_INFO',
    sysType: 'IDLE',
    descriptor: {
        moduleName: '',
        moduleMode: '',
        actionType: '',
        actionName: '',
        actionMode: '',
    },
    dataId: 0,
};

export default initModalState;
