/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import {
    Section,
    Carousel,
    withBaseProps,
    FeedbacksNodes,
    BaseFCProps,
} from 'uikit-lib';
import { EmployeePolicy } from './Feedbacks.styles';
import { SectionHeader } from 'src/components';
import lang from './lang';
import { useStore } from 'src/containers/LocalDataStore';

const convertData2CarouselList = (
    items: any[],
    handleOnClickItem: (id: string | number) => void
): any[] => {
    const output: any[] = [];
    items.map((item: any, index: number) => {
        const { firstName, lastName, position, impression, image } = item;
        output.push({
            key: window.crypto.randomUUID,
            nodes: [
                {
                    tag: 'Image',
                    imgUrl: image ? image.fileLink : '',
                },
                {
                    tag: 'Title',
                    text: `${firstName} ${lastName}`,
                },
                {
                    tag: 'SubTitle',
                    text: position,
                },
                {
                    tag: 'ShortDescription',
                    text: impression,
                },
                {
                    tag: 'Btn',
                    text: 'Читать отзыв',
                    iconPosition: 'AFTER_TEXT',
                    iconParams: {
                        iconId: 'MOVE_TO_ARROW',
                        sizeId: 'REGULAR',
                    },
                    handleOnClick: (event: any) => {
                        const id = index;
                        handleOnClickItem(id);
                    },
                },
            ],
        });
    });
    return output;
};

export interface Props extends BaseFCProps<FeedbacksNodes> {
    handleOnClickItem: (id: number) => void;
}

export const Feedbacks: React.FC<any> = (props): JSX.Element => {
    const { handleOnClickItem, theme } = props;

    const {
        FetchedData: {
            feedbacks,
            homepageSettings: { careerStartTitle, careerStartDescription },
        },
    } = useStore();

    const feedbacks2Render = feedbacks.length
        ? convertData2CarouselList(feedbacks, handleOnClickItem)
        : [];

    return (
        <Section
            id='reviews'
            modifyClasses={{
                CWrap: 'isFeedbacks',
            }}
            type={'BASIC'}
            tokens={{}}>
            <SectionHeader
                type={'BASIC'}
                lang={{
                    title: careerStartTitle || '',
                    description: careerStartDescription || '',
                }}
                tokens={{}}
            />
            <EmployeePolicy theme={theme.EmployeePolicy}>
                {lang.employeePolicy}
            </EmployeePolicy>
            {!!feedbacks.length && (
                <Carousel
                    type={'FEEDBACKS'}
                    data={feedbacks2Render}
                    options={{
                        type: 'FEEDBACKS',
                        items2Show: 3,
                        itemPaddings: [30, 14, 30, 14],
                        breakpoints: {
                            '640': {
                                items2Show: 1,
                            },
                            '1024': {
                                items2Show: 2,
                            },
                        },
                    }}
                    tokens={{}}
                />
            )}
        </Section>
    );
};

export default withBaseProps<FeedbacksNodes, BaseFCProps<FeedbacksNodes>>(
    Feedbacks,
    {
        name: 'Feedbacks',
        code: 'FEEDBACKS',
        nodeNames: Object.keys(FeedbacksNodes),
    }
);
