export enum BasicFCNodes {
    CWrap = 'CWrap',
}

export enum ButtonNodes {
    CWrap = 'CWrap',
}

export enum DropdownNodes {
    CWrap = 'CWrap',
    ToggleBtn = 'ToggleBtn',
    ToggleBtnIcon = 'ToggleBtnIcon',
    ToggleCaption = 'ToggleCaption',
    ToggleBtnArrow = 'ToggleBtnArrow',
    BtnsWrap = 'BtnsWrap',
    ItemBtn = 'ItemBtn',
}

export enum InputBoxNodes {
    CWrap = 'CWrap',
    Input = 'Input',
    Placeholder = 'Placeholder',
    ResetBtn = 'ResetBtn',
}

export enum CalendarWrapNodes {
    CWrap = 'CWrap',
    CalendarContainer = 'CalendarContainer',
    CalendarValue = 'CalendarValue',
}

export enum LinkNodes {
    CWrap = 'CWrap',
}

export enum LogoNodes {
    CWrap = 'CWrap',
    Image = 'div',
    Caption = 'div',
}

export enum MarqueeNodes {
    CWrap = 'CWrap',
    Track = 'div',
    Item = 'div',
    ItemTitle = 'h5',
    ItemSubTitle = 'h6',
    ItemText = 'p',
}

export enum MiniCardNodes {
    Title = 'Title',
    SubTitle = 'SubTitle',
    ShortDescription = 'ShortDescription',
    FullDescription = 'FullDescription',
    Image = 'Image',
    DateTime = 'DateTime',
    Btn = 'Btn',
    NavBtn = 'NavBtn',
    ExternalLink = 'ExternalLink',
}

export enum CheckBoxNodes {
    CWrap = 'CWrap',
    Input = 'Input',
    IconWrap = 'IconWrap',
}

export enum SectionNodes {
    CWrap = 'CWrap',
    ContentArea = 'ContentArea',
}

export enum PaginatorNodes {
    CWrap = 'CWrap',
}

export enum SelectNodes {
    CWrap = 'CWrap',
    Image = 'Image',
    Caption = 'Caption',
}

export enum SymbCounterNodes {
    CWrap = 'CWrap',
    CurCount = 'CurCount',
    MaxLimit = 'MaxLimit',
}

export enum TabNodes {
    CWrap = 'CWrap',
}

export enum TabPaneNodes {
    CWrap = 'CWrap',
}

export enum TabsPanelNodes {
    CWrap = 'CWrap',
}

export enum TabsNodes {
    CWrap = 'CWrap',
    PanesWrap = 'PanesWrap',
}

export enum AccordionNodes {
    CWrap = 'CWrap',
}

export enum AccordionItemNodes {
    CWrap = 'CWrap',
    ContentWrap = 'ContentWrap',
    Header = 'Header',
}

export enum ModalHeaderNodes {
    CWrap = 'CWrap',
    CloseBtn = 'CloseBtn',
}

export enum ModalContentNodes {
    CWrap = 'CWrap',
}

export enum ModalFooterNodes {
    CWrap = 'CWrap',
}

export enum TagNodes {
    CWrap = 'CWrap',
}

export enum TagListNodes {
    CWrap = 'CWrap',
}

export enum TextNodes {
    CWrap = 'CWrap',
    Image = 'Image',
    Caption = 'Caption',
}

export enum TextAreaNodes {
    CWrap = 'CWrap',
    Placeholder = 'Placeholder',
    InputArea = 'InputArea',
}

export enum TooltipNodes {
    CWrap = 'CWrap',
    ContentWrap = 'ContentWrap',
    Caption = 'Caption',
}

export enum UploaderNodes {
    CWrap = 'CWrap',
}

export enum VacanciesBannerLinkNodes {
    CWrap = 'CWrap',
    AnimatingBlock = 'AnimatingBlock',
    Text = 'Text',
    Button = 'Button',
}

export enum YandexMapNodes {
    CWrap = 'CWrap',
}

export enum YouTubePlayerNodes {
    CWrap = 'CWrap',
}

export enum CarouselNodes {
    CWrap = 'CWrap',
    Arrow = 'Arrow',
    Track = 'Track',
    Slide = 'Slide',
    PaginationWrap = 'PaginationWrap',
    Dot = 'Dot',
}

export enum CMSPageHeaderNodes {
    CWrap = 'CWrap',
    Main = 'Main',
}

export enum MenuNodes {
    CWrap = 'CWrap',
}

export enum SidebarNodes {
    CWrap = 'CWrap',
    Header = 'Header',
    Main = 'Main',
    Footer = 'Footer',
}

export enum FormBuilderNodes {
    CWrap = 'CWrap',
    ImageWrap = 'ImageWrap',
    OptionBox = 'OptionBox',
    Image = 'Image',
    NumberInputBox = 'NumberInputBox',
    TextInputBox = 'TextInputBox',
}

export enum FileUploaderNodes {
    CWrap = 'CWrap',
    Input = 'Input',
    Label = 'Label',
    Delete = 'Delete',
}

export enum SpecialProjectsNodes {
    CWrap = 'CWrap',
}

export enum ReviewsNodes {
    CWrap = 'CWrap',
}

export enum PolicyNodes {
    Title = 'Title',
    SubTitle = 'SubTitle',
    CWrap = 'CWrap',
    Section = 'Section',
    Chapter = 'Chapter',
    HorizontalLine = 'HorizontalLine',
    Table = 'Table',
    TableHead = 'TableHead',
    TableBody = 'TableBody',
    TableRow = 'TableRow',
    TableData = 'TableData',
    TableHeadData = 'TableHeadData',
    NumberList = 'NumberList',
    ListItem = 'ListItem',
    PointList = 'PointList',
    Anchor = 'Anchor',
    SupText = 'SupText',
}

export enum PubSpecialProjectNodes {
    CWrap = 'CWrap',
    Title = 'Title',
    ProjectListContainer = 'ProjectListContainer',
    Section = 'Section',
    ProjectListItems = 'ProjectListItems',
    ProjectListItem = 'ProjectListItem',
    AllProjectsBtnContainer = 'AllProjectsBtnContainer',
}

export enum PubFooterNodes {
    CWrap = 'CWrap',
    MainArea = 'MainArea',
    Column = 'Column',
    Copyright = 'Copyright',
}

export enum SafeboardHomeIntroNodes {
    CWrap = 'CWrap',
    Title = 'Title',
    Subtitle = 'Subtitle',
    TitleContent = 'TitleContent',
}

export enum HomeIntroBannerNodes {
    CWrap = 'CWrap',
    Title = 'Title',
    Image = 'Image',
    Column = 'Column',
}

export enum PubHeaderNodes {
    CWrap = 'CWrap',
    MainArea = 'MainArea',
}

export enum PubBurgerBtnNodes {
    CWrap = 'CWrap',
    LinesWrap = 'LinesWrap',
    Line = 'Line',
}

export enum PubSocialLinksNodes {
    CWrap = 'CWrap',
    BtnItem = 'BtnItem',
}

export enum TraineeFeedbacksNodes {
    CWrap = 'CWrap',
    Title = 'Title',
    HeroesList = 'HeroesList',
    HeroesContainer = 'HeroesContainer',
    Section = 'Section',
}

export enum PubSecurePathNodes {
    CWrap = 'CWrap',
    Title = 'Title',
    Btn = 'Btn',
    Date = 'Date',
    Content = 'Content',
}

export enum GradientShapeNodes {
    CWrap = 'CWrap',
}

export enum SeparatorNodes {
    CWrap = 'CWrap',
}

export enum ModalNodes {
    SplashBg = 'SplashBg',
    ModalWrap = 'ModalWrap',
}

export enum PubFaqNodes {
    CWrap = 'CWrap',
    Title = 'Title',
    Content = 'Content',
    RowContainer = 'RowContainer',
    RowContent = 'RowContent',
    RowTitle = 'RowTitle',
    BackgroundImage = 'BackgroundImage',
    Question = 'Question',
    Answer = 'Answer',
    RowContentItem = 'RowContentItem',
    AnswerIcon = 'AnswerIcon',
}

export enum FormNodes {
    CWrap = 'CWrap',
    ActionContainer = 'ActionContainer',
}

export enum NotFoundNodes {
    CWrap = 'CWrap',
    Container = 'Container',
    Content = 'Content',
    BearTop = 'BearTop',
    BearBottom = 'BearBottom',
    ContentImage = 'ContentImage',
    ContentText = 'ContentText',
    Title = 'Title',
    Subtitle = 'Subtitle',
}

export enum TCellNodes {
    CWrap = 'CWrap',
}

export enum HashLinkNodes {
    CWrap = 'CWrap',
}

export enum CookieBannerNodes {
    CWrap = 'CWrap',
    MessageWrap = 'MessageWrap',
    BtnWrap = 'BtnWrap',
    ContentWrap = 'ContentWrap',
}

export enum PicturePreviewNodes {
    CWrap = 'CWrap',
}

export enum TRowNodes {
    CWrap = 'CWrap',
}

export enum TableNodes {
    CWrap = 'CWrap',
    TBody = 'TBody',
    THead = 'THead',
    NoDataMsg = 'NoDataMsg',
}

export enum SpecialProjectNodes {
    CWrap = 'CWrap',
}

export enum SpecialProjectFormNodes {
    CWrap = 'CWrap',
    Form = 'Form',
    InputWrap = 'InputWrap',
    ActionContainer = 'ActionContainer',
}

export enum InstanceNodes {
    CWrap = 'CWrap',
}

export enum PropertyRowNodes {
    CWrap = 'CWrap',
    InputArea = 'InputArea',
}

export enum PropertyValueNodes {
    CWrap = 'CWrap',
    Value = 'Value',
    EditNowBtn = 'EditNowBtn',
    InputNotice = 'InputNotice',
    // 'SUCCEED', 'VALIDATION_FAILED', 'READY_TO_START_CHECKING',
    // 'IDLE', 'READY_TO_FILL', 'ENABLED_TO_FILL'
    // or ExceptionCheckCode abbrIVIATION
}

export enum PropertyLabelNodes {
    CWrap = 'CWrap',
}

export enum InstanceHeaderNodes {
    CWrap = 'CWrap',
}

export enum ControlsPanelNodes {
    CWrap = 'CWrap',
}

export enum FormFooterNodes {
    CWrap = 'CWrap',
}

export enum FormHeaderNodes {
    CWrap = 'CWrap',
}

export enum FormPropertyRowNodes {
    CWrap = 'CWrap',
    InputArea = 'InputArea',
    InputNotice = 'InputNotice',
}

export enum AddInstPageTplNodes {
    RowsWrap = 'RowsWrap',
}

export enum YesNoValueNodes {
    CWrap = 'CWrap',
}

export enum CounterNodes {
    CWrap = 'CWrap',
    CountBtn = 'CountBtn',
    Input = 'Input',
}

export enum DirectionsNodes {
    CWrap = 'CWrap',
    Title = 'Title',
    ShortDescription = 'ShortDescription',
    SubTitle = 'SubTitle',
    Header = 'Header',
    Folder = 'Folder',
    DirectionList = 'DirectionList',
    DirectionListItem = 'DirectionListItem',
    DirectionListItemType = 'DirectionListItemType',
    DirectionListItemContent = 'DirectionListItemContent',
    DirectionListItemName = 'DirectionListItemName',
}

export enum FilterPanelNodes {
    CWrap = 'CWrap',
}

export enum SortNodes {
    CWrap = 'CWrap',
}

export enum FeedbacksNodes {
    Header = 'Header',
    Title = 'Title',
    ShortDescription = 'ShortDescription',
}

export enum FeedbackNodes {
    CWrap = 'CWrap',
}

export enum RegisterFormNodes {
    CWrap = 'CWrap',
    DataInputWrap = 'DataInputWrap',
    DataInputHint = 'DataInputHint',
    TwoInputsWrap = 'TwoInputsWrap',
    Agreement = 'Agreement',
    AgreementLabel = 'AgreementLabel',
    GoogleDescription = 'GoogleDescription',
}

export enum DirectionNodes {
    CWrap = 'CWrap',
}

export enum InternshipRoadmapNodes {
    CWrap = 'CWrap',
}

export enum MiniCardListNodes {
    CWrap = 'CWrap',
}

export enum FileNameTagNodes {
    CWrap = 'CWrap',
    Caption = 'Caption',
    DeleteBtn = 'DeleteBtn',
}

export enum PictureUploaderNodes {
    CWrap = 'CWrap',
}

export enum PictureUploadResultNodes {
    CWrap = 'CWrap',
}

export enum UploadedImgPreviewNodes {
    CWrap = 'CWrap',
    Button = 'Button',
    BtnPanel = 'BtnPanel',
}

export enum FileUploadResultNodes {
    CWrap = 'CWrap',
    FileName = 'FileName',
    Button = 'Button',
}

export enum UploadInputNodes {
    CWrap = 'CWrap',
    Input = 'Input',
    Placeholder = 'Placeholder',
    BtnPanel = 'BtnPanel',
    Button = 'Button',
}

export enum SectionHeaderNodes {
    CWrap = 'CWrap',
    Title = 'Title',
    Description = 'Description',
}

export enum DirectionDescriptionNodes {
    CWrap = 'CWrap',
    Title = 'Title',
    Description = 'Description',
    Link = 'Link',
}

export enum DirectionTeamsNodes {
    CWrap = 'CWrap',
    Title = 'Title',
    Content = 'Content',
    ContentTitle = 'ContentTitle',
}

export enum RoadMapNodes {
    CWrap = 'CWrap',
    Title = 'Title',
    List = 'List',
    ListItem = 'ListItem',
    ListItemPeriod = 'ListItemPeriod',
    ListItemLines = 'ListItemLines',
    ListItemLinesUp = 'ListItemLinesUp',
    ListItemLinesCenter = 'ListItemLinesCenter',
    ListItemLinesDown = 'ListItemLinesDown',
    ListItemDescription = 'ListItemDescription',
    ListItemDescriptionTitle = 'ListItemDescriptionTitle',
    ListItemDescriptionComment = 'ListItemDescriptionComment',
}
