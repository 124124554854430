export const theme = {
    MainArea: {
        position: 'relative',
        zIndex: 0,
        width: '100%',
        padding: '64px 0 0 0',
        minHeight: 'calc(100vh - 80px)',
    },
};

export default theme;
