import styled from '@emotion/styled';

export const CWrap = styled.div`
    display: flex;
    flex-direction: column;

    padding: var(--PADDING__CONTAINER);
`;

export const H2 = styled.h2``;

export const H3 = styled.h3``;

export const H4 = styled.h4``;

export const H5 = styled.h5``;

export const H6 = styled.h6``;

export const P = styled.p``;

export const textBlocks = { H2, H3, H4, H5, H6, P };
