import { BtnModes, BtnSchemes } from 'src/types';
import { TRANSITION__DEFAULT } from './animation';

export const getButtonProps = (modes: BtnModes, schemes: BtnSchemes) => {
    const colors = schemes.color[modes.color];
    const sizes = schemes.size[modes.size];

    return {
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box',
        color: colors.color,
        background: colors.bg,
        border: `${sizes.borderWidth} solid ${colors.border}`,
        boxShadow: modes.withShadow ? colors.boxShadow : 'none',
        padding: sizes.padding,
        fontSize: sizes.fontSize,
        fontWeight: 600,
        lineHeight: sizes.lineHeight,
        borderRadius: '8px',
        gap:
            modes.icon === 'BEFORE'
                ? sizes.gap4Before
                : modes.icon === 'AFTER'
                ? sizes.gap4After
                : 'initial',
        ...TRANSITION__DEFAULT,

        '&:hover': {
            color: colors.colorOnHover,
            background: colors.bgOnHover,
            borderColor: colors.borderOnHover,
            boxShadow: modes.withShadow ? colors.boxShadowOnHover : 'none',
        },

        '&.isDisabled': {
            color: colors.colorDisabled,
            background: colors.bgDisabled,
            borderColor: colors.borderDisabled,
            pointerEvents: 'none',
            cursor: 'not-allowed',
        },
    };
};

export const ROUND_BTN = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    ...TRANSITION__DEFAULT,
};
