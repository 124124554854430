import {
    BTN_COLOR_MODES,
    BTN_SIZE_MODES,
    getButtonProps,
    BtnColorModeName,
    BtnSizeModeName,
} from '../../../../../';

const fillFormBtnProps = getButtonProps(
    {
        color: BtnColorModeName.GREEN,
        size: BtnSizeModeName.DEFAULT_48,
        icon: 'ALONG',
        withShadow: false,
    },
    {
        color: BTN_COLOR_MODES,
        size: BTN_SIZE_MODES,
    }
);

export const theme = {
    CWrap: {
        display: 'flex',
        position: 'relative',
        boxSizing: 'border-box',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        fontSize: '14px',
        fontWeight: 400,
        cursor: 'pointer',

        a: {
            color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
            fontWeight: 400,
        },

        '&.isNavigateBtn': {
            height: '64px',
            border: 'none',

            '&:after': {
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: '3px',
                background: 'transparent',
                transition: 'all 0.3s',
            },
            '&:hover': {
                '&:after': {
                    background: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
                },
            },
        },

        '&.isFillFormBtn': {
            ...fillFormBtnProps,
            height: 'max-content',
        },
    },
};

export default theme;
