import styled from '@emotion/styled';
import { getCSSObj } from '../../utils';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const MessageWrap = styled.div`
    ${getCSSObj}
`;

export const BtnWrap = styled.div`
    ${getCSSObj}
`;

export const ContentWrap = styled.div`
    ${getCSSObj}
`;
