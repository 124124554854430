import { HeaderLangs } from 'uikit-lib';
import { DataModelAction, ModalState } from 'src/types';

export const handleSetModalHeaderLangs = (
    setModalDataFn: DataModelAction<ModalState>,
    headerLangs: HeaderLangs
): void => {
    setModalDataFn({
        headerLangs,
    });
};

export default handleSetModalHeaderLangs;
