import { LAYOUTING__CONTENT_AREA, TYPOGRAPHY__T3 } from '../../../../..';

export const theme = {
    CWrap: {
        position: 'relative',
        zIndex: 18,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'var(--COLORS__GRAYSCALE____2)',
    },
    MainArea: {
        ...LAYOUTING__CONTENT_AREA,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80px',

        '@media screen and (max-width: 1024px)': {
            padding: '29px 0',
            flexDirection: 'column-reverse',
            height: 'auto',
        },
    },
    Column: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',

        '@media screen and (max-width: 1024px)': {
            flexDirection: 'column',
            gap: '25px',
        },
    },
    Copyright: {
        ...TYPOGRAPHY__T3,
        color: 'rgba(255, 255, 255, 0.60)',
        margin: '0 20px',

        '@media screen and (max-width: 1024px)': {
            display: 'none',
        },
    },
};

export default theme;
