export const theme = {
    CWrap: {
        fontSize: '12px',
        fontWeight: 'var(--TYPOGRAPHY__CORE____FONT_WEIGHT__IS_MEDIUM)',
        color: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
        border: '1px solid transparent',
        transition: 'all 0.3s ease',
        position: 'relative',

        '&:after': {
            content: '" "',
            position: 'absolute',
            right: '0',
            bottom: '0',
            height: '0.5px',
            width: 'calc(100% - 12px)',
            background: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
            transition: 'all 0.3s ease',
        },

        '&:before': {
            content: '"•"',
            paddingRight: '7px',
            color: '#999999',
        },

        '@media screen and (max-width: 1024px)': {
            '&:before, &:after': {
                display: 'none',
            },
        },

        '&:hover': {
            color: 'var(--COLORS__SECONDARY_PALETTE____GREEN_NEON)',

            '&:after': {
                height: '2px',
                background: 'var(--COLORS__SECONDARY_PALETTE____GREEN_NEON)',
            },
        },
    },
};

export default theme;
