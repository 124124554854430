import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const Header = styled.div`
    ${getCSSObj}
`;

export const Folder = styled.div`
    ${getCSSObj}
`;

export const Title = styled.h2`
    ${getCSSObj}
`;

export const SubTitle = styled.h3`
    ${getCSSObj}
`;

export const ShortDescription = styled.p`
    ${getCSSObj}
`;

export const DirectionList = styled.div`
    ${getCSSObj}
`;

export const DirectionListItem = styled.div`
    ${getCSSObj}
`;

export const DirectionListItemType = styled.h2`
    ${getCSSObj}
`;

export const DirectionListItemContent = styled.div`
    ${getCSSObj}
`;

export const DirectionListItemName = styled.h2`
    ${getCSSObj}
`;
