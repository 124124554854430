import React, { useState } from 'react';
import {
    BaseFCProps,
    Button,
    DropdownNodes,
    withBaseProps,
    MenuBtnProps,
    CurIcon,
    IconParams,
} from '../../';

import {
    CWrap,
    ToggleBtn,
    ToggleBtnIcon,
    ToggleCaption,
    ToggleBtnArrow,
    BtnsWrap,
} from './Dropdown.styles';

export interface Props extends BaseFCProps<DropdownNodes> {
    id: string;
    type: string;
    toggleBtnCaption?: string;
    toggleBtnIconParams?: IconParams;
    items: MenuBtnProps[];
}

export const Dropdown: React.FC<Props> = (props): JSX.Element => {
    const {
        id,
        toggleBtnCaption,
        toggleBtnIconParams,
        items,
        theme,
        testIds,
        tokens,
        tokens: { groupToken = '' },
    } = props;

    const [showMenu, setShowMenu] = useState<boolean>(false);

    return (
        <CWrap
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={`${testIds.CWrap}__IS_${id}`}>
            <ToggleBtn
                theme={theme.ToggleBtn}
                data-testid={testIds.ToggleBtn}
                className={`${showMenu ? 'isToggledOn' : 'isToggleOff'}`}
                onClick={() => {
                    setShowMenu(!showMenu);
                }}>
                {!!toggleBtnIconParams && (
                    <ToggleBtnIcon
                        theme={theme.ToggleBtnIcon}
                        data-testid={testIds.ToggleBtnIcon}>
                        <CurIcon
                            iconId={toggleBtnIconParams.iconId}
                            sizeId={toggleBtnIconParams.sizeId}
                        />
                    </ToggleBtnIcon>
                )}
                {!!toggleBtnCaption && (
                    <ToggleCaption
                        theme={theme.ToggleCaption}
                        data-testid={testIds.ToggleCaption}>
                        {toggleBtnCaption}
                    </ToggleCaption>
                )}
                <ToggleBtnArrow
                    theme={theme.ToggleBtnArrow}
                    data-testid={testIds.ToggleBtnArrow}
                    className={`${showMenu ? 'isToggledOn' : 'isToggleOff'}`}>
                    <CurIcon iconId={'MINI_ARROW'} sizeId={'REGULAR'} />
                </ToggleBtnArrow>
            </ToggleBtn>
            <BtnsWrap
                theme={theme.BtnsWrap}
                data-testid={`${testIds.BtnsWrap}${
                    showMenu ? '__IS_VISIBLE' : ''
                }`}
                className={showMenu ? 'isVisible' : ''}>
                {items.map((itemBtn) => {
                    const {
                        key,
                        type,
                        caption,
                        handleOnClick,
                        iconParams,
                        actionToken,
                    } = itemBtn;

                    return (
                        <Button
                            key={window.crypto.randomUUID()}
                            type={type}
                            caption={caption}
                            iconParams={iconParams}
                            handleOnClick={handleOnClick}
                            tokens={{
                                hlaToken: `${
                                    tokens?.hlaToken
                                        ? `${tokens.hlaToken}__`
                                        : ''
                                }DROPDOWN_BTN__IS_${key.toUpperCase()}`,
                                groupToken: `${
                                    tokens?.groupToken
                                        ? `${tokens.groupToken}__`
                                        : ''
                                }DROPDOWN_BTN`,
                                actionToken,
                            }}
                        />
                    );
                })}
            </BtnsWrap>
        </CWrap>
    );
};

export default withBaseProps<DropdownNodes, Props>(Dropdown, {
    name: 'Dropdown',
    code: 'DROPDOWN',
    nodeNames: Object.keys(DropdownNodes),
});
