import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const CWrap = styled.button`
    ${getCSSObj}
`;

export const LinesWrap = styled.div`
    ${getCSSObj}
`;

export const Line = styled.div`
    ${getCSSObj}
`;
