import styled from '@emotion/styled';
import { getCSSObj } from '../../../../utils';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const ContentWrap = styled.div`
    ${getCSSObj}
`;

export const Header = styled.div`
    ${getCSSObj}
`;
