import { CSSObject } from '@emotion/react';

/* eslint-disable @typescript-eslint/naming-convention */
export const TYPOGRAPHY__BH1 = {
    fontSize: '243px',
    lineHeight: '243px',
    fontWeight: 500,
};

export const TYPOGRAPHY__BH2 = {
    fontSize: '181px',
    lineHeight: '181px',
    fontWeight: 500,
};

export const TYPOGRAPHY__BH3 = {
    fontSize: '162px',
    lineHeight: '162px',
    fontWeight: 500,
};

export const TYPOGRAPHY__BH4 = {
    fontSize: '121px',
    lineHeight: '121px',
    fontWeight: 500,
};

export const TYPOGRAPHY__BH5 = {
    fontSize: '108px',
    lineHeight: '108px',
    fontWeight: 500,
};

export const TYPOGRAPHY__H1 = {
    fontSize: '81px',
    lineHeight: '81px',
    fontWeight: 500,
};

export const TYPOGRAPHY__H2 = {
    fontSize: '72px',
    lineHeight: '72px',
    fontWeight: 500,
};

export const TYPOGRAPHY__H3 = {
    fontSize: '54px',
    lineHeight: '54px',
    fontWeight: 500,
};

export const TYPOGRAPHY__H4 = {
    fontSize: '48px',
    lineHeight: '48px',
    fontWeight: 500,
};

export const TYPOGRAPHY__H5 = {
    fontSize: '42px',
    lineHeight: '42px',
    fontWeight: 500,
};

export const TYPOGRAPHY__S1 = {
    fontSize: '32px',
    lineHeight: '36px',
    fontWeight: 500,
};
/**
 * Paragraph styles are from here
 * It's because paddings are
 */
export const TYPOGRAPHY__S2 = {
    fontSize: '24px',
    lineHeight: '27px',
    fontWeight: 500,
    padding: '12px 0',
};

export const TYPOGRAPHY__S3 = {
    fontSize: '21px',
    lineHeight: '24px',
    fontWeight: 500,
    padding: '12px 0',
};

export const TYPOGRAPHY__S4 = {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 400,
    padding: '12px 0',
};

export const TYPOGRAPHY__T1 = {
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 400,
    padding: '12px 0',
};

export const TYPOGRAPHY__T2 = {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    padding: '8px 0',
};

export const TYPOGRAPHY__T3 = {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
    padding: '8px 0',
};

export const TYPOGRAPHY__T4 = {
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 400,
    padding: '6px 0',
};

export const TYPOGRAPHY__D1 = {
    fontSize: '8px',
    lineHeight: '10px',
    fontWeight: 400,
    padding: '6px 0',
};

export const TYPOGRAPHY__D2 = {
    fontSize: '7px',
    lineHeight: '8px',
    fontWeight: 400,
    padding: '6px 0',
};

export const TYPOGRAPHY__D3 = {
    fontSize: '6px',
    lineHeight: '7px',
    fontWeight: 400,
    padding: '6px 0',
};

export const TYPOGRAPHY__TEXT_LINK = {
    fontWeight: 600,
    color: 'var(--COLORS__PRIMARY_PALETTE____GREEN_TEXT)',
    '&:hover': {
        textDecoration: 'underline',
    },
};

export const getLineClamp = (lines: number): CSSObject => ({
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: `${lines}`,
    whiteSpace: 'pre-wrap',
});

export const SECTION_TITLE = {
    ...TYPOGRAPHY__H4,
};
