import { LAYOUTING__CONTENT_AREA } from '../../../../../';

export const theme = {
    CWrap: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 20,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        boxShadow: '-3.7375px 3.7375px 22.425px rgba(35, 209, 174, 0.3)',
    },
    MainArea: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...LAYOUTING__CONTENT_AREA,
        minHeight: '64px',
    },
};

export default theme;
