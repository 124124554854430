import React, { useState } from 'react';
import {
    CWrap,
    Title,
    Content,
    RowContainer,
    RowContent,
    RowTitle,
    Question,
    BackgroundImage,
    RowContentItem,
    Answer,
    AnswerIcon,
    Wrap,
} from './FAQ.styles';
import {
    BaseFCProps,
    Dictionary,
    FaqItem,
    FaqRow,
    PubFaqNodes,
} from '../../types';
import { withBaseProps } from '../../hocs';
import { Section } from '../Section';
import { Button } from '../Button';
import { EMAIL_HELP } from '../../constants';

export interface Props extends BaseFCProps<PubFaqNodes> {
    title: string;
    questions: FaqItem[];
    rows: Dictionary<FaqRow>;
    backgroundImage?: string;
    iconBackgroundImage?: string;
    buttonCaption?: string;
    children?: React.ReactNode;
}

export const FAQ: React.FC<Props> = (props): JSX.Element => {
    const {
        theme,
        testIds,
        tokens: { groupToken, hlaToken, actionToken },
        title,
        questions,
        rows,
        backgroundImage,
        iconBackgroundImage,
        buttonCaption,
        children,
    } = props;

    const [activeQuestion, setActiveQuestion] = useState<FaqItem>({
        ...questions[0],
    });

    return (
        <CWrap
            id='FAQ'
            data-at-selector={groupToken}
            theme={theme.CWrap}
            data-testid={testIds.CWrap}>
            {children && children}

            {backgroundImage && (
                <BackgroundImage
                    theme={theme.BackgroundImage}
                    data-testid={testIds.BackgroundImage}
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                />
            )}
            <Section type={'BASIC'} tokens={{ hlaToken }}>
                <Content theme={theme.Content} data-testid={testIds.Content}>
                    <Title theme={theme.Title} data-testid={testIds.Title}>
                        {title}
                    </Title>
                    <RowContainer
                        theme={theme.RowContainer}
                        data-testid={testIds.RowContainer}>
                        <RowContentItem
                            theme={theme.RowContentItem}
                            data-testid={testIds.RowContentItem}>
                            {Object.values(rows).map((row: FaqRow) => (
                                <RowContent
                                    key={row.key}
                                    theme={theme.RowContent}
                                    data-testid={`${testIds.RowContent}__${
                                        rows[row.key] as string
                                    }`}>
                                    {row.title && (
                                        <RowTitle
                                            theme={theme.RowTitle}
                                            data-testid={testIds.RowTitle}>
                                            {row.title}
                                        </RowTitle>
                                    )}

                                    {questions
                                        .filter(
                                            (value: FaqItem) =>
                                                value.subject.toLowerCase() ===
                                                    row.key.toLowerCase() &&
                                                value.visible
                                        )
                                        .map((item: FaqItem) => (
                                            <Wrap key={item.id}>
                                                <Question
                                                    className={
                                                        item.id ===
                                                        activeQuestion?.id
                                                            ? 'active'
                                                            : ''
                                                    }
                                                    theme={theme.Question}
                                                    data-testid={
                                                        testIds.Question
                                                    }
                                                    onClick={() =>
                                                        setActiveQuestion(item)
                                                    }>
                                                    {item.question}
                                                </Question>

                                                {activeQuestion.id ===
                                                    item.id && (
                                                    <Answer
                                                        className='isMobile'
                                                        theme={theme.Answer}
                                                        data-testid={
                                                            theme.Answer
                                                        }>
                                                        {activeQuestion.answer}
                                                    </Answer>
                                                )}
                                            </Wrap>
                                        ))}
                                </RowContent>
                            ))}
                        </RowContentItem>

                        <RowContentItem
                            className='isDesktop'
                            theme={theme.RowContentItem}
                            data-testid={testIds.RowContentItem}>
                            {activeQuestion && (
                                <>
                                    <Question
                                        className='unselectable'
                                        theme={theme.Question}
                                        data-testid={testIds.Question}>
                                        {activeQuestion.question}
                                    </Question>

                                    <Answer
                                        theme={theme.Answer}
                                        data-testid={theme.Answer}>
                                        {activeQuestion.answer}
                                    </Answer>

                                    {iconBackgroundImage && (
                                        <AnswerIcon
                                            theme={theme.AnswerIcon}
                                            data-testid={testIds.AnswerIcon}
                                            style={{
                                                backgroundImage: `url(${iconBackgroundImage})`,
                                            }}
                                        />
                                    )}

                                    {buttonCaption && (
                                        <Button
                                            type={'GRADIENT_NEON'}
                                            caption={buttonCaption}
                                            modifierClass={'isFullWidth'}
                                            tokens={{
                                                groupToken: `${
                                                    groupToken as string
                                                }__FAQ_BTN`,
                                                hlaToken,
                                                actionToken: `${
                                                    actionToken as string
                                                }__ASK_QUESTION`,
                                            }}
                                            handleOnClick={() =>
                                                window.open(EMAIL_HELP)
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </RowContentItem>

                        {buttonCaption && (
                            <Button
                                type={'GRADIENT_NEON'}
                                caption={buttonCaption}
                                modifierClass={'isFullWidth isMobileButton'}
                                tokens={{
                                    groupToken: `${
                                        groupToken as string
                                    }__FAQ_BTN`,
                                    hlaToken,
                                    actionToken: `${
                                        actionToken as string
                                    }__ASK_QUESTION`,
                                }}
                                handleOnClick={() => window.open(EMAIL_HELP)}
                            />
                        )}
                    </RowContainer>
                </Content>
            </Section>
        </CWrap>
    );
};

export default withBaseProps(FAQ, {
    name: 'FAQ',
    code: 'FAQ',
    nodeNames: Object.keys(PubFaqNodes),
});
