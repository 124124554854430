export const theme = {
    Title: {
        paddingBottom: '10px',
    },
    SubTitle: {
        padding: '20px 0px 10px 0px',
        textTransform: 'uppercase',
    },
    Chapter: {
        padding: '10px 0px',
    },
    CWrap: {
        padding: '62px 0',
    },
    Table: {
        borderCollapse: 'collapse',
    },
    TableHead: {},
    TableBody: {},
    TableRow: {},
    TableData: {
        padding: '5px',
        border: '1px solid var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
    },
    TableHeadData: {
        padding: '5px',
        border: '1px solid var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
        verticalAlign: 'middle',
    },
    NumberList: {
        listStyleType: 'auto',
        paddingLeft: '20px',
    },
    PointList: {
        listStyleType: 'disc',
        paddingLeft: '20px',
    },
    ListItem: {},
    HorizontalLine: {
        backgroundColor: 'var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
        marginTop: '30px',
        height: '1px',
        width: '50%',
    },
    Anchor: {
        color: 'var(--COLORS__PRIMARY_PALETTE____GREEN_TEXT)',
    },
    SupText: {
        top: '-0.5em',
        padding: '0px 3px',
    },
};

export default theme;
