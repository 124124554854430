export enum StrValueName {
    firstName = 'firstName',
    lastName = 'lastName',
    phone = 'phone',
    telegram = 'telegram',
    city = 'city',
    institution = 'institution',
    faculty = 'faculty',
    startEduYear = 'startEduYear',
    endEduYear = 'endEduYear',
    aboutSelf = 'aboutSelf',
    what2Know = 'what2Know',
    email = 'email',
}

export enum StrArrValueName {
    directions = 'directions',
}

export enum BoolValueName {
    agreement = 'agreement',
}

export enum ValueName2Validate {
    cityIsRequired = 'cityIsRequired',
    descriptionYourSelfIsRequired = '',
    educationIsRequired = '',
    emailIsRequired = '',
    interestDirectionIsRequired = '',
    knowAboutEventCityIsRequired = '',
    phoneIsRequired = '',
    telegramNickNameIsRequired = '',
    whatYouWantToKnowAboutInternshipIsRequired = '',
}

export enum FaqType {
    Selection = 'Selection',
    Internship = 'Internship',
}
