import React, { useEffect } from 'react';
import FocusLock from 'react-focus-lock';
import { getFCTheme, getDataTestId, checkProp, ModalConfig } from 'uikit-lib';
import { useAction, initModalState } from 'src/containers/LocalDataStore';
import { ContentGen, SystemMsgGen, Header, Content } from './features';
import { SplashBg, ModalWrap } from './Modal.styles';

import { useStore } from '..';
import customFCsDict from './customFCsDict';
import configs from './config';

export const Modal: React.FC = (): JSX.Element => {
    const {
        Modal: {
            type,
            isVisible,
            descriptor: {
                moduleName,
                moduleMode,
                actionType,
                actionName,
                actionMode,
            },
        },
    } = useStore();

    const { setModalData } = useAction(['setModalData']);
    const handleOnClose = () => {
        if (setModalData) setModalData({ ...initModalState });
    };

    const getWrapperClassName = (): string => {
        switch (actionMode) {
            case 'IS_VIDEO':
                return 'isVideo';
            case 'IS_INTRO':
                return 'isRegister';
            default:
                return '';
        }
    };

    const theme = getFCTheme('Modal');

    const hlaToken = `${checkProp(moduleName, !moduleMode)}${checkProp(
        moduleMode,
        true
    )}`;
    const actionToken = `${checkProp(actionType, false)}${checkProp(
        actionName,
        !actionMode
    )}${checkProp(actionMode, true)}`;
    const wrapperClassName = getWrapperClassName();
    const token = `${hlaToken}____${actionToken}`;

    const curFCId = 'ABOUT_INTRO';
    const testIds = {
        SplashBg: getDataTestId(`${curFCId}__CWRAP`, hlaToken, actionToken),
        ModalWrap: getDataTestId(`${curFCId}__CWRAP`, hlaToken, actionToken),
        CloseBtn: getDataTestId(`${curFCId}__CWRAP`, hlaToken, actionToken),
    };

    const config: ModalConfig = configs[token];

    let TargetFC = null;
    if (type === 'CUSTOM_FC') {
        if (!customFCsDict[token]) {
            throw new Error(
                `There are no ${token} record in customFCsDict. Consider to add one.`
            );
        }

        TargetFC = customFCsDict[token];
    }

    useEffect(() => {
        const body = document.querySelector('body');
        const modalClassName = 'isModalOpened';

        if (isVisible) {
            body?.classList.add(modalClassName);
        } else {
            body?.classList.remove(modalClassName);
        }
    }, [isVisible]);

    return (
        <FocusLock>
            <SplashBg
                theme={theme.SplashBg}
                data-testid={testIds.SplashBg}
                className={isVisible ? 'isVisible' : ''}
                onMouseDown={handleOnClose}>
                <ModalWrap
                    className={wrapperClassName}
                    theme={theme.ModalWrap}
                    data-testid={testIds.ModalWrap}
                    role={'dialog'}
                    onMouseDown={(e: { stopPropagation: () => void }) => {
                        e.stopPropagation();
                    }}>
                    {config && (
                        <>
                            <Header
                                handleOnClose={handleOnClose}
                                config={config?.header}
                                tokens={{
                                    hlaToken,
                                    groupToken: 'MODAL_HEADER',
                                }}
                            />
                            <Content
                                tokens={{
                                    hlaToken,
                                    groupToken: 'MODAL_CONTENT',
                                }}>
                                {type === 'ADDITIONAL_INFO' && (
                                    <ContentGen seqKey={token} />
                                )}
                                {type === 'CUSTOM_FC' && !!TargetFC && (
                                    <TargetFC tokens={{}} />
                                )}
                                {type === 'SYSTEM' && (
                                    <SystemMsgGen seqKey={token} />
                                )}
                                {type === 'IDLE' && (
                                    <>Oups!!! Something went wrong ...</>
                                )}
                            </Content>
                        </>
                    )}
                </ModalWrap>
            </SplashBg>
        </FocusLock>
    );
};

export default Modal;
