import { TYPOGRAPHY__T1, TYPOGRAPHY__S4, LIST_STYLING } from 'uikit-lib';

export const theme = {
    CWrap: {},
    Title: {
        ...TYPOGRAPHY__T1,
    },
    Content: {
        ...TYPOGRAPHY__T1,
        ...LIST_STYLING,
    },
    ContentTitle: {
        ...TYPOGRAPHY__S4,
        fontWeight: 'var(--TYPOGRAPHY__CORE____FONT_WEIGHT__IS_SEMI_BOLD)',
    },
};

export default theme;
