import React from 'react';
import { CSSObject } from '@emotion/react';
import { getDataTestId } from '../../../../utils';
import { CurIcon, FCTokens } from '../../../..';
import { CWrap } from './Arrow.styles';

export interface Props {
    type: 'LEFT' | 'RIGHT' | 'UP' | 'DOWN';
    isDisabled?: boolean;
    handleOnClick: () => void;
    tokens: FCTokens;
    theme: CSSObject;
}

export const Arrow: React.FC<Props> = (props): JSX.Element => {
    const {
        type,
        handleOnClick,
        isDisabled = false,
        tokens: { groupToken = '', hlaToken = '' },
        theme,
    } = props;

    const curFCId = 'ARROW';
    const testIds = {
        CWrap: getDataTestId(`${curFCId}__CWRAP__IS_${type}`, hlaToken, ''),
    };

    const modifiers = {
        LEFT: 'isLeft',
        RIGHT: 'isRight',
        UP: 'isUp',
        DOWN: 'isDown',
    };

    return (
        <CWrap
            tabIndex={0}
            onClick={handleOnClick}
            theme={theme}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}
            className={`${modifiers[type]}`}
            disabled={isDisabled}>
            <CurIcon sizeId={'INCREASED'} iconId={'MOVE_TO_ARROW'} />
        </CWrap>
    );
};

export default Arrow;
