import { DEFAULT, HEADER, FOOTER, MOBILE_FOOTER, MOBILE_HEADER } from './types';

export const theme = {
    DEFAULT,
    HEADER,
    FOOTER,
    MOBILE_FOOTER,
    MOBILE_HEADER,
};

export default theme;
