export const theme = {
    CWrap: {},
    ProjectListContainer: {
        padding: '62px 0',
        background:
            'radial-gradient(116.73% 198.67% at -22.78% -23.83%, #D5CCF4 19.67%, #FFFFFF 54.48%, #EAFEDA 100%)',
    },
    Title: {
        fontSize: 'var(--TYPOGRAPHY__H1____FONT_SIZE)',
        lineHeight: 'var(--TYPOGRAPHY__H1____FONT_SIZE)',
        fontWeight: 'var(--TYPOGRAPHY__CORE____FONT_WEIGHT__IS_NORMAL)',

        '@media screen and (max-width: 640px)': {
            fontWeight: 'var(--TYPOGRAPHY__CORE____FONT_WEIGHT__IS_SEMI_BOLD)',
            fontSize: 'var(--TYPOGRAPHY__H3____FONT_SIZE)',
            lineHeight: '60px',
        },
    },
    ProjectListItems: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'stretch',
    },
    ProjectListItem: {
        display: 'flex',
        background: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        width: 'calc(50% - 15px)',
        minHeight: '200px',
        marginTop: '30px',
        transition: 'all 0.3s ease',

        '@media screen and (max-width: 768px)': {
            width: '100%',
        },

        '&:nth-of-type(3n + 1)': {
            marginTop: '100px',
            width: '100%',
            padding: '50px',
            minHeight: '360px',

            '@media screen and (max-width: 640px)': {
                padding: '20px',
                display: 'block',
            },
        },

        '&:first-of-type': {
            padding: '50px',
            marginTop: '50px',

            '@media screen and (max-width: 640px)': {
                padding: '20px',
            },

            '@media screen and (max-width: 1440px)': {
                marginTop: '70px',
            },
        },

        '@media screen and (min-width: 1440px)': {
            '&:hover': {
                transform: 'translate(-10px, -10px)',
            },
        },
    },
    AllProjectsBtnContainer: {
        marginTop: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export default theme;
