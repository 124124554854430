import React from 'react';
import { getDataTestId } from '../../../../utils';
import { FCTokens } from '../../../..';
import { CWrap } from './ItemWrapper.styles';
import { CSSObject } from '@emotion/react';

export interface Props {
    id: number;
    width: number;
    itemPaddings: [number, number, number, number];
    cssClasses?: string;
    handleOnClick?: (id: number) => void;
    tokens: FCTokens;
    theme: CSSObject;
    children: React.ReactNode;
}

export const ItemWrapper: React.FC<Props> = (props): JSX.Element => {
    const {
        id,
        width,
        itemPaddings,
        cssClasses = '',
        handleOnClick,
        tokens: { groupToken = '', hlaToken = '' },
        theme,
        children,
    } = props;

    const curFCId = 'TRACK';
    const testIds = {
        CWrap: getDataTestId(`${curFCId}_CWRAP`, hlaToken, ''),
    };

    return (
        <CWrap
            theme={theme}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}
            className={`isCard ${cssClasses}`}
            onClick={() => {
                if (handleOnClick) handleOnClick(id);
            }}
            elemWidth={width}
            itemPaddings={itemPaddings}>
            {children}
        </CWrap>
    );
};

export default ItemWrapper;
