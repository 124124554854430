export enum MinicardElemName {
    Title = 'Title',
    SubTitle = 'SubTitle',
    ShortDescription = 'ShortDescription',
    FullDescription = 'FullDescription',
    Image = 'Image',
    DateTime = 'DateTime',
    Btn = 'Btn',
    NavBtn = 'NavBtn',
    ExternalLink = 'ExternalLink',
    CWrap = 'CWrap',
}

import React from 'react';
import { CurIcon, getFCTheme, MiniCardNode } from '../../';
import { CWrap, minicardElements } from './MiniCard.styles';

export interface Props {
    data: MiniCardNode[];
    type: string;
    groupToken?: string;
    hlaToken?: string;
    actionToken?: string;
    classModifiers?: {
        [key in MinicardElemName]?: string;
    };
}

export const MiniCard: React.FC<Props> = (props): JSX.Element => {
    const { data, type, classModifiers = {}, groupToken = '' } = props;
    const theme = getFCTheme('MiniCard', type);

    return (
        <CWrap
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={`MINICARD__CWRAP__${type}`}
            className={classModifiers.CWrap || ''}>
            {data.map((item: MiniCardNode, index) => {
                const {
                    tag,
                    text,
                    href = undefined,
                    handleOnClick = undefined,
                    imgUrl = '',
                    iconPosition = undefined,
                    iconParams = undefined,
                } = item;
                const TargetNode = minicardElements[tag];
                const nodeTheme = theme[tag];
                const targetClass =
                    classModifiers && classModifiers[tag]
                        ? classModifiers[tag]
                        : '';
                const hasIcon = !!iconPosition && !!iconParams;

                switch (tag) {
                    case 'Title':
                    case 'SubTitle':
                    case 'ShortDescription':
                    case 'FullDescription':
                    case 'DateTime':
                        return (
                            !!text && (
                                <TargetNode
                                    key={index}
                                    data-testid={`MINICARD__${tag}__${type}`}
                                    theme={nodeTheme}
                                    className={targetClass}>
                                    {text}
                                </TargetNode>
                            )
                        );
                    case 'NavBtn':
                        return (
                            !!text &&
                            !!handleOnClick && (
                                <TargetNode
                                    key={index}
                                    onClick={handleOnClick}
                                    data-testid={`MINICARD__${tag}__${type}`}
                                    theme={nodeTheme}
                                    className={targetClass}>
                                    {text}
                                </TargetNode>
                            )
                        );
                    case 'Image':
                        return (
                            !!imgUrl && (
                                <TargetNode
                                    key={index}
                                    data-testid={`MINICARD__${tag}__${type}`}
                                    theme={nodeTheme}
                                    style={{
                                        backgroundImage: `url('${imgUrl}')`,
                                    }}
                                    className={targetClass}
                                />
                            )
                        );
                    case 'ExternalLink':
                        return (
                            !!text &&
                            !!href && (
                                <TargetNode
                                    key={index}
                                    href={href}
                                    target='_blank'
                                    data-testid={`MINICARD__${tag}__${type}`}
                                    theme={nodeTheme}
                                    className={targetClass}>
                                    {text}
                                </TargetNode>
                            )
                        );
                    case 'Btn':
                        return (
                            !!text &&
                            !!handleOnClick && (
                                <TargetNode
                                    key={index}
                                    onClick={handleOnClick}
                                    data-testid={`MINICARD__${tag}__${type}`}
                                    theme={nodeTheme}
                                    className={targetClass}>
                                    {hasIcon &&
                                        iconPosition === 'BEFORE_TEXT' && (
                                            <CurIcon
                                                sizeId={iconParams.sizeId}
                                                iconId={iconParams.iconId}
                                            />
                                        )}
                                    {text}
                                    {hasIcon &&
                                        iconPosition === 'AFTER_TEXT' && (
                                            <CurIcon
                                                sizeId={iconParams.sizeId}
                                                iconId={iconParams.iconId}
                                            />
                                        )}
                                </TargetNode>
                            )
                        );
                    default:
                        return <></>;
                }
            })}
        </CWrap>
    );
};

export default MiniCard;
