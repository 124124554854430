import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { CWrap } from './HashLink.styles';
import { withBaseProps, BaseFCProps } from '../..';
import { HashLinkNodes } from '../../types';

export interface Props extends BaseFCProps<HashLinkNodes> {
    to: string;
    caption: string;
    smooth?: boolean;
    handleOnClick: () => void;
    customStylesClass?: string;
}

export const HashLink: React.FC<Props> = (props): JSX.Element => {
    const {
        theme,
        testIds,
        to,
        caption,
        smooth,
        handleOnClick,
        customStylesClass = '',
    } = props;

    return (
        <CWrap
            className={customStylesClass}
            theme={theme.CWrap}
            data-testid={testIds.CWrap}>
            <Link
                to={to}
                onClick={handleOnClick}
                scroll={(element: HTMLElement) =>
                    element.scrollIntoView({
                        behavior: smooth ? 'smooth' : 'auto',
                        block: 'start',
                    })
                }>
                {caption}
            </Link>
        </CWrap>
    );
};

export default withBaseProps(HashLink, {
    name: 'HashLink',
    code: 'HASH_LINK',
    nodeNames: Object.keys(HashLinkNodes),
});
