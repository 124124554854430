import { FC, useEffect } from 'react';
import {
    BaseFCProps,
    PolicyNodes,
    Section,
    Text,
    withBaseProps,
} from 'uikit-lib';
import {
    Title,
    CWrap,
    SubTitle,
    Chapter,
    Table,
    TableHead,
    TableBody,
    TableData,
    TableRow,
    PointList,
    ListItem,
    Anchor,
    TableHeadData,
    SupText,
} from './PrivacyPolicyItem.styles';

export type Props = BaseFCProps<PolicyNodes>;

const PrivacyPolicyItem: FC<Props> = ({ tokens, theme, testIds }) => {
    useEffect(() => {
        const { hash } = window.location;

        const elementToScroll = document.getElementById(hash?.replace('#', ''));

        if (!elementToScroll) return;

        window.scrollTo({
            top: elementToScroll.offsetTop,
        });
    }, []);

    return (
        <CWrap data-testid={testIds.CWrap} theme={theme.CWrap}>
            <Section
                type={'BASIC'}
                tokens={{
                    hlaToken: tokens?.hlaToken
                        ? `${tokens.hlaToken}__PRIVACY_POLICY_ITEM`
                        : 'PRIVACY_POLICY_ITEM',
                }}>
                <Title theme={theme.Title}>
                    <Text type='S3' nodeName='H3' tokens={{}}>
                        Политика конфиденциальности сайта safeboard.kaspersky.ru
                    </Text>
                </Title>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        СВЕДЕНИЯ ОБ ОПЕРАТОРЕ ДАННЫХ
                    </Text>
                </SubTitle>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Настоящая Политика конфиденциальности применима к
                        веб-сайту о карьере в АО {'"'}Лаборатория Касперского
                        {'"'}&nbsp;(
                        <Anchor
                            theme={theme.Anchor}
                            href='https://safeboard.kaspersky.ru'
                            target='_blank'
                            rel='noreferrer'>
                            https://safeboard.kaspersky.ru
                        </Anchor>
                        ), расположенной по адресу: 125212, Российская
                        Федерация, Москва, Ленинградское шоссе, д. 39А, стр. 2
                        (далее {'"'}Лаборатория Касперского{'"'}, {'"'}Компания
                        {'"'} или {'"'}мы{'"'}).
                    </Text>
                </Chapter>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Если у вас есть вопросы о Политике конфиденциальности
                        веб-сайта или вы хотите обновить предоставленную нам
                        информацию или предпочтения, обратитесь через форму о
                        защите данных здесь:&nbsp;
                        <Anchor
                            theme={theme.Anchor}
                            href='https://support.kaspersky.ru/general/privacy'
                            target='_blank'
                            rel='noreferrer'>
                            https://support.kaspersky.ru/general/privacy
                        </Anchor>
                    </Text>
                </Chapter>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        ОБЩАЯ ИНФОРМАЦИЯ
                    </Text>
                </SubTitle>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Вы можете посещать большинство страниц этого веб-сайта и
                        читать размещенную на нем информацию без предоставления
                        каких-либо персональных данных.
                    </Text>
                </Chapter>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Чтобы предоставлять запрашиваемую вами информацию с
                        наших веб-сайтов, наши серверы сохраняют в анонимной
                        форме некоторые данные в целях предоставления услуг
                        и/или в статистических целях и целях обеспечения
                        безопасности. В этом случае сохраняются общие сведения,
                        например, доменное имя или тип вашего браузера. Эти
                        данные не могут быть использованы нами для вашей
                        идентификации.
                    </Text>
                </Chapter>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Мы не обрабатываем «специальные категории персональных
                        данных» (сведения о расовой и этнической принадлежности,
                        религиозных или философских убеждениях, сексуальной
                        жизни, сексуальной ориентации, политических
                        предпочтениях, участии в профессиональных союзах, а
                        также сведения о вашем состоянии здоровья, генетические
                        и биометрические данные).
                    </Text>
                </Chapter>
                <SubTitle
                    theme={theme.SubTitle}
                    id={'personalDataInternshipProcessing'}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        Согласие на обработку данных
                    </Text>
                </SubTitle>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Во исполнение Федерального закона от 27.07.2006 г.
                        №152-ФЗ «О персональных данных» настоящим я даю свое
                        согласие АО «Лаборатория Касперского» производить в
                        соответствии с законодательством Российской Федерации и
                        внутренними нормативными документами Компании обработку
                        информации и сведений обо мне, включая сбор,
                        систематизацию, накопление, хранение, уточнение
                        (обновление, изменение), использование, обезличивание,
                        блокирование и уничтожение персональных данных и иной
                        информации, сообщенной мной или моим представителем
                        Компании, а также осуществлять хранение такой информации
                        в составе следующих персональных данных:
                    </Text>
                </Chapter>
                <Table theme={theme.Table}>
                    <TableHead theme={theme.TableHead}>
                        <TableRow theme={theme.TableRow}>
                            <TableHeadData theme={theme.TableHeadData}>
                                <Text type='T1' nodeName='P' tokens={{}}>
                                    Категории персональных данных
                                </Text>
                            </TableHeadData>
                            <TableHeadData theme={theme.TableHeadData}>
                                <Text type='T1' nodeName='P' tokens={{}}>
                                    Цель обработки
                                </Text>
                            </TableHeadData>
                            <TableHeadData theme={theme.TableHeadData}>
                                <Text type='T1' nodeName='P' tokens={{}}>
                                    Срок хранения
                                </Text>
                            </TableHeadData>
                            <TableHeadData theme={theme.TableHeadData}>
                                <Text type='T1' nodeName='P' tokens={{}}>
                                    Согласие на обработку данных
                                </Text>
                            </TableHeadData>
                        </TableRow>
                    </TableHead>
                    <TableBody theme={theme.TableBody}>
                        <TableRow theme={theme.TableRow}>
                            <TableData theme={theme.TableData}>
                                <PointList theme={theme.PointList}>
                                    <ListItem theme={theme.ListItem}>
                                        <Text
                                            type='T1'
                                            nodeName='P'
                                            tokens={{}}>
                                            Фамилия
                                        </Text>
                                    </ListItem>
                                    <ListItem theme={theme.ListItem}>
                                        <Text
                                            type='T1'
                                            nodeName='P'
                                            tokens={{}}>
                                            Имя
                                        </Text>
                                    </ListItem>
                                    <ListItem theme={theme.ListItem}>
                                        <Text
                                            type='T1'
                                            nodeName='P'
                                            tokens={{}}>
                                            Сведения об образовании
                                        </Text>
                                    </ListItem>
                                    <ListItem theme={theme.ListItem}>
                                        <Text
                                            type='T1'
                                            nodeName='P'
                                            tokens={{}}>
                                            Город проживания
                                        </Text>
                                    </ListItem>
                                    <ListItem theme={theme.ListItem}>
                                        <Text
                                            type='T1'
                                            nodeName='P'
                                            tokens={{}}>
                                            Номер телефона
                                        </Text>
                                    </ListItem>
                                    <ListItem theme={theme.ListItem}>
                                        <Text
                                            type='T1'
                                            nodeName='P'
                                            tokens={{}}>
                                            Адрес электронной почты
                                        </Text>
                                    </ListItem>
                                    <ListItem theme={theme.ListItem}>
                                        <Text
                                            type='T1'
                                            nodeName='P'
                                            tokens={{}}>
                                            Ник в telegram
                                        </Text>
                                    </ListItem>
                                    <ListItem theme={theme.ListItem}>
                                        <Text
                                            type='T1'
                                            nodeName='P'
                                            tokens={{}}>
                                            Рассказ о себе
                                        </Text>
                                    </ListItem>
                                    <ListItem theme={theme.ListItem}>
                                        <Text
                                            type='T1'
                                            nodeName='P'
                                            tokens={{}}>
                                            Интересующие направления стажировок
                                        </Text>
                                    </ListItem>
                                </PointList>
                            </TableData>
                            <TableData theme={theme.TableData}>
                                <Text type='T1' nodeName='P' tokens={{}}>
                                    Проведение тестирования и дальнейшее
                                    рассмотрение кандидатуры для приёма на
                                    стажировку
                                </Text>
                            </TableData>
                            <TableData theme={theme.TableData}>
                                <Text type='T1' nodeName='P' tokens={{}}>
                                    5 лет
                                </Text>
                            </TableData>
                            <TableData theme={theme.TableData}>
                                <Text type='T1' nodeName='P' tokens={{}}>
                                    Я разрешаю Компании обрабатывать мои
                                    персональные данные, а также передавать
                                    персональные данные и поручать их обработку
                                    третьим лицам с целью проведения
                                    тестирования и дальнейшего рассмотрения моей
                                    кандидатуры для приёма на стажировку в АО
                                    «Лаборатория Касперского»
                                </Text>
                            </TableData>
                        </TableRow>
                        <TableRow theme={theme.TableRow}>
                            <TableData theme={theme.TableData}>
                                <PointList theme={theme.PointList}>
                                    <ListItem theme={theme.ListItem}>
                                        <Text
                                            type='T1'
                                            nodeName='P'
                                            tokens={{}}>
                                            Фамилия
                                        </Text>
                                    </ListItem>
                                    <ListItem theme={theme.ListItem}>
                                        <Text
                                            type='T1'
                                            nodeName='P'
                                            tokens={{}}>
                                            Имя
                                        </Text>
                                    </ListItem>
                                    <ListItem theme={theme.ListItem}>
                                        <Text
                                            type='T1'
                                            nodeName='P'
                                            tokens={{}}>
                                            Адрес электронной почты
                                        </Text>
                                    </ListItem>
                                </PointList>
                            </TableData>
                            <TableData theme={theme.TableData}>
                                <Text type='T1' nodeName='P' tokens={{}}>
                                    Осуществление маркетинговой деятельности
                                    Лабораторией Касперского, в том числе
                                    предоставление доступа к участию в событиях
                                    и мероприятиях, проводимых Лабораторией
                                    Касперского
                                </Text>
                            </TableData>
                            <TableData theme={theme.TableData}>
                                <Text type='T1' nodeName='P' tokens={{}}>
                                    5 лет
                                </Text>
                            </TableData>
                            <TableData theme={theme.TableData}>
                                <Text type='T1' nodeName='P' tokens={{}}>
                                    Я разрешаю АО «Лаборатория Касперского»
                                    обрабатывать мои персональные данные, а
                                    также передавать персональные данные и
                                    поручать их обработку третьим лицам с целью
                                    осуществления АО «Лаборатория Касперского»
                                    маркетинговой деятельности
                                    <SupText theme={theme.SupText}>1</SupText>
                                </Text>
                            </TableData>
                        </TableRow>
                    </TableBody>
                </Table>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        <SupText theme={theme.SupText}>1</SupText>
                        Применяется при получении согласия на получение
                        маркетинговой информации.
                    </Text>
                </Chapter>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Я подтверждаю, что я проинформирован(а) о порядке отзыва
                        настоящего согласия путем подачи письменного заявления в
                        АО «Лаборатория Касперского» в случаях, предусмотренных
                        законодательством РФ.
                    </Text>
                </Chapter>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Мы получаем ваши личные данные от вас напрямую.
                    </Text>
                </Chapter>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        ЮРИДИЧЕСКОЕ ОСНОВАНИЕ ДЛЯ ОБРАБОТКИ ВАШИХ ПЕРСОНАЛЬНЫХ
                        ДАННЫХ
                    </Text>
                </SubTitle>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Наши законные основания для обработки ваших персональных
                        данных:
                    </Text>
                </Chapter>
                <PointList theme={theme.PointList}>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            Ваше согласие на обработку персональных данных с
                            целью рассмотрения кандидатуры для приёма на
                            стажировку
                        </Text>
                    </ListItem>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            Ваше согласие на обработку данных в целях получения
                            маркетинговой информации
                        </Text>
                    </ListItem>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            Обработка, необходимая для выполнения требований
                            действующего законодательства.
                        </Text>
                    </ListItem>
                </PointList>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        ГДЕ МЫ ОБРАБАТЫВАЕМ ДАННЫЕ
                    </Text>
                </SubTitle>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Персональные данные, предоставляемые пользователями
                        этого сайта, обрабатываются на территории России.
                    </Text>
                </Chapter>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        ВРЕМЯ, В ТЕЧЕНИЕ КОТОРОГО МЫ ХРАНИМ ВАШИ ПЕРСОНАЛЬНЫЕ
                        ДАННЫЕ
                    </Text>
                </SubTitle>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Персональные данные хранятся в течение 5 лет, пока
                        пользователем не будет отозвано согласие на обработку
                        его персональных данных или до достижения целей
                        обработки.
                    </Text>
                </Chapter>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        ИСПОЛЬЗОВАНИЕ COOKIES
                    </Text>
                </SubTitle>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Файлы cookie представляют собой файлы или фрагменты
                        информации, которые могут быть сохранены на вашем
                        компьютере или других Интернет-совместимых устройствах
                        (например, смартфон или планшет), когда вы посещаете наш
                        веб-сайт.
                    </Text>
                </Chapter>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        На этом сайте мы используем cookies в виде электронных
                        меток (веб-маяки) для получения комплексной статистики в
                        отношении эффективности наших рекламных кампаний и иных
                        операций.
                    </Text>
                </Chapter>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Вы можете настроить параметры браузера таким образом,
                        чтобы файлы cookie блокировались или ваша система
                        сообщала вам, когда веб-сайт хочет установить файлы
                        cookie. Обратите внимание, что блокирование файлов
                        cookie может привести к тому, что вы не сможете
                        использовать все функции нашего веб-сайта.
                    </Text>
                </Chapter>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Мы также используем различные типы &quot;модулей для
                        социальных сетей&quot;. Ознакомьтесь со списком cookie,
                        встроенных в наши веб-сайты и таблицей, разъясняющей,
                        как мы используем модули социальных сетей.
                    </Text>
                </Chapter>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        ВАШИ ПРАВА И ВАШИ ПЕРСОНАЛЬНЫЕ ДАННЫЕ И СПОСОБЫ ИХ
                        РЕАЛИЗАЦИИ
                    </Text>
                </SubTitle>
                <PointList theme={theme.PointList}>
                    <ListItem theme={theme.ListItem}>
                        Ваши права в отношении персональных данных предусмотрены
                        действующим законодательством.
                    </ListItem>
                </PointList>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Если вы хотите воспользоваться этими правами, вы можете
                        в любое время связаться с нами через форму по адресу{' '}
                        <Anchor
                            theme={theme.Anchor}
                            href='https://support.kaspersky.ru/general/privacy'
                            target='_blank'
                            rel='noreferrer'>
                            https://support.kaspersky.ru/general/privacy
                        </Anchor>
                    </Text>
                </Chapter>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        ЖАЛОБЫ
                    </Text>
                </SubTitle>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Если вы хотите подать жалобу в отношении данной Политики
                        конфиденциальности или того, как мы используем ваши
                        Персональные данные, свяжитесь с нами, перейдя по{' '}
                        <Anchor
                            theme={theme.Anchor}
                            href='https://support.kaspersky.ru/general/privacy'
                            target='_blank'
                            rel='noreferrer'>
                            этой ссылке
                        </Anchor>
                        .
                    </Text>
                </Chapter>
            </Section>
        </CWrap>
    );
};

export default withBaseProps<PolicyNodes, Props>(PrivacyPolicyItem, {
    name: 'Policy',
    code: 'PRICACY_POLICY',
    nodeNames: Object.keys(PolicyNodes),
});
