import {
    TRANSITION__DEFAULT,
    TYPOGRAPHY__H2,
    TYPOGRAPHY__H3,
    TYPOGRAPHY__H4,
    TYPOGRAPHY__S1,
    TYPOGRAPHY__S4,
} from '../../../../coreTheme';

const TITLE_COLOR = '#F1F1F1';

export const theme = {
    CWrap: {
        position: 'relative',
        overflow: 'hidden',

        '.isMobileButton': {
            display: 'none',

            '@media screen and (max-width: 640px)': {
                display: 'block',
            },
        },
    },
    BackgroundImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundSize: '100% 101%',
        backgroundRepeat: 'no-repeat',
    },
    Content: {
        padding: '80px 0',
        minHeight: '100%',
        position: 'relative',
        zIndex: 10,
    },
    Question: {
        ...TYPOGRAPHY__S4,
        ...TRANSITION__DEFAULT,
        display: 'inline-block',
        color: '#F2F1F8',
        padding: '6px 10px',
        letterSpacing: '1.44px',
        borderRadius: '5px',
        border: '1px solid rgba(255, 255, 255, 0.20)',
        fontWeight: 'var(--TYPOGRAPHY__CORE____FONT_WEIGHT__IS_LIGHT)',
        position: 'relative',

        '@media screen and (max-width: 640px)': {
            '&.active': {
                '&:before': {
                    content: '""',
                    width: '13px',
                    height: '7px',
                    backgroundImage:
                        'url("/assets/images/home/bubble-tail.svg")',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    position: 'absolute',
                    left: '0',
                    bottom: '-8px',
                },
            },
        },

        '&:hover': {
            boxShadow:
                '-3.737499952316284px 3.737499952316284px 22.424999237060547px 0px rgba(38, 255, 211, 0.30)',
        },

        '&:hover, &.active, &.unselectable': {
            cursor: 'pointer',
            backgroundColor: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
            color: 'var(--COLORS__PRIMARY_PALETTE____CRAPHITE)',
        },

        '&.unselectable': {
            padding: '6px 20px',
            pointerEvents: 'none',
            float: 'right',
            margin: '30px 0 0 0',
            alignSelf: 'flex-end',
            position: 'relative',

            '&:before': {
                content: '""',
                width: '13px',
                height: '7px',
                backgroundImage: 'url("/assets/images/home/bubble-tail.svg")',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'absolute',
                right: '0',
                bottom: '-8px',
                transform: 'scaleX(-1)',
            },

            '@media screen and (max-width: 640px)': {
                display: 'none',
            },
        },
    },
    Answer: {
        ...TYPOGRAPHY__S4,
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        fontWeight: 'var(--TYPOGRAPHY__CORE____FONT_WEIGHT__IS_LIGHT)',
        backgroundColor: 'rgba(255, 255, 255, 0.20)',
        padding: '30px',
        borderRadius: '10px',
        backdropFilter: 'blur(6.5px)',
        position: 'relative',

        '&.isMobile': {
            display: 'none',
        },

        '&:before': {
            content: '""',
            width: '13px',
            height: '7px',
            backgroundImage: 'url("/assets/images/home/bubble-tail-grey.svg")',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
            left: '0',
            bottom: '-7px',

            '@media screen and (max-width: 640px)': {
                transform: 'scaleX(-1)',
                left: 'auto',
                right: '0',
            },
        },

        '@media screen and (max-width: 640px)': {
            margin: '25px 0 0 0',

            '&.isMobile': {
                display: 'flex',
            },
        },
    },
    AnswerIcon: {
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        marginTop: '-35px',
    },
    Title: {
        ...TYPOGRAPHY__H2,
        color: TITLE_COLOR,
        margin: '0 0 40px 0',

        '@media screen and (max-width: 640px)': {
            ...TYPOGRAPHY__H3,
        },
    },
    RowTitle: {
        ...TYPOGRAPHY__S1,
        color: TITLE_COLOR,
        margin: '0 0 10px 0',
    },
    RowContent: {
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        alignItems: 'flex-start',

        '@media screen and (max-width: 640px)': {
            gap: '15px',
        },
    },
    RowContentItem: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        gap: '50px',
        alignItems: 'flex-start',
        position: 'relative',
        zIndex: 25,

        '@media screen and (max-width: 640px)': {
            width: '100%',
            gap: '30px',

            '&:last-child': {
                alignItems: 'flex-end',
            },

            '&.isDesktop': {
                display: 'none',
            },
        },
    },
    RowContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '80px',

        '@media screen and (max-width: 640px)': {
            flexDirection: 'column',
            gap: '40px',
        },
    },
    Marquee: {
        height: '100px',
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',
        background:
            'linear-gradient(90deg, #1D1D1B 0%, #146658 44.27%, #29CCB1 100%)',
        ...TYPOGRAPHY__H4,
        position: 'relative',
        zIndex: 10,
    },
};

export default theme;
