import { CarouselLocalStore } from '../../../../../';

export type Params2CalcTrackPosition = Pick<
    CarouselLocalStore,
    'activePage' | 'steps2MoveTrack' | 'numOfPages'
>;

export const getTrackPosition = (
    sourceParams: Params2CalcTrackPosition,
    mode: 'MOVE2PREV' | 'MOVE2NEXT'
): Partial<CarouselLocalStore> => {
    const { activePage, steps2MoveTrack, numOfPages } = sourceParams;
    let nextActivePage = 0;
    if (mode === 'MOVE2PREV' && activePage > 0) nextActivePage = activePage - 1;
    if (mode === 'MOVE2NEXT' && activePage < numOfPages)
        nextActivePage = activePage + 1;
    const output = {
        trackPosition: steps2MoveTrack[nextActivePage],
        activePage: nextActivePage,
    };
    return output;
};

export default getTrackPosition;
