import React from 'react';
import { withBaseProps, ModalContentNodes, BaseFCProps } from 'uikit-lib';
import { CWrap } from './Content.styles';

export interface Props extends BaseFCProps<ModalContentNodes> {
    children: React.ReactNode;
}

export const Content: React.FC<Props> = (props): JSX.Element => {
    const { children, theme, testIds } = props;

    return (
        <CWrap theme={theme.CWrap} data-testid={testIds.CWrap}>
            {children}
        </CWrap>
    );
};

export default withBaseProps<ModalContentNodes, Props>(Content, {
    name: 'ModalContent',
    code: 'MODAL_CONTENT',
    nodeNames: Object.keys(ModalContentNodes),
});
