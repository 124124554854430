export const getSteps2MoveTrack = (
    trackWidth: number,
    stepDelta: number
): number[] => {
    const steps2MoveTrack = [0];
    let moveCount = Math.floor(trackWidth / stepDelta);
    const lastMove = trackWidth - moveCount * stepDelta;

    if (moveCount === 0) {
        return steps2MoveTrack;
    } else if (moveCount === 1) {
        if (lastMove === 0) {
            return steps2MoveTrack;
        } else {
            steps2MoveTrack.push(trackWidth - stepDelta);
        }
    } else {
        let lastActive = 0;
        while (moveCount > 0) {
            moveCount -= 1;
            let moveValue = stepDelta + lastActive;
            if (moveCount === 0 && lastMove) moveValue = trackWidth - stepDelta;
            if (moveValue > 0) steps2MoveTrack.push(moveValue);
            lastActive = moveValue;
        }
    }
    return steps2MoveTrack;
};

export default getSteps2MoveTrack;
