import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const Title = styled.div`
    ${getCSSObj}
`;

export const Content = styled.div`
    ${getCSSObj}
`;

export const ContentTitle = styled.h4`
    ${getCSSObj}
`;

export const ContentWrap = styled.div`
    ${getCSSObj}
`;
