import { NavigateFunction } from 'react-router-dom';
import {
    MenuData,
    MenuItemType,
    MenuDeclaration,
    NavBtn,
    MenuBtnProps,
    EntitiesLocale,
} from '..';

export const genMenuItemAsBtn = (
    navBtnDeclaration: MenuBtnProps,
    navigateFn: NavigateFunction,
    entityNamesLocale: EntitiesLocale,
    handler?: (params?: unknown) => void
): MenuBtnProps => {
    const {
        menuType,
        type,
        key,
        entityNameParams,
        routePath,
        newTab,
        modifierClass,
        actionToken,
        iconParams,
        handleOnClick,
    } = navBtnDeclaration;
    return {
        key,
        menuType,
        type,
        navigatesTo: routePath,
        actionToken,
        modifierClass: modifierClass ? modifierClass : undefined,
        routePath,
        newTab,
        caption:
            entityNameParams && entityNamesLocale[entityNameParams.code]
                ? entityNamesLocale[entityNameParams.code][
                      entityNameParams.form
                  ]
                : undefined,
        closeMenu: handler,
        handleOnClick: (params?: any) => {
            if (handler) handler();
            if (handleOnClick) {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                params ? handleOnClick(params) : handleOnClick();
            }

            if (
                !!routePath &&
                (routePath.includes('https://') ||
                    routePath.includes('http://'))
            )
                window.open(routePath);
            else {
                if (newTab) {
                    window.open(
                        `${window.location.origin}${routePath}`,
                        '_blank'
                    );
                } else {
                    navigateFn(routePath);
                    window.scrollTo(0, 0);
                }
            }
        },
        iconParams,
    };
};

export const convertMenuItems = (
    menuItems: MenuDeclaration,
    navigateFn: NavigateFunction,
    langs: EntitiesLocale,
    handler?: () => void
): MenuData => {
    const output: MenuData = [];

    menuItems.map((element) => {
        if (element.menuType === MenuItemType.BUTTON)
            output.push({
                type: MenuItemType.BUTTON,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                data: genMenuItemAsBtn(element, navigateFn, langs, handler),
            });
        if (element.menuType === MenuItemType.DROPDOWN) {
            const {
                id,
                key,
                type,
                menuType,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                items,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                toggleBtnIconParams,
                entityNameParams,
            } = element;
            const dropdownItems: MenuBtnProps[] = [];
            items.map((item: NavBtn) => {
                dropdownItems.push(
                    genMenuItemAsBtn(item, navigateFn, langs, handler)
                );
            });
            output.push({
                type: MenuItemType.DROPDOWN,
                data: {
                    id,
                    key,
                    type,
                    menuType,
                    items: [...dropdownItems],
                    toggleBtnIconParams,
                    toggleBtnCaption:
                        entityNameParams && langs[entityNameParams.code]
                            ? langs[entityNameParams.code][
                                  entityNameParams.form
                              ]
                            : '',
                },
            });
        }
    });

    return output;
};

export default convertMenuItems;
