import {
    BASIC,
    DECLINE,
    FOOTER_MENU_BTN,
    HEADER_MENU_BTN,
    SPECIAL_PROJECTS_BTN,
    GRADIENT_NEON,
    TRANSPARENT,
} from './types';

export const theme = {
    BASIC,
    DECLINE,
    FOOTER_MENU_BTN,
    HEADER_MENU_BTN,
    SPECIAL_PROJECTS_BTN,
    GRADIENT_NEON,
    TRANSPARENT,
};

export default theme;
