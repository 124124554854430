import React, { LegacyRef, forwardRef, useEffect, useState } from 'react';
import { BaseFCProps, CheckBoxNodes, withBaseProps, CurIcon } from '../../';
import { CWrap, Input } from './CheckBox.styles';

export interface Props extends BaseFCProps<CheckBoxNodes> {
    type: string;
    value: boolean;
    onChange: (value: boolean) => unknown;
    isValid?: boolean;
    disabled?: boolean;
}

// eslint-disable-next-line react/display-name
export const CheckBox = forwardRef<unknown, Props>(
    (props, ref): JSX.Element => {
        // console.log('RUNTIME::STATUS::CheckBox\nprops are - ', props);
        const {
            theme,
            testIds,
            value,
            onChange,
            isValid,
            disabled = false,
            tokens: { groupToken = '' },
        } = props;
        const [curValue, setCurValue] = useState<boolean>(value);

        useEffect(() => {
            setCurValue(value);
        }, [value]);

        return (
            <CWrap
                theme={theme.CWrap}
                data-at-selector={groupToken}
                data-testid={testIds.CWrap}
                onClick={() => {
                    setCurValue(!value);
                    onChange(!value);
                }}
                className={`${value ? 'isChecked' : ''}${
                    typeof isValid === 'boolean' && isValid ? '' : ' isInvalid'
                } ${disabled ? 'isDisabled' : ''}`}>
                <Input
                    theme={theme.Input}
                    data-at-selector={groupToken}
                    data-testid={testIds.CWrap}
                    onClick={() => {
                        const newValue = !curValue;
                        setCurValue(newValue);
                        onChange(newValue);
                    }}
                    ref={ref as LegacyRef<HTMLInputElement>}
                    type={'checkbox'}
                    hidden
                    disabled={disabled}
                />
                {curValue && <CurIcon sizeId={'REDUCED'} iconId={'APPLY'} />}
            </CWrap>
        );
    }
);

export default React.memo(
    withBaseProps<CheckBoxNodes, Props>(CheckBox, {
        name: 'CheckBox',
        code: 'CHECKBOX',
        nodeNames: Object.keys(CheckBoxNodes),
    })
);
