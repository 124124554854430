import React, { useEffect, useState } from 'react';
import {
    getDataTestId,
    getFCTheme,
    Footer,
    Header,
    CookieBanner,
    getLSItemSync,
    setLSItemSync,
    MenuBtnProps,
    setUtm,
} from 'uikit-lib';
import AppRouter from '../router';
import { Modal, useAction, useQueryDictionary } from 'src/containers';
import { MainArea } from './AppLayout.styles';
import { AppSettings } from 'uikit-lib/src/types/app';
import {
    FOOTER_MENU_ITEMS,
    SOCIAL_LINKS,
    ENTITIES_LOCALE,
    HEADER_MENU_ITEMS,
    REG_FORM_BTN_DESCRIPTOR,
} from 'src/constants';
import { useLazyQuery } from '@apollo/client';

interface Props {
    appSettings: AppSettings;
}

const COOKIE_BANNER_LS_NAME = 'cookieBannerSuppressed';

export const AppLayout: React.FC<Props> = ({ appSettings }): JSX.Element => {
    const curFCId = 'APP_LAYOUT';
    const hlaToken = 'APP';
    const testIds = {
        MainArea: getDataTestId(`${curFCId}__MAIN_AREA`, hlaToken, ''),
    };
    const theme = getFCTheme('AppLayout');

    const { setAppData, setModalData, setFetchedData } = useAction([
        'setAppData',
        'setModalData',
        'setFetchedData',
    ]);

    const [cookieBannerVisible, setCookieBannerVisible] = useState<boolean>(
        !getLSItemSync(COOKIE_BANNER_LS_NAME)
    );
    const [menuItemsList, setMenuItemsList] = useState<MenuBtnProps[]>([
        ...HEADER_MENU_ITEMS,
    ]);

    const [GET_REGISTER_FORM_CONFIG] = useQueryDictionary([
        'GET_REGISTER_FORM_CONFIG',
    ]);
    const [runRegisterFormConfigFetch] = useLazyQuery(GET_REGISTER_FORM_CONFIG);

    const [GET_HOMEPAGE_CONFIG] = useQueryDictionary(['GET_HOMEPAGE_SETTINGS']);
    const [runHomepageConfigFetch] = useLazyQuery(GET_HOMEPAGE_CONFIG);

    const [GET_EVENT_ADT] = useQueryDictionary(['GET_EVENT_ADT']);
    const [runEventAdtFetch] = useLazyQuery(GET_EVENT_ADT);

    useEffect(() => {
        if (!!appSettings && setAppData) {
            setAppData({ appSettings });
        }
    }, [appSettings]);

    const processMenuItemsListChange = (isOpened: boolean) => {
        if (isOpened)
            setMenuItemsList([
                ...menuItemsList,
                { ...REG_FORM_BTN_DESCRIPTOR },
            ]);
    };

    const suppressCookieBanner = (): void => {
        setCookieBannerVisible(false);
        setLSItemSync({ name: COOKIE_BANNER_LS_NAME, value: true });
    };

    useEffect(() => {
        setUtm();
        runRegisterFormConfigFetch().then((response) => {
            if (
                setFetchedData &&
                response?.data?.internshipSettings?.settings
            ) {
                setFetchedData({
                    registerFormConfig: {
                        ...response?.data?.internshipSettings?.settings,
                    },
                });
                processMenuItemsListChange(
                    response?.data?.internshipSettings?.settings?.isOpened
                );
            }
        });
        runHomepageConfigFetch().then((response) => {
            if (response?.data?.homepageSettings?.settings) {
                if (setFetchedData) {
                    setFetchedData({
                        homepageSettings: {
                            ...response?.data?.homepageSettings?.settings,
                        },
                    });
                }
            }
        });

        runEventAdtFetch().then((response) => {
            if (response?.data?.eventAdt?.settings) {
                if (setFetchedData) {
                    setFetchedData({
                        eventAdt: {
                            ...response?.data?.eventAdt?.settings,
                        },
                    });
                }
            }
        });
    }, []);

    return (
        <>
            <Header
                tokens={{
                    hlaToken,
                    groupToken: 'HEADER',
                }}
                actions={{ setAppData, setModalData }}
                type={'SAFEBOARD_DARK'}
                menuItems={menuItemsList}
                entitiesLocale={ENTITIES_LOCALE}
                logoImagePath={'/assets/images/logo/safeboard__is_white.png'}
            />
            <MainArea theme={theme.MainArea} data-testid={testIds.MainArea}>
                <AppRouter />
            </MainArea>
            <Footer
                tokens={{
                    groupToken: 'FOOTER',
                    hlaToken,
                }}
                type={'SAFEBOARD_DARK'}
                logoImagePath={'/assets/images/logo/kaspersky__is_white.png'}
                entitiesLocale={ENTITIES_LOCALE}
                socialLinks={SOCIAL_LINKS}
                menuItems={FOOTER_MENU_ITEMS}
                copyright={'© 2024 АО «Лаборатория Касперского»'}
            />
            <Modal />
            {cookieBannerVisible && (
                <CookieBanner
                    tokens={{ hlaToken, groupToken: 'COOKIE_BANNER' }}
                    link={
                        <a
                            href='https://www.kaspersky.ru/third-party-tracking'
                            target='__blank'
                            rel='noreferrer'>
                            Подробнее
                        </a>
                    }
                    message={
                        'Мы используем файлы cookie, чтобы улучшить работу сайта. Дальнейшее пребывание на сайте означает согласие с их применением.'
                    }
                    btnCaption={'Принять и закрыть'}
                    accept={suppressCookieBanner}
                />
            )}
        </>
    );
};

export default AppLayout;
