import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const Title = styled.p`
    ${getCSSObj}
`;

export const Image = styled.div`
    ${getCSSObj}
`;

export const Column = styled.div`
    ${getCSSObj}
`;
