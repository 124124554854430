import { gql } from '@apollo/client';

export const GET_REGISTER_FORM_CONFIG_GQL = gql`
    query getInternshipSettings {
        internshipSettings {
            settings {
                interestDirectionIsRequired
                emailIsRequired
                phoneIsRequired
                telegramNickNameIsRequired
                cityIsRequired
                educationIsRequired
                additionalEducationIsRequired
                descriptionYourSelfIsRequired
                whatYouWantToKnowAboutInternshipIsRequired
                startEducationYears
                finishEducationYears
                start
                finish
                stepikCourseId
                firstNameIsRequired
                lastNameIsRequired
                isOpened
            }
        }
    }
`;

export default GET_REGISTER_FORM_CONFIG_GQL;
