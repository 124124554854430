import React from 'react';
import { withBaseProps, BaseFCProps, TabsPanelNodes } from '../../';
import { Tab } from '..';
import { CWrap } from './TabsPanel.styles';

export interface TabItem {
    caption: string;
    tabId: string | number;
}

export interface Props extends BaseFCProps<TabsPanelNodes> {
    tabsState: {
        [key: string]: boolean;
    };
    items: TabItem[];
    handleOnClickTab: (tabId: string | number) => void;
}

export const TabsPanel: React.FC<Props> = (props): JSX.Element => {
    const {
        tabsState,
        items,
        handleOnClickTab,
        theme,
        testIds,
        tokens: { groupToken },
    } = props;

    return (
        <CWrap
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}>
            {items.map((item) => {
                const { caption, tabId } = item;
                return (
                    <Tab
                        key={window.crypto.randomUUID()}
                        tabId={tabId}
                        caption={caption}
                        isActive={tabsState[tabId]}
                        handleOnClick={handleOnClickTab}
                        tokens={{}}
                    />
                );
            })}
        </CWrap>
    );
};

export default withBaseProps<TabsPanelNodes, Props>(TabsPanel, {
    name: 'TabsPanel',
    code: 'TABS_PANEL',
    nodeNames: Object.keys(TabsPanelNodes),
});
