import { PubAppFetchedDataState } from 'src/types';

export const initFetchedDataState: PubAppFetchedDataState = {
    directions: [],
    internshipSteps: [],
    feedbacks: [],
    questions: [],
    registerFormConfig: {
        interestDirectionIsRequired: false,
        emailIsRequired: false,
        phoneIsRequired: false,
        telegramNickNameIsRequired: false,
        cityIsRequired: false,
        educationIsRequired: false,
        additionalEducationIsRequired: false,
        descriptionYourSelfIsRequired: false,
        whatYouWantToKnowAboutInternshipIsRequired: false,
        startEducationYears: [],
        finishEducationYears: [],
        start: '',
        finish: '',
        stepikCourseId: '',
        firstNameIsRequired: false,
        lastNameIsRequired: false,
        isOpened: false,
        type: '',
        knowAboutEventCityIsRequired: false,
    },
    homepageSettings: {
        introTitle: '',
        careerStartTitle: '',
        directionsTitle: '',
        traineeTitle: '',
    },
    eventAdt: {
        isVisible: false,
        description: '',
        link: '',
        image: null,
    },
};

export default initFetchedDataState;
