import React, {
    useState,
    useRef,
    forwardRef,
    LegacyRef,
    useEffect,
} from 'react';
import { withBaseProps, TextAreaNodes, BaseFCProps } from '../..';
import { CWrap, InputArea, Placeholder } from './TextArea.styles';

export interface Props extends BaseFCProps<TextAreaNodes> {
    type: string;
    value: string;
    onChange: (value: string) => void;
    onBlur: () => void;
    onKeyPress?: (evt: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
    isValid?: boolean;
}

// eslint-disable-next-line react/display-name
export const TextArea = forwardRef<unknown, Props>(
    (props, ref): JSX.Element => {
        const {
            value,
            placeholder,
            onChange,
            onBlur,
            onKeyPress,
            theme,
            isValid = true,
            testIds,
            tokens: { groupToken = '' },
        } = props;
        const [inputValue, setInputValue] = useState<string>(value);
        const [isBlur, setIsBlur] = useState<boolean>(true);
        const inputRef = useRef<HTMLTextAreaElement>(null);

        useEffect(() => {
            setInputValue(value);
        }, [value]);

        return (
            <CWrap
                ref={ref as LegacyRef<HTMLDivElement>}
                theme={theme.CWrap}
                data-testid={testIds.CWrap}
                className={`${!isValid ? 'isInvalid' : ''}`}
                onClick={(event) => {
                    event.stopPropagation();
                    setIsBlur(false);
                    if (inputRef?.current) inputRef?.current.focus();
                }}>
                <InputArea
                    theme={theme.InputArea}
                    data-at-selector={groupToken}
                    data-testid={testIds.InputArea}
                    value={inputValue}
                    ref={inputRef}
                    onChange={(event) => {
                        setInputValue(event.target.value);
                        onChange(event.target.value);
                    }}
                    onBlur={() => {
                        if (onBlur) onBlur();
                        setIsBlur(true);
                    }}
                    onKeyPress={onKeyPress}
                />
                {isBlur && !inputValue && (
                    <Placeholder
                        theme={theme.Placeholder}
                        data-testid={testIds.Placeholder}>
                        {placeholder}
                    </Placeholder>
                )}
            </CWrap>
        );
    }
);

export default React.memo(
    withBaseProps<TextAreaNodes, Props>(TextArea, {
        name: 'TextArea',
        code: 'TEXT_AREA',
        nodeNames: Object.keys(TextAreaNodes),
    })
);
