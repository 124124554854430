import { gql } from '@apollo/client';

export const GET_REGISTER_FORM_CONFIG_GQL = gql`
    query getHomepageSettings {
        homepageSettings {
            settings {
                introTitle
                directionsTitle
                directionsDescription
                traineeTitle
                traineeDescription
                careerStartTitle
                careerStartDescription
            }
        }
    }
`;

export default GET_REGISTER_FORM_CONFIG_GQL;
