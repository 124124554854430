import React from 'react';
import { getDataTestId, getArrayFromNumber } from '../../../../utils';
import { FCStyles, FCTokens } from '../../../..';
import { Dot } from '..';
import { CWrap } from './Pagination.styles';

export interface Props {
    params: {
        isVertical: boolean;
        numOfPages: number;
        activePage: number;
        handleOnClick: (id: number) => void;
    };
    tokens: FCTokens;
    theme: FCStyles;
}

export const Pagination: React.FC<Props> = (props): JSX.Element => {
    const {
        params: { numOfPages, activePage, handleOnClick },
        tokens: { groupToken = '', hlaToken = '' },
        theme,
    } = props;

    const curFCId = 'PAGINATION';
    const testIds = {
        CWrap: getDataTestId(`${curFCId}__CWRAP`, hlaToken, ''),
    };
    const pages = getArrayFromNumber(numOfPages);

    return (
        <CWrap
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}>
            {pages.map((_item, index) => (
                <Dot
                    key={index}
                    id={index}
                    activePage={activePage}
                    tokens={{}}
                    handleOnClick={() => handleOnClick(index)}
                    theme={theme.Dot}
                />
            ))}
        </CWrap>
    );
};

export default Pagination;
