import Section from './Section';
import SecurePath from './SecurePath';
import HomeIntro from './HomeIntro';
import HomeIntroBanner from './HomeIntroBanner';
import Separator from './Separator';

export const componentThemes = {
    Section,
    SecurePath,
    HomeIntro,
    HomeIntroBanner,
    Separator,
};

export default componentThemes;
