import { TYPOGRAPHY__T2 } from '../../../../coreTheme';

export const theme = {
    CWrap: {
        position: 'relative',
        backgroundColor: '#fff',
        borderRadius: '8px',
        overflow: 'hidden',
        width: '100%',

        '& input': {
            ...TYPOGRAPHY__T2,
            padding: '16px 44px 16px 12px',
            width: '100%',
            '&::placeholder': {
                color: 'var(--COLORS__GRAYSCALE____4)',
                opacity: 1,
            },
            '&:-ms-input-placeholder': {
                color: 'var(--COLORS__GRAYSCALE____4)',
            },
            '&::-ms-input-placeholder': {
                color: 'var(--COLORS__GRAYSCALE____4)',
            },

            border: '1px solid',
            borderColor: 'var(--COLORS__GRAYSCALE____4)',
            borderRadius: '8px',

            '&:hover': {
                borderColor: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
            },

            '&.isInvalid': {
                borderColor: 'var(--COLORS__SECONDARY_PALETTE____RED)',
            },

            '&:focus': {
                borderColor: 'var(--COLORS__PRIMARY_PALETTE____GREEN)',
            },
        },
    },
    Placeholder: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        ...TYPOGRAPHY__T2,
        padding: '16px 12px',
        color: 'var(--COLORS__GRAYSCALE____4)',
    },
    ResetBtn: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: '32px',
        height: '100%',
    },
};

export default theme;
