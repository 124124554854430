import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const SplashBg = styled.div`
    ${getCSSObj}
`;

export const ModalWrap = styled.div`
    ${getCSSObj}
`;
