import React, {
    useState,
    useRef,
    forwardRef,
    useImperativeHandle,
    useEffect,
} from 'react';
import InputMask from 'react-input-mask';
import {
    withBaseProps,
    InputBoxNodes,
    BaseFCProps,
    CurIcon,
    UnitSizeId,
} from '../..';
import { CWrap, ResetBtn } from './InputBox.styles';
import { checkInputValue } from './InputBox';

export interface Props extends BaseFCProps<InputBoxNodes> {
    type: string;
    value: string;
    placeholder?: string;
    hasErrors?: boolean;
    onChange: (value: string | number | undefined) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    maskParams: {
        mask: string;
        maskChar: string;
    };
    onKeyUp?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
}

// eslint-disable-next-line react/display-name
export const InputBox = forwardRef((props: Props, ref): JSX.Element => {
    const {
        value,
        onChange,
        onBlur,
        theme,
        testIds,
        tokens: { groupToken = '' },
        hasErrors = false,
        placeholder,
        maskParams,
    } = props;
    const [inputValue, setInputValue] = useState<string>(value);
    const [hasInputValue, setHasInputValue] = useState<boolean>(false);
    const inputRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => inputRef.current);

    useEffect(() => {
        const updatedHasValue = checkInputValue(value, 'text');
        setHasInputValue(updatedHasValue);
        setInputValue(value);
    }, [value]);

    return (
        <CWrap
            theme={theme.CWrap}
            data-testid={testIds.CWrap}
            ref={inputRef}
            onClick={(event) => {
                event.stopPropagation();
                const innerInput = inputRef.current?.querySelector('input');
                if (innerInput) innerInput.focus();
            }}>
            {hasInputValue && (
                <ResetBtn
                    theme={theme.ResetBtn}
                    data-testid={testIds.ResetBtn}
                    onClick={(event: React.MouseEvent) => {
                        event.preventDefault();
                        setInputValue('');
                        onChange('');
                        if (inputRef?.current) inputRef?.current.focus();
                    }}>
                    <CurIcon sizeId={UnitSizeId.REGULAR} iconId={'CROSS'} />
                </ResetBtn>
            )}
            <InputMask
                {...maskParams}
                data-at-selector={groupToken}
                data-testid={testIds.Input}
                value={inputValue}
                type={'text'}
                placeholder={placeholder}
                className={`inputMask ${hasErrors ? 'isInvalid' : ''}`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    event.preventDefault();
                    setInputValue(event.target.value);

                    if (onChange) onChange(event.target.value);
                }}
                onBlur={(event) => {
                    if (onBlur) onBlur(event);
                    const updatedHasValue = checkInputValue(value, 'text');
                    setHasInputValue(updatedHasValue);
                }}
                onKeyPress={(evt: React.KeyboardEvent<HTMLInputElement>) => {
                    if (evt.key === 'Enter') evt.preventDefault();
                }}
            />
        </CWrap>
    );
});

export default withBaseProps<InputBoxNodes, Props>(InputBox, {
    name: 'MaskedInputBox',
    code: 'MASKED_INPUT_BOX',
    nodeNames: Object.keys(InputBoxNodes),
});
