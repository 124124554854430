/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/rules-of-hooks */
import { useAction, initModalState } from '../../../LocalDataStore';
import lang from './lang';

export type ModalControlDeclaration = {
    name: string;
    key: string;
    caption: string;
    handleOnClick: () => void;
};

export type ControlsSequense = ModalControlDeclaration[];

export const sequenses: {
    [key: string]: ControlsSequense;
} = {
    /**
     * MOCK DATA START =>
     */
    PROFILE____SYSTEM__CONFIRM: [
        {
            name: 'okbtn',
            key: 'PROFILE____SYSTEM__CONFIRM__OK_BTN',
            caption: 'ok',
            handleOnClick: () => {
                const { setModalData } = useAction(['setModalData']);
                if (setModalData) setModalData({ ...initModalState });
            },
        },
    ],
    HOME_PAGE__MODAL____CUSTOM_FC__SHOW_REGISTER_FORM__IS_INTRO: [
        {
            name: 'registerFormSubmitBtn',
            key: 'HOME_PAGE__MODAL____CUSTOM_FC__SHOW_REGISTER_FORM__IS_INTRO__OK_BTN',
            caption: lang.registerFormSubmitBtnCaption,
            handleOnClick: () => {
                throw new Error('Implement backend request here');
            },
        },
    ],
    /**
     * <= END OF MOCK DATA
     */
};

export default sequenses;
