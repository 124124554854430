export const theme = {
    CWrap: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'max-content',
        padding: '30px 0 120px',

        '& .rec-slider-container': {
            margin: 0,
        },
        '& .rec-item-wrapper > div': {
            width: '100%',
            height: '100%',
        },
    },
    VisibleZone: {
        height: '320px',
    },
    Track: {},
    Item: {
        '&:hover': {
            '& > div': {
                transform: 'rotateY(180deg)',
            },
        },
    },
    Arrow: {},
    PaginationWrap: {},
    Dots: {},
};

export default theme;
