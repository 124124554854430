export interface RegisterFormLang {
    title: string;
    submitBtnCaption: string;
    placeholders: Record<string, string>;
    checkboxLabels: Record<string, React.FC>;
    errors: Record<string, string>;
}
export const ruLangs: RegisterFormLang = {
    title: 'Регистрация на стажировку',
    submitBtnCaption: 'Подать заявку',
    placeholders: {
        directionsSelect: 'Направление стажировки*',
        firstNameInput: 'Ваше имя*',
        lastNameInput: 'Ваша фамилия*',
        emailInput: 'Ваш email*',
        phoneInput: '(+7) XXX XXX XXXX*',
        telegramInput: 'Имя в Телеграм*',
        cityInput: 'Ваш город*',
        universityInput: 'ВУЗ*',
        facultyInput: 'Факультет*',
        startEduYearInput: 'Год поступления*',
        endEduYearInput: 'Год окончания*',
        aboutSelfInput: 'О себе',
        what2KnowInput: 'Что бы ты хотел узнать о стажировке?',
    },
    checkboxLabels: {
        marketingInfo: () => (
            <>
                {'Я даю '}
                <a
                    href={`${window.location.origin}/privacy-policy#personalDataInternshipProcessing`}
                    rel='noreferrer'
                    target='_blank'>
                    {'согласие'}
                </a>
                {
                    ' АО "Лаборатория Касперского"  обрабатывать мои персональные данные с целью получения макретинговых коммуникаций  в соответствии с '
                }
                <a
                    href={`${window.location.origin}/privacy-policy`}
                    rel='noreferrer'
                    target='_blank'>
                    {'политикой конфиденциальности'}
                </a>
            </>
        ),
        termsOfUse: () => (
            <>
                {'Настоящим, подтверждаю, что я ознакомлен и согласен с '}
                <a
                    href={`${window.location.origin}/terms-of-use`}
                    rel='noreferrer'
                    target='_blank'>
                    {'правилами использования сайта'}
                </a>
                {'*'}
            </>
        ),
        privacyPolicy: () => (
            <>
                {'Я даю '}
                <a
                    href={`${window.location.origin}/privacy-policy#personalDataInternshipProcessing`}
                    rel='noreferrer'
                    target='_blank'>
                    {'согласие'}
                </a>
                {
                    ' АО "Лаборатория Касперского" обрабатывать мои персональные данные с целью проведения тестирования и дальнейшего рассмотрения моей кандидатуры для приёма на стажировку в соответствии с '
                }
                <a
                    href={`${window.location.origin}/privacy-policy`}
                    rel='noreferrer'
                    target='_blank'>
                    {'политикой конфиденциальности'}
                </a>
                {'*'}
            </>
        ),
    },
    // TODO: universalization of messages is necessary here
    errors: {
        IS_REQUIRED: 'Поле обязательно для заполнения',
        IS_NOT_EMAIL: 'Email не валиден',
        IS_OUT_OF_FIRST_NAME_LENGTH: 'Допустимо от 1 до 50 символов',
        IS_OUT_OF_LAST_NAME_LENGTH: 'Допустимо от 1 до 50 символов',
        IS_OUT_OF_TELEGRAM_LENGTH: 'Допустимо от 1 до 255 символов',
        IS_OUT_OF_CITY_LENGTH: 'Допустимо от 1 до 50 символов',
        IS_OUT_OF_INSTITUTION_LENGTH: 'Допустимо от 1 до 255 символов',
        IS_OUT_OF_FACULTY_LENGTH: 'Допустимо от 1 до 255 символов',
        IS_OUT_OF_ABOUT_SELF_LENGTH: 'Допустимо от 1 до 2000 символов',
        IS_OUT_OF_WHAT2KNOW_LENGTH: 'Допустимо от 1 до 2000 символов',
        IS_OUT_OF_EMAIL_LENGTH: 'Допустимо от 1 до 255 символов',
        IS_OUT_OF_PHONE_LENGTH: 'Допустимо ровно 10 символов вместо "X"',
    },
};

export default ruLangs;
