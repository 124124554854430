import { css } from '@emotion/react';

export const TypographyVars = css`
    :root {
        --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_THIN: 100;
        --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_EXTRA_LIGHT: 200;
        --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_LIGHT: 300;
        --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_NORMAL: 400;
        --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_MEDIUM: 500;
        --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_SEMI_BOLD: 600;
        --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_BOLD: 700;
        --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_EXTRA_BOLD: 800;
        --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_BLACK: 900;
        --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_EXTRA_BLACK: 950;

        --TYPOGRAPHY__BH_GROUP____FONT_WEIGHT: var(
            --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_MEDIUM
        );
        --TYPOGRAPHY__BH1____FONT_SIZE: 243px;
        --TYPOGRAPHY__BH2____FONT_SIZE: 181px;
        --TYPOGRAPHY__BH3____FONT_SIZE: 162px;
        --TYPOGRAPHY__BH4____FONT_SIZE: 121px;
        --TYPOGRAPHY__BH5____FONT_SIZE: 108px;

        --TYPOGRAPHY__H_GROUP____FONT_WEIGHT: var(
            --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_MEDIUM
        );
        --TYPOGRAPHY__H1____FONT_SIZE: 81px;
        --TYPOGRAPHY__H2____FONT_SIZE: 72px;
        --TYPOGRAPHY__H3____FONT_SIZE: 54px;
        --TYPOGRAPHY__H4____FONT_SIZE: 48px;
        --TYPOGRAPHY__H5____FONT_SIZE: 42px;

        --TYPOGRAPHY__S_GROUP____FONT_WEIGHT: var(
            --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_MEDIUM
        );
        --TYPOGRAPHY__S1____FONT_SIZE: 32px;
        --TYPOGRAPHY__S1____LINE_HEIGHT: 36px;
        --TYPOGRAPHY__S2____FONT_SIZE: 24px;
        --TYPOGRAPHY__S2____LINE_HEIGHT: 27px;
        --TYPOGRAPHY__S3____FONT_SIZE: 21px;
        --TYPOGRAPHY__S3____LINE_HEIGHT: 24px;
        --TYPOGRAPHY__S4____FONT_SIZE: 18px;
        --TYPOGRAPHY__S4____LINE_HEIGHT: 21px;

        --TYPOGRAPHY__T_GROUP____FONT_WEIGHT: var(
            --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_NORMAL
        );
        --TYPOGRAPHY__T1____FONT_SIZE: 16px;
        --TYPOGRAPHY__T1____LINE_HEIGHT: 18px;
        --TYPOGRAPHY__T2____FONT_SIZE: 14px;
        --TYPOGRAPHY__T2____LINE_HEIGHT: 16px;
        --TYPOGRAPHY__T3____FONT_SIZE: 12px;
        --TYPOGRAPHY__T3____LINE_HEIGHT: 14px;
        --TYPOGRAPHY__T4____FONT_SIZE: 10px;
        --TYPOGRAPHY__T4____LINE_HEIGHT: 12px;

        --TYPOGRAPHY__T_GROUP____FONT_WEIGHT: var(
            --TYPOGRAPHY__CORE____FONT_WEIGHT__IS_NORMAL
        );
        --TYPOGRAPHY__D1____FONT_SIZE: 8px;
        --TYPOGRAPHY__D1____LINE_HEIGHT: 10px;
        --TYPOGRAPHY__D2____FONT_SIZE: 7px;
        --TYPOGRAPHY__D2____LINE_HEIGHT: 8px;
        --TYPOGRAPHY__D3____FONT_SIZE: 6px;
        --TYPOGRAPHY__D3____LINE_HEIGHT: 7px;
    }
`;

export default TypographyVars;
