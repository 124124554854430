import { ModalConfig } from 'uikit-lib';

export const config: Record<string, ModalConfig> = {
    HOME_PAGE__MODAL____CUSTOM_FC__SHOW_REGISTER_FORM__IS_INTRO: {
        header: {
            types: {
                nodeName: 'H2',
                type: 'S1',
            },
            langs: {
                title: 'Регистрация на стажировку',
                description: '* - обязательное для заполнения поле',
            },
        },
    },
    HOME_PAGE__MODAL____CUSTOM_FC__SHOW_DIRECTION: {
        header: {
            types: {
                nodeName: 'H2',
                type: 'S1',
            },
            langs: {},
        },
    },
    HOME_PAGE__MODAL____CUSTOM_FC__SHOW_FEEDBACK: {
        header: {
            types: {
                nodeName: 'H2',
                type: 'S1',
            },
            langs: {},
        },
    },
    HOME_PAGE__MODAL____REGISTER_FORM__REG_SUCCESS: {
        header: {
            types: {
                nodeName: 'H2',
                type: 'S1',
            },
            langs: {},
        },
    },
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__IS_SERVER_ERROR: {
        header: {
            types: {
                nodeName: 'H2',
                type: 'S1',
            },
            langs: {
                title: 'Упс, что-то пошло не так!',
            },
        },
    },
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__COURSE_DOESNT_HAVE_INTO_FORM_SETTINGS:
        {
            header: {
                types: {
                    nodeName: 'H2',
                    type: 'S1',
                },
                langs: {
                    title: 'Упс, что-то пошло не так!',
                },
            },
        },
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__EMAIL_AND_FIRSTNAME_ARE_REQUIRED:
        {
            header: {
                types: {
                    nodeName: 'H2',
                    type: 'S1',
                },
                langs: {
                    title: 'Упс, что-то пошло не так!',
                },
            },
        },
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__USER_IS_EXISTING_ON_THIS_COURSE:
        {
            header: {
                types: {
                    nodeName: 'H2',
                    type: 'S1',
                },
                langs: {
                    title: 'Упс, что-то пошло не так!',
                },
            },
        },
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__YOU_CAN_NOT_USING_AUTH: {
        header: {
            types: {
                nodeName: 'H2',
                type: 'S1',
            },
            langs: {},
        },
    },
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__CAPTCHA_IS_NOT_VALID: {
        header: {
            types: {
                nodeName: 'H2',
                type: 'S1',
            },
            langs: {
                title: 'Упс, что-то пошло не так!',
            },
        },
    },
    HOME_PAGE__MODAL____REGISTER_FORM__DATA_SEND__LTI_ERROR: {
        header: {
            types: {
                nodeName: 'H2',
                type: 'S1',
            },
            langs: {
                title: 'Упс, что-то пошло не так!',
            },
        },
    },
};

export default config;
