import BASIC from 'uikit-lib/src/styles/pubAppTheme/components/Section/types/BASIC';

export const theme = {
    CWrap: {
        ...BASIC.CWrap,
        position: 'relative',
        '&::before': {
            content: '""',
            width: '457px',
            height: '373px',
            backgroundImage:
                'url("./assets/images/directions/grid_green.webp")',
            opacity: 0.5,
            backgroundSize: 'cover',
            position: 'absolute',
            zIndex: 0,
            right: 'calc((100% - 1200px) / 2 - 200px)',
            top: '-100px',
            '@media screen and (max-width: 1024px)': {
                right: '-100px',
            },
            '@media screen and (max-width: 900px)': {
                display: 'none',
            },
        },
        '&::after': {
            content: '""',
            width: '457px',
            height: '373px',
            backgroundImage:
                'url("./assets/images/directions/grid_green.webp")',
            opacity: 0.5,
            backgroundSize: 'cover',
            position: 'absolute',
            zIndex: 0,
            right: 'calc(100% / 2 - 200px)',
            bottom: '-100px',
            '@media screen and (max-width: 1270px)': {
                display: 'none',
            },
        },
    },
    ContentArea: {
        ...BASIC.ContentArea,
    },
};

export default theme;
