import { CarouselLocalStore } from '../../../../../';
import {
    getNumOfPages,
    getStepDelta,
    getTrackWidth,
    getElemWidth,
    getSteps2MoveTrack,
} from './';

export interface SourceParams4InitState {
    childrenLength: number;
    items2Show: number;
    items2Scroll?: number;
    itemPaddings: [number, number, number, number];
}

export const getInitialState = (sourceParams: SourceParams4InitState) => {
    const {
        childrenLength,
        items2Show,
        items2Scroll = 0,
        itemPaddings = [0, 0, 0, 0],
    } = sourceParams;
    const numOfPages = getNumOfPages(childrenLength, items2Show);
    const trackWidth = getTrackWidth(childrenLength, items2Show);
    const stepDelta = getStepDelta(childrenLength, items2Show, trackWidth);
    const elemWidth = getElemWidth(childrenLength);
    const steps2MoveTrack = getSteps2MoveTrack(trackWidth, stepDelta);

    const output: CarouselLocalStore = {
        items2Scroll:
            items2Scroll && items2Show < items2Scroll
                ? items2Show
                : items2Scroll || 1,
        numOfPages,
        rootHeight: 0,
        childHeight: 0,
        isSwiping: false,
        transitioning: false,
        slideWidth: 0,
        trackWidth,
        elemWidth,
        stepDelta,
        steps2MoveTrack,
        itemPaddings,
        activePage: 0,
        trackPosition: 0,
    };
    return output;
};

export default getInitialState;
