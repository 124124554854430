/* eslint-disable @typescript-eslint/naming-convention */
import { CSSObject } from '@emotion/react';

export const LAYOUTING__CONTENT_AREA: CSSObject = {
    width: '100%',
    maxWidth:
        'var(--LAYOUTING__CONTENT_AREA__IS_DESKTOP____MAX_WIDTH__IS_FROM_1440)',
    padding: 'var(--LAYOUTING__CONTENT_AREA__IS_MOBILE__PADDING)',

    '@media (min-width: 641px) and (max-width: 1440px)': {
        padding: 'var(--LAYOUTING__CONTENT_AREA__IS_TABLET__PADDING)',
    },
};

/**
 * generates CSSObject of layouting grid whose width can be 1-12 parts of
 * the Content Area's container width on tablet or desktop, and 1-6 parts
 * for mobile screen
 * All Layouting Grid visualizations are here:
 * https://www.figma.com/file/FE7V74JsvjqOSPf9IlSnTN/%E2%9D%87%EF%B8%8F-Brand-UI-kit-2023-%5Bbeta%2C-WIP%5D?node-id=7-2&t=UmUb7x2uPt6EZxEA-0
 * @param { number } partsNum
 * @returns { CSSObject }
 */
export const getCoreCSS4Cell = (partsNum: number): CSSObject => {
    return {
        width: `calc(${partsNum}00% / 12)`,
        padding:
            '0 var(--LAYOUTING__CONTENT_AREA__NOT_MOBILE____GRID_CELL_PADDING)',
        '@media screen and (max-width: 639px)': {
            width: `calc(${partsNum}00% / 6)`,
            padding:
                '0 var(--LAYOUTING__CONTENT_AREA__IS_MOBILE____GRID_CELL_PADDING)',
        },
    };
};
