import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const CWrap = styled.footer`
    ${getCSSObj}
`;

export const MainArea = styled.div`
    ${getCSSObj}
`;

export const Column = styled.div`
    ${getCSSObj}
`;

export const Copyright = styled.div`
    ${getCSSObj}
`;
