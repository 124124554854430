export enum BtnColorModeName {
    GREEN = 'GREEN',
    BLACK = 'BLACK',
    WHITE = 'WHITE',
    BLUE = 'BLUE',
    RED = 'RED',
    GHOST_BLACK = 'GHOST_BLACK',
    GHOST_GREY = 'GHOST_GREY',
    GHOST_GREEN = 'GHOST_GREEN',
    GRADIENT_GREEN = 'GRADIENT_GREEN',
    TRANSPARENT = 'TRANSPARENT',
}

export enum BtnSizeModeName {
    DEFAULT_40 = 'DEFAULT_40',
    DEFAULT_48 = 'DEFAULT_48',
    DEFAULT_64 = 'DEFAULT_64',
}

export enum BtnType {
    BASIC = 'BASIC',
    DECLINE = 'DECLINE',
    HEADER_MENU_BTN = 'HEADER_MENU_BTN',
    FOOTER_MENU_BTN = 'FOOTER_MENU_BTN',
    SIDEBAR_MENU_BTN = 'SIDEBAR_MENU_BTN',
    COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR',
    FAQ_BTN = 'FAQ_BTN',
}
