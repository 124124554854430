import styled from '@emotion/styled';
import { getCSSObj } from '../../../../utils';

export const CWrap = styled.div<{
    curWidth: number;
    xDelta: number;
    yDelta: number;
}>`
    position: absolute;

    top: 0;
    bottom: 0;
    left: ${(props) => props.xDelta}%;

    width: ${(props) => props.curWidth}%;

    transition: all 0.3s ease;

    ${getCSSObj}
`;
