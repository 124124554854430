import styled from '@emotion/styled';
import { getCSSObj } from 'uikit-lib';

export const CWrap = styled.nav`
    ${getCSSObj}
`;

export const BtnItem = styled.a`
    ${getCSSObj}
`;
