export const theme = {
    CWrap: {
        width: '108px',
        height: '64px',
        position: 'relative',
        zIndex: 20,
    },
    Image: {
        width: '100%',
        height: '100%',
        backgroundImage: 'url("/assets/images/logo/safeboard__is_default.png")',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    },
};

export default theme;
