import React, {
    useState,
    useRef,
    useEffect,
    forwardRef,
    FocusEventHandler,
    useImperativeHandle,
    HTMLInputTypeAttribute,
} from 'react';
import {
    withBaseProps,
    InputBoxNodes,
    BaseFCProps,
    CurIcon,
    UnitSizeId,
} from '../..';
import { CWrap, ResetBtn, Input, Placeholder } from './InputBox.styles';

export const checkInputValue = (
    value2Check: string | number | null | undefined,
    curInputType: HTMLInputTypeAttribute
): boolean => {
    if (!value2Check) return false;

    if (curInputType === 'number') {
        return !isNaN(Number(value2Check));
    }
    return !!value2Check;
};

export interface Props extends BaseFCProps<InputBoxNodes> {
    type: string;
    value: string | number;
    isValid?: boolean;
    placeholder?: string;
    onChange: (value: string | number | undefined) => void;
    onReset?: () => void;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onKeyUp?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
    inputType?: HTMLInputTypeAttribute;
}

// eslint-disable-next-line react/display-name
export const InputBox = forwardRef((props: Props, ref): JSX.Element => {
    const {
        value,
        placeholder,
        onChange,
        onReset,
        onBlur,
        onKeyUp,
        theme,
        testIds,
        isValid = true,
        tokens: { groupToken = '' },
        inputType = 'text',
    } = props;
    const [inputValue, setInputValue] = useState<string | number | undefined>();
    const [hasInputValue, setHasInputValue] = useState<boolean>(false);
    const [isBlur, setIsBlur] = useState<boolean>(true);
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current);

    const transformValue = (
        val: string | number | undefined
    ): number | undefined | string => {
        if (inputType === 'number' && !!val) {
            const newVal = Number(val);
            if (isNaN(newVal)) return undefined;

            return newVal;
        }
        return val;
    };

    useEffect(() => {
        const updatedHasValue = checkInputValue(value, inputType);
        setHasInputValue(updatedHasValue);
        setInputValue(transformValue(value));
    }, [value]);

    return (
        <CWrap
            theme={theme.CWrap}
            data-testid={testIds.CWrap}
            onClick={(event: React.MouseEvent) => {
                event.preventDefault();

                setIsBlur(false);
                if (inputRef?.current) inputRef?.current.focus();
            }}>
            {hasInputValue && (
                <ResetBtn
                    theme={theme.ResetBtn}
                    data-testid={testIds.ResetBtn}
                    onClick={(event: React.MouseEvent) => {
                        event.preventDefault();
                        setInputValue('');
                        if (onReset) {
                            onReset();
                        } else {
                            onChange('');
                        }
                        if (inputRef?.current) inputRef?.current.focus();
                    }}>
                    <CurIcon sizeId={UnitSizeId.REGULAR} iconId={'CROSS'} />
                </ResetBtn>
            )}
            <Input
                theme={theme.Input}
                data-at-selector={groupToken}
                data-testid={testIds.Input}
                value={inputValue}
                ref={inputRef}
                type={inputType}
                className={`${isValid ? '' : 'isInvalid'}`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    event.preventDefault();

                    setInputValue(transformValue(event.target.value));
                    onChange(transformValue(event.target.value));
                }}
                onBlur={(event) => {
                    if (onBlur) onBlur(event);
                    setIsBlur(true);
                    const updatedHasValue = checkInputValue(value, inputType);
                    setHasInputValue(updatedHasValue);
                }}
                onKeyUp={(evt: React.KeyboardEvent<HTMLInputElement>) => {
                    evt.preventDefault();
                    if (onKeyUp) onKeyUp(evt);
                }}
                onKeyPress={(evt: React.KeyboardEvent<HTMLInputElement>) => {
                    if (evt.key === 'Enter') evt.preventDefault();
                }}
            />
            {isBlur && !hasInputValue && (
                <Placeholder
                    theme={theme.Placeholder}
                    data-testid={testIds.Placeholder}>
                    {placeholder}
                </Placeholder>
            )}
        </CWrap>
    );
});

export default React.memo(
    withBaseProps<InputBoxNodes, Props>(InputBox, {
        name: 'InputBox',
        code: 'INPUT_BOX',
        nodeNames: Object.keys(InputBoxNodes),
    })
);
