/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    BaseFCProps,
    RegisterFormNodes,
    withBaseProps,
    Button,
    getUtm,
    AppSettings,
} from 'uikit-lib';
import {
    useMutationDictionary,
    useStore,
    PrivacyPolicyItem,
    TermsOfUseItem,
    useAction,
} from 'src/containers';
import { Agreements, Contacts, CoreData, Institution, Interests } from './';
import lang from './lang';
import { AgreedDocContent, CWrap } from './RegisterForm.styles';
import { RegFormData2Send } from 'src/types';

const convertError = (error: string): string => {
    switch (error) {
        case 'courseDoesntHaveValueIntoFormSettings':
            return 'COURSE_DOESNT_HAVE_INTO_FORM_SETTINGS';
        case 'emailAndFirstNameAreRequired':
            return 'EMAIL_AND_FIRSTNAME_ARE_REQUIRED';
        case 'userIsExistingOnThisCourse':
            return 'USER_IS_EXISTING_ON_THIS_COURSE';
        case 'captchaIsNotValid':
            return 'CAPTCHA_IS_NOT_VALID';
        case 'youCanNotUsingAuth':
            return 'YOU_CAN_NOT_USING_AUTH';
        case 'ltiError':
            return 'LTI_ERROR';
        default:
            return 'IS_SERVER_ERROR';
    }
};

export type Props = BaseFCProps<RegisterFormNodes>;

export const RegisterForm: React.FC<Props> = (props): JSX.Element => {
    const { theme, testIds } = props;
    const methods = useForm({
        defaultValues: {},
        mode: 'onChange',
    });
    const {
        control,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = methods;
    const { setModalData } = useAction(['setModalData']);
    const {
        App: { appSettings },
    } = useStore();
    const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);

    const [privacyPolicyWrap, setPrivacyPolicyWrap] = useState<Element | null>(
        null
    );
    const [termsOfUseWrap, setTermsOfUseWrap] = useState<Element | null>(null);
    const [marketingLabel, setMarketingLabel] = useState<Element | null>(null);
    const [policyLabel, setPolicyLabel] = useState<Element | null>(null);
    const [termsOfUseLabel, setTermsOfUseLabel] = useState<Element | null>(
        null
    );

    useEffect(() => {
        setPrivacyPolicyWrap(window.document.querySelector('.isPrivacyPolicy'));
        setTermsOfUseWrap(window.document.querySelector('.isTermsOfUse'));
        setPolicyLabel(window.document.querySelector('.isPrivacyPolicyLabel'));
        setTermsOfUseLabel(window.document.querySelector('.isTermsOfUseLabel'));
        setMarketingLabel(window.document.querySelector('.isMarketingLabel'));
    }, []);

    const FORM_MUTATION = useMutationDictionary('ADD_REGISTER_FORM_DATA');
    const [sendInternshipRegistrationReq] = useMutation(FORM_MUTATION);

    const onSubmit: SubmitHandler<Record<string, unknown>> = (data) => {
        let formData: RegFormData2Send = {
            agreement: {
                touText: termsOfUseWrap ? String(termsOfUseWrap.innerHTML) : '',
                touCheckBoxText: termsOfUseLabel
                    ? String(termsOfUseLabel.innerHTML)
                    : '',
                privacyPolicyText: privacyPolicyWrap
                    ? String(privacyPolicyWrap.innerHTML)
                    : '',
                privacyPolicyCheckBoxText: termsOfUseLabel
                    ? String(policyLabel?.innerHTML)
                    : '',
                marketingCheckBoxText: marketingLabel
                    ? String(marketingLabel?.innerHTML)
                    : '',
                touCheckBoxIsSelected: data.touCheckBoxIsSelected as boolean,
                privacyPolicyIsSelected:
                    data.privacyPolicyIsSelected as boolean,
                marketingIsSelected:
                    (data.marketingIsSelected as boolean) || false,
            },
            captchaCode: '',
            knowAboutInternshipCity: true,
            utmTerm: '',
            utmMedium: '',
            utmCampaign: '',
            utmSource: '',
            utmContent: '',
        };
        if (
            Object.prototype.hasOwnProperty.call(data, 'touCheckBoxIsSelected')
        ) {
            delete data.touCheckBoxIsSelected;
        }
        if (
            Object.prototype.hasOwnProperty.call(
                data,
                'privacyPolicyIsSelected'
            )
        ) {
            delete data?.privacyPolicyIsSelected;
        }
        if (Object.prototype.hasOwnProperty.call(data, 'marketingIsSelected')) {
            delete data?.marketingIsSelected;
        }
        if (Object.prototype.hasOwnProperty.call(data, 'phone')) {
            data.phone = (data.phone as string).replaceAll(' ', '');
            data.phone = (data.phone as string).replaceAll('(+7)', '7');
        }
        formData = { ...formData, ...data };
        // console.log('formData is - ', formData);
        // @ts-ignore
        grecaptcha.ready(() => {
            // @ts-ignore
            grecaptcha
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .execute(
                    (
                        appSettings as Omit<
                            AppSettings,
                            | 'adfsLogoutBasePath'
                            | 'adfsClientId'
                            | 'adfsAuthorizeBasePath'
                        >
                    ).captchaSiteKey,
                    {
                        action: 'submit',
                    }
                )
                .then((token: string) => {
                    formData.captchaCode = token;

                    setSubmitInProgress(true);
                    const utm = getUtm();
                    sendInternshipRegistrationReq({
                        variables: {
                            model: { ...formData, ...utm },
                        },
                    })
                        .then(() => {
                            setSubmitInProgress(false);
                            window.ym(94766054, 'reachGoal', 'SendForm');
                            if (setModalData)
                                setModalData({
                                    isVisible: true,
                                    type: 'SYSTEM',
                                    sysType: 'INFO',
                                    descriptor: {
                                        moduleName: 'HOME_PAGE__MODAL',
                                        moduleMode: '',
                                        actionType: 'REGISTER_FORM',
                                        actionName: 'REG_SUCCESS',
                                        actionMode: '',
                                    },
                                    dataId: 0,
                                });
                            return;
                        })
                        .catch((error) => {
                            setSubmitInProgress(false);
                            const mode = convertError(error.message);
                            if (mode === 'YOU_CAN_NOT_USING_AUTH') {
                                window.ym(94766054, 'reachGoal', 'SendForm');
                            }
                            const sysType =
                                mode !== 'YOU_CAN_NOT_USING_AUTH'
                                    ? 'ERROR'
                                    : 'INFO';
                            if (setModalData)
                                setModalData({
                                    isVisible: true,
                                    type: 'SYSTEM',
                                    sysType,
                                    descriptor: {
                                        moduleName: 'HOME_PAGE__MODAL',
                                        moduleMode: '',
                                        actionType: 'REGISTER_FORM',
                                        actionName: 'DATA_SEND',
                                        actionMode: mode,
                                    },
                                    dataId: 0,
                                });
                            // eslint-disable-next-line no-console
                            console.warn(
                                'Error occurred while sending reg form data',
                                error
                            );
                        });
                });
        });
    };

    return (
        <CWrap
            theme={theme.CWrap}
            data-testid={testIds.CWrap}
            onSubmit={(evt: { preventDefault: () => void }) => {
                evt.preventDefault();
            }}>
            <AgreedDocContent className={'isPrivacyPolicy'}>
                <PrivacyPolicyItem tokens={{}} />
            </AgreedDocContent>
            <AgreedDocContent className={'isTermsOfUse'}>
                <TermsOfUseItem tokens={{}} />
            </AgreedDocContent>

            <CoreData
                control={control}
                errors={errors}
                theme={theme}
                testIds={testIds}
            />
            <Contacts
                control={control}
                errors={errors}
                theme={theme}
                testIds={testIds}
            />
            <Institution
                control={control}
                errors={errors}
                theme={theme}
                testIds={testIds}
            />
            <Interests
                control={control}
                errors={errors}
                theme={theme}
                testIds={testIds}
            />
            <Agreements control={control} errors={errors} testIds={testIds} />
            <Button
                modifierClass={'registerFormButton'}
                type={'BASIC'}
                buttonType={'button'}
                tokens={{}}
                isDisabled={!isDirty || !isValid}
                caption={lang.submitBtnCaption}
                loading={submitInProgress}
                handleOnClick={(evt) => {
                    const handleOnSubmit = handleSubmit(onSubmit);
                    handleOnSubmit(evt);
                }}
            />
        </CWrap>
    );
};

export default withBaseProps(RegisterForm, {
    name: 'RegisterForm',
    code: 'REGISTER_FORM',
    nodeNames: Object.keys(RegisterFormNodes),
});
