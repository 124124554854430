import styled from '@emotion/styled';
import { getCSSObj, TYPOGRAPHY__T1 } from 'uikit-lib';

export const CWrap = styled.form`
    ${getCSSObj}
`;

export const DataInputWrap = styled.div`
    ${getCSSObj}
`;

export const DataInputHint = styled.p`
    ${getCSSObj}
`;

export const TwoInputsWrap = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;

    width: 100%;

    & > div {
        width: 50%;
    }
`;

export const Agreement = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 12px;
`;

export const AgreementLabel = styled.p`
    ${() => TYPOGRAPHY__T1}
    padding: 0;
`;

export const AgreedDocContent = styled.div`
    display: none;
`;

export const GoogleDescription = styled.div`
    color: var(--COLORS__GRAYSCALE____5);

    & a {
        color: var(--COLORS__GRAYSCALE____4);
        cursor: pointer;
    }
`;
