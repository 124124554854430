import React from 'react';
import { FCTokens, Icon, UnitSizeId } from '../../';
import iconsMap from './iconsMap';

export interface Props {
    sizeId: keyof typeof UnitSizeId;
    iconId: string;
    tokens?: FCTokens;
    handleOnClick?: () => void;
}

export const CurIcon: React.FC<Props> = (props): JSX.Element => {
    const { sizeId, iconId, tokens = {}, handleOnClick } = props;

    return (
        <Icon
            handleOnClick={handleOnClick ? handleOnClick : undefined}
            map={iconsMap}
            sizeId={sizeId}
            iconId={iconId}
            tokens={tokens}
        />
    );
};

export default CurIcon;
