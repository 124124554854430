/**
 * generates data-testid attribute value by pattern - <HLA_SEMANTIC_TOKEN>____<TARGET_ABSTRACTION_SEMANTIC_TOKEN>____<ACTION_SEMANTIC_TOKEN>
 * As you see Compound Semantic Token consists of three parts, and each part is simple semantic token. HLA & Action Semantic Tokens are optional,
 * Target Abstraction Semantic Token is required.
 * In turn of each Semantic Token, it consists of type, name and mode of abstraction. Chunk 'Name' is required! Two others are optional.
 * @param {string} targetToken - Semantic Token for Target Abstraction
 * @param {string | undefined} prefixId HLA Semantic Token
 * @param {string | undefined} postfixId Action Semantic Token
 * @returns
 */
export const getDataTestId = (
    targetToken: string,
    hlaToken?: string | null,
    actionToken?: string | null
) => {
    if (!targetToken)
        throw new Error(
            'Attention! The targetToken value is invalid in use of genDataTestId Fn.'
        );

    return `${hlaToken ? `${hlaToken}--` : ''}${targetToken}${
        actionToken ? `--${actionToken}` : ''
    }`;
};

export default getDataTestId;
