import React from 'react';
import { InputBox, Select, SelectOptions } from 'uikit-lib';
import { Direction } from 'src/types';
import lang from './lang';
import { useStore } from 'src/containers';
import { DataInputWrap, DataInputHint } from './RegisterForm.styles';
import { CSSObject } from '@emotion/react';
import { Controller, Control, FieldValues, FieldErrors } from 'react-hook-form';
import { getSelectedOptIds, prepareSelectOptions } from './api';
import { VALIDATION_RULES } from 'src/constants';

export type Props = {
    testIds: Record<string, string>;
    theme: Record<string, CSSObject>;
    control: Control<FieldValues> | undefined;
    errors: FieldErrors;
};

export const CoreData: React.FC<Props> = (props): JSX.Element => {
    const { theme, testIds, control, errors } = props;
    const {
        FetchedData: {
            registerFormConfig: {
                firstNameIsRequired,
                lastNameIsRequired,
                interestDirectionIsRequired,
            },
            directions,
        },
    } = useStore();
    const { placeholders } = lang;
    const directionOptions = prepareSelectOptions<Direction>(
        directions,
        'name'
    );

    return (
        <>
            {interestDirectionIsRequired && (
                <DataInputWrap
                    theme={theme.DataInputWrap}
                    data-testid={testIds.DataInputWrap}>
                    <Controller
                        name={'directionsId'}
                        control={control}
                        rules={VALIDATION_RULES.directionsId}
                        render={({ field: { onChange, value } }) => {
                            const valueOptions: SelectOptions = [];
                            if (Array.isArray(value)) {
                                value.forEach((id: string) => {
                                    valueOptions.push({
                                        ...directionOptions.dictionary[id],
                                    });
                                });
                            }
                            return (
                                <Select
                                    tokens={{}}
                                    options={directionOptions.options}
                                    langs={{
                                        placeholder:
                                            placeholders.directionsSelect,
                                    }}
                                    params={{
                                        isMulti: true,
                                        isOptionDisabled: () =>
                                            valueOptions.length >= 3,
                                        value: valueOptions,
                                    }}
                                    isValid={!errors?.directionsId}
                                    handleOnChange={(
                                        selectedOptions: SelectOptions
                                    ): void => {
                                        const curValue =
                                            getSelectedOptIds(selectedOptions);
                                        onChange(curValue);
                                    }}
                                />
                            );
                        }}
                    />
                    {!!errors?.directionsId?.message && (
                        <DataInputHint
                            theme={theme.DataInputHint}
                            data-testid={testIds.DataInputHint}>
                            {errors.directionsId.message as string}
                        </DataInputHint>
                    )}
                </DataInputWrap>
            )}
            {firstNameIsRequired && (
                <DataInputWrap
                    theme={theme.DataInputWrap}
                    data-testid={testIds.DataInputWrap}>
                    <Controller
                        name={'firstName'}
                        control={control}
                        rules={VALIDATION_RULES.firstName}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <InputBox
                                    type={'BASIC'}
                                    tokens={{}}
                                    value={value}
                                    isValid={!errors.firstName}
                                    placeholder={placeholders.firstNameInput}
                                    onChange={onChange}
                                />
                            );
                        }}
                    />
                    {!!errors?.firstName?.message && (
                        <DataInputHint
                            theme={theme.DataInputHint}
                            data-testid={testIds.DataInputHint}>
                            {errors.firstName.message as string}
                        </DataInputHint>
                    )}
                </DataInputWrap>
            )}
            {lastNameIsRequired && (
                <DataInputWrap
                    theme={theme.DataInputWrap}
                    data-testid={testIds.DataInputWrap}>
                    <Controller
                        name={'lastName'}
                        control={control}
                        rules={VALIDATION_RULES.lastName}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <InputBox
                                    type={'BASIC'}
                                    tokens={{}}
                                    value={value}
                                    isValid={!errors.lastName}
                                    placeholder={placeholders.lastNameInput}
                                    onChange={onChange}
                                />
                            );
                        }}
                    />
                    {!!errors?.lastName?.message && (
                        <DataInputHint
                            theme={theme.DataInputHint}
                            data-testid={testIds.DataInputHint}>
                            {errors.lastName.message as string}
                        </DataInputHint>
                    )}
                </DataInputWrap>
            )}
        </>
    );
};

export default CoreData;
