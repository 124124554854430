import { css } from '@emotion/react';

export const ColorVars = css`
    :root {
        --COLORS__PRIMARY_PALETTE____GREEN: #29ccb1;
        --COLORS__PRIMARY_PALETTE____CRAPHITE: #1d1d1b;
        --COLORS__PRIMARY_PALETTE____WHITE: #ffffff;
        --COLORS__PRIMARY_PALETTE____GREEN_TEXT: #00a88e;
        --COLORS__PRIMARY_PALETTE____GREEN_DARK: #146658;
        --COLORS__PRIMARY_PALETTE____SECONDARY_GREEN_NEON_4: #effffc;

        --COLORS__SECONDARY_PALETTE____GREEN_NEON: #3de8ca;
        --COLORS__SECONDARY_PALETTE____CYAN: #33bbff;
        --COLORS__SECONDARY_PALETTE____BLUE: #3355ff;
        --COLORS__SECONDARY_PALETTE____VIOLET: #7733ff;
        --COLORS__SECONDARY_PALETTE____PURPLE: #dd33ff;
        --COLORS__SECONDARY_PALETTE____PINK: #ff33bb;
        --COLORS__SECONDARY_PALETTE____RED: #ff3355;
        --COLORS__SECONDARY_PALETTE____ORANGE: #ff7733;
        --COLORS__SECONDARY_PALETTE____YELLOW: #ffdd33;
        --COLORS__SECONDARY_PALETTE____GREEN_ACID: #bbff33;
        --COLORS__SECONDARY_PALETTE____GREEN_ACCENT: #55ff33;
        --COLORS__SECONDARY_PALETTE____GREEN_RICH: #4dff88;

        --COLORS__BACKGROUNDS____LIGHT_GREY: #f4f7fa;
        --COLORS__BACKGROUNDS____GREEN_NEON_4: #effffc;
        --COLORS__BACKGROUNDS____CYAN_4: #effaff;
        --COLORS__BACKGROUNDS____RED_4: #ffeff1;
        --COLORS__BACKGROUNDS____YELLOW_4: #fff7e5;

        --COLORS__INTERFACE____YELLOW: #f3b846;

        --COLORS__GRAYSCALE____0: #0f151c;
        --COLORS__GRAYSCALE____1: #131a23;
        --COLORS__GRAYSCALE____2: #21272f;
        --COLORS__GRAYSCALE____3: #383f48;
        --COLORS__GRAYSCALE____4: rgba(42, 53, 65, 0.7);
        --COLORS__GRAYSCALE____5: rgba(34, 52, 73, 0.4);
        --COLORS__GRAYSCALE____6: rgba(29, 54, 83, 0.17);
        --COLORS__GRAYSCALE____7: rgba(41, 77, 117, 0.09);
        --COLORS__GRAYSCALE____8: #f4f7fa;

        --COLORS__PRIMARY_GRADIENTS____GREEN: linear-gradient(
            45deg,
            #3de8ca 38.74%,
            #55ff33 100%
        );
        --COLORS__PRIMARY_GRADIENTS____KASPERSKY_GREEN: linear-gradient(
            25.95deg,
            #00a88e 22.8%,
            #3de8ca 84.13%
        );
        --COLORS__PRIMARY_GRADIENTS____DARK_GREEN: linear-gradient(
            40.89deg,
            #1d1d1b 0%,
            #146658 44.27%,
            #29ccb1 100%
        );
        --COLORS__PRIMARY_GRADIENTS____YELLOW: linear-gradient(
            63.03deg,
            #ff7733 4.7%,
            #ffdd33 71.61%
        );
        --COLORS__PRIMARY_GRADIENTS____RED: linear-gradient(
            60deg,
            #ff3355 0%,
            #ff7733 100%
        );

        --COLORS__SHADOWS____100: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
            0px 4px 4px -2px rgba(24, 39, 75, 0.08);
        --COLORS__SHADOWS____300: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
            0px 8px 16px -6px rgba(24, 39, 75, 0.08);
        --COLORS__SHADOWS____500: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
            0px 10px 32px -4px rgba(24, 39, 75, 0.1);
        --COLORS__SHADOWS____700: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
            0px 14px 64px -4px rgba(24, 39, 75, 0.12);
        --COLORS__SHADOWS____CARD_SHADOW: 0px 4px 20px rgba(85, 110, 110, 0.16);
        --COLORS__SHADOWS____CARD_SHADOW_HOVER: 0px 8px 20px
            rgba(85, 110, 110, 0.24);
        --COLORS__SHADOWS____GREEN_SHADOW: 0px 4px 20px rgba(35, 209, 174, 0.24);
        --COLORS__SHADOWS____GREEN_SHADOW_HOVER: 0px 8px 20px
            rgba(35, 209, 174, 0.4);
        --COLORS__SHADOWS____GREY_HOVER: 0px 8px 20px rgba(85, 110, 110, 0.24);
        // it's custom shadow created by me(Alexander Rudenko) to highlight inputs etc.
        --COLORS__SHADOWS____RED: 0px 2px 4px rgba(255, 51, 85, 0.24);
    }
`;

export default ColorVars;
