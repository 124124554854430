export enum PictureExtention {
    png = 'image/png',
    jpg = 'image/jpg',
    jpeg = 'image/jpeg',
    webp = 'image/webp',
    svg = 'image/jpg',
    gif = 'image/gif',
    any = 'image/*',
}

export enum FileExtention {
    xlsx = '.xlsx',
    xls = '.xls',
    msword = '.doc, .docx, application/msword',
    preso = '.ppt, .pptx',
    text = '.txt',
    pdf = '.pdf',
}

export enum UnitSizeId {
    ATOMIC = 'ATOMIC',
    MICRO = 'MICRO',
    TINY = 'TINY',
    SMALL = 'SMALL',
    REDUCED = 'REDUCED',
    REGULAR = 'REGULAR',
    INCREASED = 'INCREASED',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
    HUGE = 'HUGE',
    GIANT = 'GIANT',
    MAXI = 'MAXI',
}

export enum DataInputType {
    AUTO = 'AUTO',
    NUMBER_INPUT = 'NUMBER_INPUT',
    TEXT_INPUT = 'TEXT_INPUT',
    COLOR_INPUT = 'COLOR_INPUT',
    DATE_INPUT = 'DATE_INPUT',
    TIME_INPUT = 'TIME_INPUT',
    EMAIL_INPUT = 'EMAIL_INPUT',
    PHONE_INPUT = 'PHONE_INPUT',
    SALE_AMMOUNT_INPUT = 'SALE_AMMOUNT_INPUT',
    URL_INPUT = 'URL_INPUT',
    BANK_CARD_NUMBER_INPUT = 'BANK_CARD_NUMBER_INPUT',
    USER_INPUT = 'USER_INPUT',
    PASSWORD_INPUT = 'PASSWORD_INPUT',
    AUTO_COMPLETE_INPUT = 'AUTO_COMPLETE_INPUT',
    DATE_PICKER = 'DATE_PICKER',
    DATE_PERIOD_PICKER_GROUP = 'DATE_PERIOD_PICKER_GROUP',
    TIME_PICKER = 'TIME_PICKER',
    TIME_PERIOD_PICKER_GROUP = 'TIME_PERIOD_PICKER_GROUP',
    DATE_TIME_PICKER = 'DATE_TIME_PICKER',
    DATE_TIME_PERIOD_PICKER_GROUP = 'DATE_TIME_PERIOD_PICKER_GROUP',
    COLOR_PICKER = 'COLOR_PICKER',
    RATE = 'RATE',
    TEXT_AREA = 'TEXT_AREA',
    TEXT_EDITOR = 'TEXT_EDITOR',
    CHECKBOX = 'CHECKBOX',
    CHECKBOX_GROUP = 'CHECKBOX_GROUP',
    RADIOBOX = 'RADIOBOX',
    RADIOBOX_GROUP = 'RADIOBOX_GROUP',
    OPTION_GROUP = 'OPTION_GROUP',
    SINGLE_SELECT = 'SINGLE_SELECT',
    SINGLE_SELECT_ENUM = 'SINGLE_SELECT_ENUM',
    MULTIPLE_SELECT = 'MULTIPLE_SELECT',
    NUMBER_ARRAY_SELECT = 'NUMBER_ARRAY_SELECT',
    CASCADER_SELECT = 'CASCADER_SELECT',
    TRANSFER_SELECT = 'TRANSFER_SELECT',
    TREE_SELECT = 'TREE_SELECT',
    RANGE_SLIDER = 'RANGE_SLIDER',
    BOOLEAN_TOGGLER = 'BOOLEAN_TOGGLER',
    DROPDOWN = 'DROPDOWN',
    IMAGE_UPLOADER = 'IMAGE_UPLOADER',
    VIDEO_UPLOADER = 'VIDEO_UPLOADER',
    FILE_UPLOADER = 'FILE_UPLOADER',
    CALENDAR = 'CALENDAR',
    DATE_TIME_STAMP = 'DATE_TIME_STAMP',
    WYSIWYG = 'WYSIWYG',
}

export enum DataRenderType {
    BUTTON = 'BUTTON',
    DELETE_BTN = 'DELETE_BTN',
    /**
     * NUMBERS START =>
     */
    NUMBER = 'NUMBER',
    FORMATTED_NUMBER = 'FORMATTED_NUMBER',
    NUMBER_WITH_MEASURE_NAME = 'NUMBER_WITH_MEASURE_NAME',
    /**
     * <= NUMBERS END
     */
    /**
     * TEXT START =>
     */
    TEXTBOX = 'TEXTBOX',
    HTML_TEXT = 'HTML_TEXT',
    AMMOUNT_TEXTBOX = 'AMMOUNT_TEXTBOX', // FOR PRICE ETC
    NOTICE = 'NOTICE',
    /**
     * <= TEXT END
     */
    /**
     * GRAPHICS START =>
     */
    ICON = 'ICON',
    IMAGE = 'IMAGE',
    PICTURE_PREVIEW = 'PICTURE_PREVIEW',
    SPINNER = 'SPINNER',
    AVATAR = 'AVATAR',
    GALLERY = 'GALLERY',
    /**
     * <= GRAPHICS END
     */
    /**
     * LISTS START =>
     */
    LIST = 'LIST',
    MARKED_LIST = 'MARKED_LIST',
    NUMBERED_LIST = 'NUMBERED_LIST',
    NESTED_LIST = 'NESTED_LIST',
    TREE = 'TREE',
    /**
     * <= LISTS END
     */
    /**
     * DATE/TIME START =>
     */
    CALENDAR = 'CALENDAR',
    DATE_TIME_STAMP = 'DATE_TIME_STAMP',
    /**
     * <= DATE/TIME END
     */
    /**
     * SWIPING SWAPERS START =>
     */
    SLIDER = 'SLIDER',
    CAROUSEL = 'CAROUSEL',
    /**
     * <= SWIPING SWAPERS END
     */
    /**
     * COLLAPSING/SWITCHING BLOCKS START =>
     */
    TABS = 'TABS',
    ACCORDION = 'ACCORDION',
    /**
     * <= COLLAPSING/SWITCHING BLOCKS END
     */
    /**
     * DATA STUCTURE VISUALIZATIONS START =>
     */
    TABLE = 'TABLE',
    CHART = 'CHART',
    DIAGRAM = 'DIAGRAM',
    /**
     * <= DATA STUCTURE VISUALIZATIONS END
     */
    /**
     *  VARIABLE ELEMENTS START =>
     */
    CHECKLIST = 'CHECKLIST',
    /**
     *  <= VARIABLE ELEMENTS END
     */
    /**
     *  PROCESS PROGRESS INDICATORS START =>
     */
    PROGRESS_BAR = 'PROGRESS_BAR',
    TIMELINE = 'TIMELINE',
    LOADER = 'LOADER',
    /**
     * <= PROCESS PROGRESS INDICATORS END
     */
    /**
     * MARKERS & INDICATORS START =>
     */
    TAG = 'TAG',
    BADGE = 'BADGE',
    STICKER = 'STICKER',
    LABEL = 'LABEL',
    RATING = 'RATE',
    LIKES_COUNT = 'LIKES_COUNT',
    INDICATOR = 'INDICATOR',
    STATUS_BAR = 'STATUS_BAR',
    YES_NO = 'YES_NO',
    /**
     * <= MARKERS & INDICATORS END
     */
    /**
     * NAVIGATION ELEMS START =>
     */
    LINK = 'LINK',
    EXTERNAL_LINK = 'EXTERNAL_LINK', // FOR PRICE ETC
    FILE_LINK = 'FILE_LINK',
    NAV_BTN = 'NAV_BTN',
    MENU = 'MENU',
    BREADCRUMBP = 'BREADCRUMBP',
    ANCHOR = 'ANCHOR',
    PAGINATION = 'PAGINATION',
    DOTS_PANEL = 'DOTS_PANEL',
    STEPS = 'STEPS',
    /**
     * <= NAVIGATION ELEMS END
     */
    /**
     * DIALOG ELEMS START =>
     */
    MODAL = 'MODAL',
    TOAST = 'TOAST',
    /**
     * <= DIALOG ELEMS END
     */
}

export enum AppThemeMode {
    LIGHT = 'LIGHT',
    DARK = 'DARK',
}

export enum EntityNameForm {
    SINGULAR = 'SINGULAR',
    PLURAL = 'PLURAL',
}
