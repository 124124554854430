import { FC } from 'react';
import {
    BaseFCProps,
    PolicyNodes,
    Section,
    Text,
    withBaseProps,
} from 'uikit-lib';
import {
    Title,
    CWrap,
    SubTitle,
    Chapter,
    NumberList,
    ListItem,
    Anchor,
} from '../../../PrivacyPolicy/components/PrivacyPolicyItem/PrivacyPolicyItem.styles';

export type Props = BaseFCProps<PolicyNodes>;

export const TermOfUsageItem: FC<Props> = ({ tokens, theme, testIds }) => {
    return (
        <CWrap data-testid={testIds.CWrap} theme={theme.CWrap}>
            <Section
                type={'BASIC'}
                tokens={{
                    hlaToken: tokens?.hlaToken
                        ? `${tokens.hlaToken}__TERM_OF_USE_ITEM`
                        : 'TERM_OF_USE_ITEM',
                }}>
                <Title theme={theme.Title}>
                    <Text type='S3' nodeName='H3' tokens={{}}>
                        Условия использования веб-сайта safeboard.kaspersky.ru
                    </Text>
                </Title>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        <b>ВНИМАНИЕ!</b> Внимательно ознакомьтесь с настоящими
                        Условиями использования веб-сайта safeboard.kaspersky.ru
                        перед началом его использования.
                    </Text>
                </Chapter>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        Определения
                    </Text>
                </SubTitle>
                <NumberList theme={theme.NumberList}>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            <b>Веб-сайт</b> – веб-портал
                            “safeboard.kaspersky.ru”.
                        </Text>
                    </ListItem>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            <b>Пользователь (Вы) </b> – физическое лицо, которое
                            использует Веб-сайт.
                        </Text>
                    </ListItem>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            <b>«Лаборатория Касперского» </b> – АО «Лаборатория
                            Касперского», Правообладатель.
                        </Text>
                    </ListItem>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            <b>Администратор Веб-сайта </b> – представитель
                            «Лаборатории Касперского», выполняющий
                            администрирование Веб-сайта.
                        </Text>
                    </ListItem>
                </NumberList>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Использование Веб-сайта означает ваше безоговорочное
                        согласие с настоящими Условиями использования, а также
                        означает, что вы принимаете настоящие Условия
                        использования. «Лаборатория Касперского» оставляет за
                        собой право без предварительных уведомлений в любое
                        время и по своему усмотрению изменять настоящие Условия
                        использования, а также модифицировать Веб-сайт или любую
                        его функциональность. Продолжение использования
                        Веб-сайта после внесения изменений означает Ваше
                        согласие с такими изменениями. Если вы не согласны с
                        такими изменениями, Вы не должны использовать Веб-сайт.
                        Если вы не согласны с настоящими Условиями
                        использования, Вы не должны использовать Веб-сайт.
                    </Text>
                </Chapter>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        Права на интеллектуальную собственность
                    </Text>
                </SubTitle>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        «Лаборатория Касперского» является владельцем всех прав
                        на Веб-сайт, включая как исключительные права, так и
                        другие применимые виды прав. «Лаборатория Касперского»
                        имеет все необходимые права, включая права на
                        интеллектуальную собственность, и лицензии на
                        опубликованные на Веб-сайте материалы и информацию,
                        включая, но не ограничиваясь текстовыми, графическими,
                        фото-, видео- и аудиоматериалами, а также программами
                        для ЭВМ независимо от их вида. Данное соглашение не дает
                        Вам никаких прав на использование объектов
                        интеллектуальной собственности, включая товарные знаки и
                        знаки обслуживания «Лаборатории Касперского», за
                        исключением переданных Вам прав «Лабораторией
                        Касперского».
                    </Text>
                </Chapter>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        Общие условия
                    </Text>
                </SubTitle>
                <NumberList theme={theme.NumberList}>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            Данный Веб-сайт предназначен для ознакомления с
                            описанием структуры программы стажировок
                            «Лаборатории Касперского», а также заполнения и
                            отправки заявки на участие в отборе.
                        </Text>
                    </ListItem>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            «Лаборатория Касперского» предоставляет Вам право
                            безвозмездно использовать Веб-сайт в пределах
                            функциональности Веб-сайта при условии соблюдения
                            Вами всех ограничений и условий использования
                            Веб-сайта, указанных в настоящих Условиях
                            использования.
                        </Text>
                    </ListItem>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            Представители «Лаборатории Касперского» имеют право
                            подтвердить или отклонить заявку Пользователя на
                            участие в отборе на стажировку и сообщить результаты
                            Пользователю посредством электронной почты,
                            телефонного звонка или других средств связи.
                        </Text>
                    </ListItem>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            В случае нарушения Пользователем настоящих Условий
                            использования Администратор Веб-сайта имеет право
                            отказать в участии в отборе на программу стажировок.
                        </Text>
                    </ListItem>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            Пользователь обязуется соблюдать все применимые
                            законы, связанные с использованием Веб-сайта,
                            включая, но не ограничиваясь всеми применимыми
                            законами, касающимися мошенничества через
                            электронную почту, несанкционированного получения
                            доступа к устройству, кражи личных данных,
                            банковского мошенничества, мошенничества с
                            использованием электронных средств коммуникации,
                            компьютерного мошенничества, рассылок спама, а также
                            обеспечения конфиденциальности, охраны товарных
                            знаков и авторского права. Ваш доступ к Веб-сайту
                            будет запрещен в случае, если «Лаборатория
                            Касперского» установит, что Вы не используете
                            Веб-сайт в соответствии с условиями использования.
                        </Text>
                    </ListItem>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            Запрещается передавать третьим лицам информацию,
                            которая является конфиденциальной информацией
                            «Лаборатории Касперского», а именно содержимое
                            заданий, получаемых в процессе отбора на стажировку.
                            Пользователь несёт полную ответственность за весь
                            ущерб, причиненный им «Лаборатории Касперского»,
                            Веб-сайту или третьим лицам, возникший вследствие
                            намеренной или ненамеренной передачи Пользователем
                            конфиденциальной информации «Лаборатории
                            Касперского» третьим лицам.
                        </Text>
                    </ListItem>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            При создании заявки на участие Пользователь обязан
                            указывать действительные имя, фамилию, телефон,
                            адрес электронной почты и другие данные, указанные в
                            Положении о конфиденциальности, в противном случае
                            Администратор Веб-сайта вправе отказать в
                            продолжении отбора на стажировку.
                        </Text>
                    </ListItem>
                </NumberList>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        Ограничения
                    </Text>
                </SubTitle>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Пользователю Веб-сайта запрещается:
                    </Text>
                </Chapter>
                <NumberList theme={theme.NumberList}>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            Добавлять на Веб-сайт заведомо ложную информацию о
                            себе, сообщать заведомо ложную информацию о себе в
                            ходе коммуникации с Администратором Веб-сайта.
                        </Text>
                    </ListItem>
                    <ListItem theme={theme.ListItem}>
                        <Text type='T1' nodeName='P' tokens={{}}>
                            Тестировать Веб-сайт с целью проверки на наличие
                            уязвимостей без явного разрешения администратора
                            Веб-сайта, совершать какие-либо действия, которые
                            могут вывести из строя, избыточно нагрузить или
                            негативно повлиять на нормальную работу Веб-сайта,
                            например, атаки типа отказа в обслуживании или
                            отказа в отображении страниц Веб-сайта.
                        </Text>
                    </ListItem>
                </NumberList>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        Ограничение ответственности
                    </Text>
                </SubTitle>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Веб-сайт может содержать ссылки на сторонние веб-сайты
                        или ресурсы. «Лаборатория Касперского» предоставляет
                        такие ссылки только для вашего удобства и не несет
                        ответственности за их содержание или предоставляемые на
                        них или доступные на них ресурсы или ссылки на продукты
                        или услуги.
                        <br />
                        ВЕБ-САЙТ ПРЕДОСТАВЛЯЕТСЯ «КАК ЕСТЬ» БЕЗ ГАРАНТИЙ И
                        УСЛОВИЙ (ВЫРАЖАЕМЫХ В ЯВНОЙ ИЛИ В ПОДРАЗУМЕВАЕМОЙ ФОРМЕ)
                        ВКЛЮЧАЯ, БЕЗ ОГРАНИЧЕНИЙ, КОММЕРЧЕСКОЕ КАЧЕСТВО,
                        ПРИГОДНОСТЬ ДЛЯ ОПРЕДЕЛЕННЫХ ЦЕЛЕЙ ИЛИ НЕНАРУШЕНИЯ ПРАВ
                        ТРЕТЬИХ ЛИЦ. В МАКСИМАЛЬНОЙ СТЕПЕНИ, ДОПУСКАЕМОЙ
                        ПРИМЕНИМЫМ ЗАКОНОДАТЕЛЬСТВОМ, НИ ПРИ КАКИХ
                        ОБСТОЯТЕЛЬСТВАХ ЛАБОРАТОРИЯ КАСПЕРСКОГО НЕ НЕСЕТ
                        ОТВЕТСТВЕННОСТИ ЗА КАКИЕ-ЛИБО УБЫТКИ И/ИЛИ УЩЕРБ,
                        ВОЗНИКАЮЩИЕ ИЛИ КАКИМ-ЛИБО ОБРАЗОМ СВЯЗАННЫЕ С
                        ВЕБ-САЙТОМ ИЛИ ЕГО ИСПОЛЬЗОВАНИЕМ.
                    </Text>
                </Chapter>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        Применимое законодательство
                    </Text>
                </SubTitle>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Настоящее соглашение регулируется в соответствии с
                        законодательством Российской Федерации.
                    </Text>
                </Chapter>
                <SubTitle theme={theme.SubTitle}>
                    <Text type='S1' nodeName='H1' tokens={{}}>
                        Поддержка
                    </Text>
                </SubTitle>
                <Chapter theme={theme.Chapter}>
                    <Text type='T1' nodeName='P' tokens={{}}>
                        Если у вас есть какие-либо вопросы или предложения
                        относительно работы Веб-сайта, пишите на адрес:
                        <Anchor
                            theme={theme.Anchor}
                            href='mailto:safeboard@kaspersky.com'
                            target='_blank'
                            rel='noreferrer'>
                            safeboard@kaspersky.com
                        </Anchor>
                        <br />© 2020 АО «Лаборатория Касперского»
                        <br />
                        Дата последних изменений: 19.03.2024
                    </Text>
                </Chapter>
            </Section>
        </CWrap>
    );
};

export default withBaseProps<PolicyNodes, Props>(TermOfUsageItem, {
    name: 'Policy',
    code: 'TERMS_OF_USE',
    nodeNames: Object.keys(PolicyNodes),
});
