export const theme = {
    CWrap: {
        width: '80px',
        height: '64px',
        backgroundImage: 'url("./assets/images/directions/row_to_left.png")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',

        position: 'absolute',
        top: '20px',
        left: 0,
        transform: 'translate(-50%)',

        '@media screen and (max-width: 640px)': {
            width: '42px',
            height: '34px',
        },
    },
};

export default theme;
