import React from 'react';

import { getFCTheme, getDataTestId } from '../../utils';
import { CWrap, Image, Caption } from './Logo.styles';

export type Props = {
    type?:
        | 'HEADER'
        | 'MOBILE_HEADER'
        | 'FOOTER'
        | 'MOBILE_FOOTER'
        | 'ICON'
        | 'DEFAULT'
        | 'SIDEBAR'
        | string;
    mode: 'FULL' | 'IMAGE' | 'TEXT';
    caption?: string;
    groupToken?: string;
    hlaToken?: string;
    actionToken?: string;
    customize?: Record<string, unknown>;
    logoImagePath?: string;
};

export const Logo: React.FC<Props> = (props): JSX.Element => {
    const {
        caption = '',
        mode,
        type = 'DEFAULT',
        groupToken = '',
        hlaToken = '',
        actionToken = '',
        logoImagePath = '/assets/images/logo/kaspersky__is_default.png',
    } = props;

    const theme = getFCTheme('Logo', type);

    const curFCId = 'LOGO';
    const testIds = {
        CWrap: getDataTestId(`${curFCId}__CWRAP`, hlaToken, actionToken),
        Image: getDataTestId(`${curFCId}__IMAGE`, hlaToken, actionToken),
        Caption: getDataTestId(`${curFCId}__CAPTION`, hlaToken, actionToken),
    };

    return (
        <CWrap
            theme={theme.CWrap}
            data-at-selector={groupToken}
            data-testid={testIds.CWrap}>
            {mode !== 'TEXT' && (
                <Image
                    style={{ backgroundImage: `url(${logoImagePath})` }}
                    theme={theme.Image}
                    data-testid={testIds.Image}
                />
            )}
            {mode !== 'IMAGE' && !!caption && (
                <Caption theme={theme.Caption} data-testid={testIds.Caption}>
                    {caption}
                </Caption>
            )}
        </CWrap>
    );
};

export default Logo;
