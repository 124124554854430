import { TYPOGRAPHY__H4, TYPOGRAPHY__S2 } from '../../../../';

export const theme = {
    CWrap: {
        background:
            'linear-gradient(90deg, #1D1D1B 0%, #146658 44.27%, #29CCB1 100%)',
        maxHeight: '100px',

        '@media screen and (max-width: 640px)': {
            maxHeight: '80px',
        },
    },
    Item: {
        padding: '26px 65px',
        height: '100px',

        '@media screen and (max-width: 640px)': {
            height: '80px',
            padding: '26px 35px',
        },
    },
    ItemTitle: {
        ...TYPOGRAPHY__H4,
        color: 'var(--COLORS__PRIMARY_PALETTE____WHITE)',

        '@media screen and (max-width: 640px)': {
            ...TYPOGRAPHY__S2,
            padding: 0,
        },
    },
};

export default theme;
