import React from 'react';
import { useTranslation } from 'react-i18next';
import { CWrap, textBlocks } from './ContentGen.styles';
import scheme, { ContentBlock } from './sequenses';

export interface Props {
    seqKey: string;
}

export const ContentGen: React.FC<Props> = (props): JSX.Element => {
    const { seqKey } = props;
    const targetSeq = scheme[seqKey];
    const { t } = useTranslation('content');

    return (
        <CWrap data-testid='CONTENT_GEN__CWRAP'>
            {!!targetSeq &&
                targetSeq.map((textBlock: ContentBlock) => {
                    const { key, i18nKey, tag } = textBlock;
                    const TargetBlock = textBlocks[tag];
                    return (
                        <TargetBlock
                            key={key}
                            data-testid={`CONTENT_GEN__${tag.toUpperCase()}__IS_${key.toUpperCase()}`}>
                            {t(i18nKey)}
                        </TargetBlock>
                    );
                })}
        </CWrap>
    );
};

export default ContentGen;
