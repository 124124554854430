import React from 'react';
import { withBaseProps, GradientShapeNodes, BaseFCProps } from 'uikit-lib';
import { CWrap } from './GradientShape.styles';
import { GradientShapeConfig } from '../../types';
import { getGradientShape } from '../../theme/mixins';

export interface Props extends BaseFCProps<GradientShapeNodes> {
    config: GradientShapeConfig;
}

export const GradientShape: React.FC<Props> = (props): JSX.Element => {
    const {
        testIds,
        tokens: { groupToken },
        config,
    } = props;

    return (
        <CWrap
            data-at-selector={groupToken}
            theme={{ ...getGradientShape(config) }}
            data-testid={testIds.CWrap}
        />
    );
};

export default withBaseProps(GradientShape, {
    name: 'GradientShape',
    code: 'GRADIENT_SHAPE',
    nodeNames: Object.keys(GradientShapeNodes),
});
