import styled from '@emotion/styled';
import { getCSSObj } from '../../utils';

export const CWrap = styled.div`
    ${getCSSObj}
`;

export const Link = styled.div`
    ${getCSSObj}
`;

export const Image = styled.div`
    ${getCSSObj}
`;

export const Caption = styled.div`
    ${getCSSObj}
`;
