import styled from '@emotion/styled';
import { getCSSObj } from '../../';

export const CWrap = styled.button`
    & > svg {
        display: inline-flex;
    }

    ${getCSSObj}
`;

export const BtnLoader = styled.div`
    ${getCSSObj}
`;
