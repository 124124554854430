export const theme = {
    CWrap: {
        flex: 1,
        display: 'flex',
        minHeight: 'calc(100vh - 144px)',
    },
    Container: {
        width: '100%',
        margin: '0 auto',
        maxWidth: '918px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        '@media only screen and (max-width: 1279px)': {
            maxWidth: '970px',
            padding: '0 16px',
        },
        '@media only screen and (max-width: 1023px)': {
            maxWidth: '100%',
        },
        '@media only screen and (max-width: 767px)': {
            flexDirection: 'column',
        },
    },
    Content: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        '@media only screen and (max-width: 1023px)': {
            flexDirection: 'column',
            maxWidth: '500px',
            margin: '0 auto',
        },
    },
    ContentImage: {
        display: 'block',
        width: '500px',
        marginRight: '11px',
        '@media only screen and (max-width: 1279px)': {
            width: '450px',
            marginRight: '20px',
        },
        '@media only screen and (max-width: 1023px)': {
            width: '500px',
            margin: '0 0 -11px',
        },
        '@media only screen and (max-width: 767px)': {
            width: '325px',
            marginBottom: '10px',
        },
    },
    BearTop: {
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '172px',
        '@media only screen and (max-width: 1023px)': {
            left: '19px',
        },
        '@media only screen and (max-width: 767px)': {
            display: 'none',
        },
    },
    BearBottom: {
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 'calc(50% - 21px)',
        width: '176px',

        '@media only screen and (max-width: 1279px)': {
            width: '204px',
            left: 'auto',
            right: '-20px',
        },
        '@media only screen and (max-width: 1023px)': {
            width: '279px',
            right: '47px',
        },
        '@media only screen and (max-width: 767px)': {
            width: '136px',
            position: 'static',
            margin: '-13px -11px 0 auto',
        },
    },
    Title: {
        fontSize: '48px',
        lineHeight: '48px',
        marginBottom: '36px',
        fontWeight: 500,
        '@media only screen and (max-width: 767px)': {
            fontSize: '40px',
            lineHeight: '40px',
            marginBottom: '24px',
        },
    },
    Subtitle: {
        p: {
            position: 'relative',
            paddingTop: '10px',
            fontSize: '24px',
            lineHeight: '26px',
            fontWeight: '500',

            '@media only screen and (max-width: 1279px)': {
                paddingBottom: 0,
            },

            '@media only screen and (max-width: 1023px)': {
                maxWidth: '300px',
            },

            '@media only screen and (max-width: 767px)': {
                paddingLeft: '6px',
                fontSize: '21px',
                lineHeight: '22px',
                marginBottom: '22px',
            },

            svg: {
                display: 'inline-block',
                marginLeft: '5px',
                marginBottom: '0',
                verticalAlign: 'middle',
            },

            '&:before': {
                content: '""',
                display: 'block',
                width: '51px',
                height: '3px',
                position: 'absolute',
                left: 0,
                top: 0,
                background: '#1d1d1b',

                '@media only screen and (max-width: 767px)': {
                    width: '47px',
                    height: '3px',
                    left: '5px',
                },
            },

            a: {
                padding: '0 0 0 5px',
            },
        },
    },
};

export default theme;
