import {
    TYPOGRAPHY__S2,
    TYPOGRAPHY__S3,
    TYPOGRAPHY__S4,
    TYPOGRAPHY__T1,
    LIST_STYLING,
} from 'uikit-lib';

export const theme = {
    CWrap: {
        display: 'flex',
        flexDirection: 'column',
    },
    Title: {
        ...TYPOGRAPHY__S2,
        color: '#23D1AE',
        '@media screen and (max-width: 640px)': {
            ...TYPOGRAPHY__S3,
        },
        paddingBottom: '10px',
        paddingLeft: 'calc(50% + 50px)',
        zIndex: 1,
        '@media screen and (max-width: 900px)': {
            paddingLeft: 'calc(50% - 40px)',
        },
    },
    List: {
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 'calc(50% - 125px)',
        '@media screen and (max-width: 900px)': {
            paddingLeft: 'calc(50% - 220px)',
        },
    },
    ListItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        '&:first-of-type': {
            '.line-up': {
                borderLeft: 'none',
            },
        },
        '&:last-of-type': {
            '.line-down': {
                borderLeft: 'none',
            },
        },
    },
    ListItemPeriod: {
        ...TYPOGRAPHY__S4,
        color: '#FFF',
        margin: '0px',
        padding: '0px',
        width: '170px',
        minWidth: '170px',
        textAlign: 'right',
        paddingRight: '20px',
        alignSelf: 'center',
    },
    ListItemLines: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    ListItemLinesUp: {
        borderLeft: '2px solid #23D1AE',
        display: 'flex',
        height: 'calc(50% - 6px)',
    },
    ListItemLinesCenter: {
        width: '12px',
        height: '12px',
        borderRadius: '6px',
        backgroundColor: '#23D1AE',
        overflow: 'hidden',
    },
    ListItemLinesDown: {
        borderLeft: '2px solid #23D1AE',
        display: 'flex',
        height: 'calc(50% - 6px)',
    },
    ListItemDescription: {
        padding: '20px 0px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ListItemDescriptionTitle: {
        ...TYPOGRAPHY__S4,
        color: '#FFF',
        margin: '0px',
        padding: '0px',
    },
    ListItemDescriptionComment: {
        ...TYPOGRAPHY__T1,
        ...LIST_STYLING,
        color: '#CCC',
        margin: '0px',
        padding: '0px',
    },
};

export default theme;
