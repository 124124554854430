import React from 'react';
import { useStore } from 'src/containers';
import { BaseFCProps, DirectionNodes, withBaseProps, Tabs } from 'uikit-lib';
import { DirectionDescription, DirectionTeams } from '../';
import { CWrap } from './Direction.styles';

export type Props = BaseFCProps<DirectionNodes>;

export const Direction: React.FC<Props> = (props): JSX.Element => {
    const { theme, testIds } = props;
    const {
        Modal: { dataId },
        FetchedData: { directions },
    } = useStore();

    const { description, internshipTeams, link } = directions[dataId];

    return (
        <CWrap theme={theme.CWrap} data-testid={testIds.CWrap}>
            <Tabs
                initState={{
                    description: true,
                    teams: false,
                }}
                value2ResetState={{
                    description: false,
                    teams: false,
                }}
                panelData={[
                    {
                        caption: 'Описание',
                        tabId: 'description',
                    },
                    {
                        caption: 'Команды',
                        tabId: 'teams',
                    },
                ]}
                panesContent={[
                    {
                        tabId: 'description',
                        FC2Render: () => (
                            <DirectionDescription
                                description={description}
                                link={link}
                                tokens={{}}
                            />
                        ),
                    },
                    {
                        tabId: 'teams',
                        FC2Render: () => (
                            <DirectionTeams
                                teams={internshipTeams}
                                tokens={{}}
                            />
                        ),
                    },
                ]}
                tokens={{}}
            />
        </CWrap>
    );
};

export default withBaseProps(Direction, {
    name: 'Direction',
    code: 'DIRECTION',
    nodeNames: Object.keys(DirectionNodes),
});
