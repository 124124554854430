import styled from '@emotion/styled';
import { getCSSObj } from '../../../../utils';

export const CWrap = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    box-sizing: border-box;
    border: none;
    padding: 0;
    width: 80px;
    min-width: 80px;
    height: 80px;
    min-height: 80px;

    border-radius: 50%;

    cursor: pointer;
    outline: none;

    transition: all 0.3s ease;

    &:disabled {
        cursor: not-allowed;
    }

    &.isLeft {
        transform: rotate(180deg);
    }

    &.isUp {
        transform: rotate(-90deg);
    }

    &.isDown {
        transform: rotate(90deg);
    }

    ${getCSSObj}
`;
