import { gql } from '@apollo/client';

export const GET_EVENT_ADT_GQL = gql`
    query getEventAdt {
        eventAdt {
            settings {
                link
                description
                isVisible
                image {
                    fileLink
                }
            }
        }
    }
`;

export default GET_EVENT_ADT_GQL;
