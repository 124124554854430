import React, { forwardRef } from 'react';
import { getFCTheme, getDataTestId } from '../../utils';
import { FCTokens, WithBasePropsConfig } from '../../';

export const withBaseProps = <
    ReactNodeNames,
    SourceFCProps extends { tokens: FCTokens; type?: string }
>(
    SourceFC: React.FC<SourceFCProps>,
    config: WithBasePropsConfig
) => {
    // eslint-disable-next-line react/display-name
    const withBasePropsFC = forwardRef((props: any, ref) => {
        const { type = undefined, tokens } = props;
        const { name, code, nodeNames } = config;
        const theme = getFCTheme(name, type);

        const testIds: {
            [key in keyof ReactNodeNames]?: string;
        } = {};
        nodeNames.forEach((nodeName) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            testIds[nodeName] = getDataTestId(
                `${code}__${String(nodeName).toUpperCase()}`,
                tokens.hlaToken,
                tokens.actionToken
            );
        });

        return <SourceFC {...props} theme={theme} testIds={testIds} />;
    });

    return withBasePropsFC;
};

export default withBaseProps;
